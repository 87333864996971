@import '../../../../assets/styles/variables.scss';

.container {
  text-align: center;
  color: $Brand-Black;
  padding-top: 32px;

  .iconsWithDescriptionsContainer {
    margin-top: 48px;
    margin-bottom: 48px;
    justify-content: space-around;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: $screen-xxs-max) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }
}
