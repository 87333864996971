@import '../../assets/styles/variables';

.container {
  border: 1px solid getThemeValue('alert-info-border-color');
  border-radius: getThemeValue('container-border-radius');
  background: getThemeValue('alert-info-background-color');
  padding: getThemeValue('alert-padding');
  font-size: getThemeValue('alert-font-size');
  color: getThemeValue('alert-info-font-color');
  margin-bottom: getThemeValue('alert-margin-bottom');
  text-align: left;

  @media (max-width: $screen-xs-max) {
    margin: 5px 10px 10px 10px;
  }
}
