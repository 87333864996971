@import '../../../../assets/styles/variables.scss';

.container {
  padding-top: 20px;
  text-align: center;
  color: $Brand-Black;

  .text {
    padding: 10px 0;

    .description {
      font-size: 15px;
      line-height: 24px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .amount {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      color: $Green-500;

      @media (max-width: $screen-xs-max) {
        font-size: 35px;
        padding-top: 10px;
      }
    }
  }

  .buttons {
    padding: 10px 0;
  }

  img {
    height: 245px;
    max-height: 150px;
    max-width: 150px;
  }

  .viewOfferButton {
    border-radius: 1000px;
    background-color: $btn-primary;
    color: $White;
  }
}