.oldWarning {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: #FFFFFF;
  z-index: 10000;
  text-align: center;
  color: white;
  font-size: 24px;
  padding: 50px;

  :global {
    .title {
      text-transform: uppercase;
      color: #03b0ea;
    }

    .subtitle {
      max-width: 400px;
      color: #3c3c3c;
      font-size: 18px;
      margin: 24px auto;
    }

    .update-button {
      margin: 66px;
      font-size: 16px;
      a {
        margin: 12px;
        background: #03b0ea;
        padding: 16px;
        color: white;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
          font-style: normal;
        }
      }
    }

    .comments {
      margin: 46px auto;
      font-size: 14px;
      max-width: 400px;
      color: #3c3c3c;
    }

    .support {
      font-size: 16px;
      color: #3c3c3c;
    }
  }
}