@import "../../../../assets/styles/variables.scss";

.root {
  text-align: left;
  margin: $Padding-Loose * -1;
  padding: $Padding-Default $Padding-Default $Padding-Default - 12px $Padding-Default;
}

.quickSelectButtons {
  display: flex;
  flex-wrap: no-wrap;
  margin-bottom: $Padding-Default;

  button {
    flex: 1;
    margin-right: $Padding-Default;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.submitWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0;
  }
}