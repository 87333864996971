@import '../../assets/styles/variables';

.popupBackground {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparentize(black, 0.4);
  z-index: 9000;

  .popupInside {
    margin: auto;
    margin-top: 116px;
    width: 450px;
    background: white;
    position: relative;
    min-height: 150px;
    padding: getThemeValue('content-padding-size');
    text-align: center;
    border-radius: getThemeValue('container-border-radius');

    @media (max-width: $menu-toggle-width) {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      margin-top: 0;
      border-radius: 0;
    }

    .popupTitle {
      font-family: getThemeValue('font');
      font-size: getThemeValue('popup-title-font-size');
      font-weight: bold;
      margin-bottom: $Padding-Default;
      position: relative;
    }

    .popupImageContainer {
      margin: 0 auto;
      padding-bottom: 10px;
      width: 73.68px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .popupSubtitle {
      font-family: getThemeValue('font');
      font-size: 13px;
      font-weight: medium;
      margin-bottom: 24px;
    }

    .previewTile {
      background: $subtle-off-white;
      padding: 15px 12px;
    }

    .colorPicker {
      width: 176px;
      margin: 0px auto 5px;

      .choice {
        width: 32px;
        height: 32px;
        padding: 4px;
        margin-right: 3px;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;

        &.active {
          position: relative;

          &:before {
            position: absolute;
            bottom: 0px;
            right: 4px;
            content: 'e';
            color: white;
            font-style: normal;
            font-weight: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -mos-osx-font-smoothing: grayscale;
          }
        }
      }
    }

    .form {
      .actions {
        margin-top: 32px;
      }
    }

    .capitalEscButton {
      all: unset;
    }

    .escButton {
      background: transparent;
      border-radius: 32px;
      position: absolute;
      top: -4px;
      padding-top: 4px;
      right: -4px;
      text-align: center;
      z-index: 1000;
      cursor: pointer;
      width: 32px;
      height: 32px;
      line-height: 32px;

      &:hover {
        background: $Gray-100;
        color: black;
      }
    }

    .backButton {
      background: transparent;
      border-radius: 32px;
      position: absolute;
      top: 12px;
      left: 12px;
      width: 32px;
      height: 32px;
      text-align: center;
      z-index: 1000;
      cursor: pointer;

      &.corner {
        top: -4px;
        left: 0;
        width: 30px;
        height: 30px;

        &:hover {
          background: $Gray-100;
        }
      }

      &.modifiedButton {
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
      }

      i {
        margin-top: 4px;
        margin-left: 4px;
        line-height: 24px;
        display: block;
      }

      &:hover {
        background: $Gray-100;
      }
    }

    .roundedButton{
      @extend .escButton;
      padding-top: 0;
      right: 10px;
      top: 10px;
    }
  }

  .flagshipPopupInside {
    @extend .popupInside;
    padding: 0;
  }

  .roundedPopupInside{
    @extend .popupInside;
    border-radius: 20px;
  }

  .emailPopupInside{
    @extend .roundedPopupInside;
    padding: 0;

    .emailPopupTitle {
      @extend .popupTitle;
      text-align: left;
      padding: 15px 32px;
      background-color: black;
      font-size: 13px;
      color: white;
      border-radius: 20px 20px 0px 0px;
    }

    .emailEscButton{
      @extend.escButton;
      top: 8px;
      right: 20px;
    }
  }
}

.updateMethod {
  margin: 12px 0 8px 0;

  .customField {
    float: left;

    @media (max-width: $menu-toggle-width) {
      float: none;
    }

    .checkboxLabelWrapper {
      span {
        line-height: 40px;
        padding-left: 8px;
        font-size: 16px;
      }
    }

    .checkboxField {
      padding-top: 4px;
    }
  }
}

.noCellStyle {
  background: none;
  border: 0;
  border-right-width: 1px;
}

.listBlock {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.spaceBetween {
  justify-content: space-between;

  @media (max-width: $menu-toggle-width) {
    align-items: center !important;
    justify-content: center;
    flex-direction: column;
  }
}

.addItemsPopupContainer {
  .addItemsPopupInner {
    height: 360px;
    overflow-y: auto;

    @media (max-width: $menu-toggle-width) {
      height: 100%;
      max-height: 80vh; // We want our bottom action buttons to be visible
    }

    table {
      border: none;

      tbody {
        border: none;
      }
    }
  }

  .buttonsContainer {
    border-top: 1px solid $light-gray-divider;
    margin: 0 0 12px;
    padding-top: 12px;

    @media (max-width: $menu-toggle-width) {
    }
  }
}

.stockTotal {
  margin-top: 12px;
  font-family: getThemeValue('font');
  font-size: 12px;
  color: $font-light-gray;
}

.fieldLabel {
  font-size: getThemeValue('form-label-font-size');
  text-transform: getThemeValue('form-label-text-transform');
  font-weight: getThemeValue('form-label-font-weight');
  color: getThemeValue('font-color');
  line-height: getThemeValue('form-label-line-height');
  margin: 0;
  float: left;
}

.storePopupBackground {
  position: absolute;
  z-index: 9000;
  background: transparentize(black, 1);
  margin: auto;
  left: 25%;
  top: 10%;

  .storePopupInside {
    border-radius: 8px;
    margin: auto;
    margin-top: 116px;
    height: inherit;
    width: 250px;
    background: white;
    position: relative;
    min-height: 150px;
    padding: 30px;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(148, 155, 165, 0.5);

    @media (max-width: $menu-toggle-width) {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      margin-top: 0;
    }

    .escButton {
      background: transparent;
      border-radius: 16px;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 32px;
      height: 32px;
      color: $font-light-gray;
      font-weight: bold;
      text-align: center;
      z-index: 1000;
      cursor: pointer;

      i {
        font-size: 18px;
        margin-top: 3px;
        line-height: 32px;
        display: block;
      }

      &:hover {
        background: #efefef;
        color: black;
      }
    }

    .menuItems {
      .menuItem {
        border-bottom: 1px solid #e4e4e4;
        font-family: getThemeValue('font');
        color: $font-medium;
        font-size: 14px;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
      }
    }
  }
}

.editItemPopup {
  border-radius: 12px;

  .itemHeader {
    margin-bottom: 8px;
    border-radius: 12px 12px 0 0;
    -moz-border-radius-topleft: 12px;
    -moz-border-radius-topright: 12px;
    height: 40px;
    color: #fff;
    background: $yoco-blue;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }

  .popupContent {
    padding: 6px 12px;

    .sectionTitle {
      text-align: left;
      color: $gray-text;
      margin: 12px 0;
    }

    .previewTile {
      background: $subtle-off-white;
      padding: 15px 12px;
    }

    .colorPicker {
      width: 176px;
      margin: 0px auto 5px;

      .choice {
        width: 32px;
        height: 32px;
        padding: 4px;
        margin-right: 3px;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;

        &.active {
          position: relative;

          &:before {
            position: absolute;
            bottom: 0px;
            right: 4px;
            content: 'e';
            color: white;
            font-family: 'yoco';
            font-style: normal;
            font-weight: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -mos-osx-font-smoothing: grayscale;
          }
        }
      }
    }
  }
}

.cannot {
  color: $Red-500;
}

.refundWarning {
  margin-bottom: 24px;
}

.rowRadioButtons {
  margin-top: 2rem;
}

.rowRadioButton {
  margin-bottom: 1rem;
}

.bankDetailsOwnerRows {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.bankDetailsOwnerRow {
  display: flex;
  justify-content: space-between;
  width: 35rem;
}
.bankDetailsOwnerDescription {
  font-weight: bold;
  color: #2b2b2b;
}
.bankDetailsSubmittedContent {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

