@import 'src/assets/styles/variables.scss';

.firstLineFieldContainer {
  padding-right: 0px;
  padding-bottom: 20px;

  @media (min-width: $screen-xxs-max) {
    padding-right: 20px;
  }
}

.linkContainer {
  text-align: left;
}

.flagshipFormContainer > div{
  padding-bottom: 4px !important;
}

.flagshipForm > div{
  margin: 0;
}