@import '../../assets/styles/variables';

$padding-unit: 6px;

.textCentered {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.widthFull {
  width: 100%;
}

.heightFull {
  min-height: 100%;
}

.marginCentered {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}

.block {
  display: block;
}

.overflowAutoOnMobile {
  @media (max-width: $menu-toggle-width) {
    overflow: auto;
  }
}

.halfWidthOnDesktop {
  @media (min-width: $menu-toggle-width) {
    width: 50%;
  }
}

// This is horrific, but short of refactoring all of our forms I need this
.paddingRightOnDesktop {
  @media (min-width: $menu-toggle-width) {
    padding-right: 20px;
  }
}
.paddingRightOnMobile {
  padding-right: 20px;

  @media (min-width: $menu-toggle-width) {
    padding-right: 0px;
  }
}

.hideOnMobile {
  @media (max-width: $menu-toggle-width) {
    display: none;
  }
}

hidden {
  display: none;
}


.flex {
  display: flex;
}

.flexOnDesktop {
  display: block;

  @media (min-width: $menu-toggle-width) {
    display: flex;
  }
}

.flexColumn {
  flex-direction: column;
}

.flexOne {
  flex: 1;
}

.flexGrow {
  flex-grow: 1;
}

.flexNoShrink {
  flex-shrink: 0;
}

.itemsCenter {
  align-items: center;
}

.itemsStart {
  align-items: flex-start;
}

.itemsStretch {
  align-items: stretch;
}

.p1 {
  padding: $padding-unit;
}

.p2 {
  padding: $padding-unit * 2;
}

.p3 {
  padding: $padding-unit * 3;
}

.p4 {
  padding: $padding-unit * 4;
}

.pl1 {
  padding-left: $padding-unit;
}

.pl2 {
  padding-left: $padding-unit * 2;
}

.pl3 {
  padding-left: $padding-unit * 3;
}

.pl4 {
  padding-left: $padding-unit * 4;
}

.pl8 {
  padding-left: $padding-unit * 8;
}

.pr1 {
  padding-right: $padding-unit;
}

.pr2 {
  padding-right: $padding-unit * 2;
}

.pr3 {
  padding-right: $padding-unit * 3;
}

.pr4 {
  padding-right: $padding-unit * 4;
}

.pr8 {
  padding-right: $padding-unit * 8;
}

.pt1 {
  padding-top: $padding-unit;
}

.pt2 {
  padding-top: $padding-unit * 2;
}

.pt3 {
  padding-top: $padding-unit * 3;
}

.pt4 {
  padding-top: $padding-unit * 4;
}

.pt8 {
  padding-top: $padding-unit * 8;
}

.pb1 {
  padding-bottom: $padding-unit;
}

.pb2 {
  padding-bottom: $padding-unit * 2;
}

.pb3 {
  padding-bottom: $padding-unit * 3;
}

.pb4 {
  padding-bottom: $padding-unit * 4;
}

.pb8 {
  padding-bottom: $padding-unit * 8;
}

.maxWidth {
  max-width: 600px;
}

.minWidth200 {
  min-width: 200px;
}

.minWidth0 {
  min-width: 0;
}
.minWidthFull {
  min-width: 100%;
}

.smMinWidthFull {
  @media (min-width: $menu-toggle-width) {
    min-width: 0;
  }
}

.smMinWidth0 {
  @media (min-width: $menu-toggle-width) {
    min-width: 0;
  }
}

