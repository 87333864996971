@import "../../../assets/styles/variables.scss";

.accountSwitcher {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-width: getThemeValue('filter-border-width');
  border-color: getThemeValue('filter-border-color');
  border-style: solid;
  background: getThemeValue('filter-background-color');
  border-radius: getThemeValue('filter-border-radius');
  font-size: getThemeValue('filter-font-size');
  color: getThemeValue('filter-font-color');

  padding: 0px 12px;
  margin: 4px 0px;
  height: 42px;

  i {
    line-height: 24px;
    height: 24px;
  }
}

.accountList {
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  color: black;
  padding: 12px;
  z-index: 10;
  max-height: 60vh;
  overflow-y: auto;
}
