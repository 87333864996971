@import "../../../../assets/styles/variables.scss";

.root {
  background-color: getThemeValue('form-field-background');
  border: getThemeValue('form-field-border');
  border-radius: getThemeValue('form-field-border-radius');
  color: getThemeValue('font-color');
}

.empty {
  padding: 36px 12px;
  text-align: center;
  color: getThemeValue('font-color');
  img {
    width: 50px;
    height: auto;
    margin-bottom: 12px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: getThemeValue('form-field-border');
  padding: 8px;
}
