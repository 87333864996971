@import '../../../../assets/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;

  .image {
    width: 100px;
  }

  h1 {
    margin-top: 10px;
  }

  h4 {
    margin: 20px;
    font-size: 18px;
    text-align: center;
    width: 50%;
  }

  .button {
    margin-top: 20px;
    border-radius: 1000px;
    background-color: getThemeValue('button-charge-background-color');
    color: getThemeValue('button-charge-font-color');
    width: 350px;
  }
}
