@import "../../../assets/styles/variables.scss";

.pageEditor {
  border: getThemeValue('form-field-border');
  color: getThemeValue('font-color');
  border-radius: getThemeValue('form-field-border-radius');

}

:global {
  .pell-actionbar {
    width: 100%;
    background-color: $Gray-100;
    border-bottom: getThemeValue('form-field-border');
    display: flex;

    .pell-button {
      padding: $Padding-Tight;
      border: none;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .pell-content {
    padding: $Padding-Default;

  }
}

.deleteButton {
  background: getThemeValue('button-destructive-background-color');
  color: getThemeValue('button-destructive-font-color');
  min-width: 40px;
  @media(max-width: $menu-toggle-width) {
    right: 0;
  }

  i {
    color: #FFF;
    margin-right: 0;
  }
}