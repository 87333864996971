@import "../../../assets/styles/variables.scss";

.referralsContainer {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
        "summary summary button"
        "share share share"
        ". . search"
        "table table table";
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $phone-width) {
        grid-template-areas:
                "summary "
                "button"
                "share"
                "search"
                "table";
        grid-template-columns: 100%;
    }
}

.summaryContainer {
  grid-area: summary;
  width: 100%;
}

.summaryContentWrapper {
  flex: 1;
  height: 100%;
  background-color: $White;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 60px;
}

.amountAwardedSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .amountAwardedValue {
    font-size: 62px;
    line-height: 55px;
    font-weight: 600;
    margin: 0;
  }

  .amountAwardedLabel {
    font-size: 12px;
    font-weight: 500;
    color: $Brand-Blue;
    margin: 0;
  }

  .noReferralsText {
    color: $Yellow-500;
  }
}

.secondarySummariesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}

.secondarySummaryDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .secondarySummaryValue {
    font-family: $SharpGrotesk;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
  }

  .secondarySummaryLabel {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
  }
}

.referralIcon {
  height: 40px;
}

.sharpGroteskMedium {
    font-family: $SharpGrotesk25;
    font-weight: 500;
}

.sharpGroteskSemiBold {
    font-family: $SharpGrotesk;
    font-weight: bold;
    color: $White;
}

.noWrap {
  white-space: nowrap;
}

.referralButtonContainer {
    grid-area: button;
}

.referralButton {
    background-color: $Brand-Yellow;
    color: $Brand-Black;
    font-weight: 500;
    display: flex;
    padding: 20px 18px;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
}

.referralButtonUrl {
    @media(max-width: $screen-xs-max) {
        font-size: 10px;
        padding-top: 5px;
    }
}

.referralCardTitle {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
}

.referralButtonMessage {
    margin: 0 10px;
    @media(max-width: $screen-xs-max) {
        font-size: 10px;
    }
}

.referralButtonIcon {
    margin: 0;
    height: 20px;
}

.referralUrlSpinner {
    height: 8px;
    width: 8px;
    margin: 0 auto;
}

.commissionsSpinner {
    margin-top: 100px;
    height: 32px;
    width: 32px;
}

.shareCard {
    grid-area: share;
    display: flex;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
    border-radius: 4px;
    background-color: $Brand-Green;
    color: $White;
    justify-content: center;
    border: 1px solid $Gray-200;

    @media(min-width: $screen-xs-max){
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    @media(min-width: $screen-lg-min) {
      grid-template-columns: repeat(3, 1fr);
    }
}

.shareCardBlock {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    align-self: center;
}

.referYocoImage {
  display: none;
  width: 100%;
  height: 100%;

  @media(min-width: $screen-lg-min) {
    display: block;
    object-fit: cover;
  }
}

.shareCardHelp {
  color: $Brand-Yellow;
  text-decoration: underline;
  font-weight: 500;
}

.shareButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shareCardSocialButton {
    max-width: 350px;
    flex-grow: 1;
    padding: 12px 18px;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    font-weight: 500;
}

.shareCardSocialButtonIcon {
    padding-right: 10px;
}

.searchBar {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: $light-gray-divider;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;

    input {
      background-color: transparent;
    }

    input::placeholder {
      color: $Brand-Black;
      font-weight: 500;
    }
}

.tableWrapper {
    grid-area: table;
    padding: 10px 20px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $Gray-200;
    flex: 1;
}

.table {
    width: 100%;
}

.tableSearchWrapper {
  display: flex;
  justify-content: flex-end;

  .tableSearch {
    width: 285px;
    margin-right: 30px;
  }
}

.tableHeader {
    background-color: $white;
    padding: 15px;
    color: $Brand-Black;
    font-family: $SharpGrotesk25;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
}

.statusTableHeader {
  display: flex;
  justify-content: space-between;
}

.tableRow {
    border-bottom: 1px solid $Gray-200;
    font-size: 12px;
}

.tableIsEmptyMessage {
  padding: 20px;
  margin: 0;
}

.tableCell {
    font-family: $SharpGrotesk;
    padding: 15px;
    font-weight: 400;
}

.dateTableCell {
  font-weight: 600;
}

.commissionTableCell {
  font-family: $SharpGrotesk;
  font-weight: 500;
}

.commissionPendingTableCell {
  color: $gray-blue;
}

.tableSearchContainer {
    grid-area: search;
}

.statusFilterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 30px;
  margin-right: 20px;
  border-left: 1px solid $Gray-900;
}

.statusFilterIconWrapper {
  margin-top: 10px;
}

.statusFilterOptions {
  top: 50px;
  right: 30px;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  font-family: $SharpGrotesk;
  font-size: 14px;
  line-height: 28px;

  p {
    padding: 0px;
    margin: 0px;
    text-transform: capitalize;
    padding: 0px 20px;
  }

  p:hover,
  p:active,
  p:visited {
    background-color: $Gray-200;
  }
}

.statusFilterOptions::before {
  display: none;
}

.statusFilterOptions::after {
  display: none;
}

.statusChipTooltip {
  left: 0px;
  bottom: 70px;
  padding: 14px 12px;
  background-color: $Brand-Black;
  border: 1px solid $Brand-Black;

  p {
    font-size: 11px;
    line-height: 18px;
    text-align: left;
    color: $font-extra-light;
    margin: 0;
  }

  hr {
    border-color: $Gray-900;
    margin: 5px 0px 7px 0px;
  }
}

.statusChipTooltip:before {
  border-top-color: $Brand-Black;
}

.statusChipTooltip:after {
  border-top-color: $Brand-Black;
}

.statusChipTooltipHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-family: $SharpGrotesk;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: $White;
    margin: 0;
  }

  .completeStep {
    color: $Green-500;
  }

  .incompleteStep {
    display: flex;
    align-items: center;
    gap: 3px;

    .incompleteLabel {
      color: $Brand-Orange;
    }

    .incompleteIndicator {
      height: 6px;
      width: 6px;
      border-radius: 100%;
      background-color: $Brand-Orange;
    }
  }
}

.referralQRContainer{
  display: flex;
  flex-direction: row;
  margin-top: 13px;
}

.referralQrViewButton {
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  font-family: $SharpGrotesk;
  color: $Blue-500;
  cursor: pointer;
}

.qrIcon {
  margin-right: 15px;
  margin-left: 15px;
}