@import "../../assets/styles/variables.scss";

.itemName {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hoverBlue:hover{
  color: #00a2e0!important;

}

.iconInfo {
  font-size: 11px;
  text-align: 'center';
  margin-top: 8px;
}

.exportDropDown{
  display: inline-block;
  @media (max-width: 480px) {
    width: 100%;
  }
}

.dropDownListItem{
  background: none;
  border: none;

}

.dropDownListItem:hover{

}

.exportDropDownList{
  position: absolute;
  z-index: 9;
  box-shadow: 0 0 5px 0 rgba(148, 140, 100, 0.5);
  background-color: white;
  margin-top:2px;
  min-width: 160px;
}

.exportDropDownList li{
  list-style: none;
  border-bottom: 1px solid #EFEFEF;

}
.exportDropDownList li a,
.exportDropDownList li a:visited,
.exportDropDownList li a:focus  {
  color: #3e3e3e;
}
.exportDropDownList li a:hover{
  color: $yoco-blue;
}

@media (max-width: 480px) {
  .buttonContainer button{
    width: 100%;
    margin-top: 5px;


  }
}


.itemsPage {
  input {
    width: 100%;
    box-sizing: border-box;
  }

  table {
    position: relative;

    &.newTable {
      border-bottom: none;
      width: 100%;
      table-layout: fixed;

      tr {
        height: 72px;

        &.underlinedRow {
          border-bottom: 1px solid #E4E4E4;
        }

        &.groupHeaderRow {
          height: 33px;

          td {
            line-height: 24px;
            padding-bottom: 0;
            vertical-align: bottom;
          }
        }

        &.childRow {
          height: 30px;

          &.underlinedRow {
            td {
              height: 36px;
            }
          }

          td {
            padding-bottom: 0;
            padding-top: 0;
          }
        }

        &.invisibleHeader {
          height: 0px;
        }

        &.visibleHeader {
          height: 34px;

          th {
            padding: 0;
            padding-left: 24px;
            border: none;
            color: #3E3E3E;
            font-size: 16px;
            font-weight: 500;
            background: none;
            border-bottom: 1px solid #BBBBBB;
            text-overflow: ellipsis;
            overflow: hidden;

            @media(max-width: 767px) {
              padding-left: 8px;
            }
          }
        }

        &.spacerHeader {
          height: 0px;
        }

        &.subtleBackground {
          td {
            background-color: #F1F6F6;

            &.spacer {
              background: white;
            }
          }
        }

        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }
      }

      td {
        border-top: none;
        padding-top: 6px;
        padding-bottom: 6px;
        line-height: 33px;
        overflow: hidden;
        text-overflow: ellipsis;
        &.spacer {
          border-top: none;
        }
      }
    }

    &.tableClickable {
      tbody tr {
        &:hover {
          cursor: pointer;
        }
      }
    }

    thead {
      tr {
        .invisible-header {

          th {
            height: 0px;
            border-bottom: none;
            border-top: none;
            padding: 0;
          }

          th.hideOnMobile {
            @media (max-width: $screen-xs-max) {
              width: 0;
              overflow: hidden;
              font-size: 0px;
              padding: 0px;
              margin: 0px;
            }
          }
        }

        th {
          background-color: $off-white;
          color: $yoco-blue;
          font-weight: 500;
          font-weight: normal;
          font-size: 16px;
          border-bottom: 1px solid #e7f4f8;
        }

        th.noPadding{
          padding-left:4px;
          padding-right:4px;
        }

        th.price {
          text-align: right;
          padding-right: 24px !important;
        }

        th.quantity {
          width: 200px;
          padding: 12px 24px;
          text-align: right;
        }

        th.stock {
          width: 160px;
          padding: 12px 24px;
          text-align: center;

          @media(max-width: $tablet-width) {
            max-width: 200px;
          }

          @media (max-width: $phone-width) {
            text-align: right;
          }

          @media(max-width: $screen-xxs-max) {
            max-width: 100%;
            width: 40%;
          }
        }

        th.variant {
          text-overflow: ellipsis;
          white-space: nowrap;

          @media(max-width: $tablet-width) {
            width: 200px;
            left: 10px;
          }
        }

        th.showOnPhoneOnly {
          display: none;

          @media(max-width: $screen-xxs-max) {
            display: table-cell;
            padding-left: 0;
          }
        }

        th.image {
          width: 100px;
        }

        th.sku {
          width: 160px;
          padding: 12px 24px;

          @media(max-width: $screen-xxs-max) {
            width: 40%;
          }
        }

        th.stockNote {
          width: 80px;

          @media(max-width: $phone-width) {
            display: none;
          }
        }

        th.alert {
          width: 150px;
          padding: 12px 24px;
          text-align: right;

          @media(max-width: $tablet-width) {
            max-width: 160px;
          }

          @media(max-width: $phone-width) {
            display: none;
          }
        }
      }
    }

    tbody {
      font-family: getThemeValue('font');
      font-size: 14px;
      font-weight: normal;
      color: $font-table-dark;

      tr {
        position: relative;
        height: 72px;

        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }

        td {
          position: relative;
          line-height: 24px;

          .hideOnMobile {
            @media(max-height: $menu-toggle-width) {
              width: 0;
              overflow: hidden;
              font-size: 0px;
              padding: 0px;
              margin: 0px;
            }
          }
        }

        td.image {
          padding: 6px 24px;
        }

        td.hideOnMobile {
          @media (max-width: $screen-xs-max) {
            width: 0;
            overflow: hidden;
            font-size: 0px;
            padding: 0px;
            margin: 0px;
          }
        }
      }

      tr.newGroupHeader{
        color: black;
        font-weight: 500;
        font-size: 14px;

        border-top: 1px solid #efefef;

        .bigTotal {
          font-size: 20px;
          padding-right: 48px;

          @media (max-width: $screen-xs-max) {
            padding: 0;

            .amount {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:hover {
          cursor: default;
        }
      }

      tr.groupHeader{
        height: auto;

        &:hover {
          cursor: default;
        }

        td::before, td.groupHeader::after,
        td:last-child::before, td:first-child::before{
          background: #ddd !important;
          top: 21px;
          z-index: 1;
        }

        td{
          background: $table-group-header;
          color: $table-header;
          line-height: 12px;
          font-size: 90%;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }

      tr.subtleBackground {
        td {
          background-color: #F1F6F6;

          &.spacer {
            background: white;
          }
        }
        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }
      }

      tr.spacerHeader {
        height: 0px;
      }

    }
  }
}

.viewPanel {
  flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;

  @media (max-width: $menu-toggle-width) {
    margin-left: 0;
    margin-right: 0;
  }

  .viewContainer {
    position: relative;
    background: white;
    border-radius: 10px;
    margin: 36px 24px;
    padding: 12px 12px;
  }

  .stockCountItem {
    position: relative;
    display: block;

    .stockDetails {
      padding: 20px 0;
      float: left;

      .stockCount {
        margin-left: 32px;
        max-width: 200px;
        float: right;
      }

      .stockLabel {
        font-weight: 500;
        color: $font-dark;
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: initial;
      }
    }

    .stockCountButtons {
      position: absolute;
      margin: auto 0;
      top: 12px;
      bottom: -18px;
      right: 0;

      .stockHistoryButton {
        min-width: 50px;
        border-radius: 4px;
        height: 40px;
        line-height: 40px;
        background: $orange;
        color: #FFF;
      }
    }
  }

  .fieldLabel {
    font-size: getThemeValue('form-label-font-size');
    text-transform: getThemeValue('form-label-text-transform');
    font-weight: getThemeValue('form-label-font-weight');
    color: getThemeValue('font-color');
    line-height: getThemeValue('form-label-line-height');
    margin: 0;
  }

  .switchMargin {
    margin-bottom: 5px;
    float: right;

    &.left {
      float: left;
    }
  }

  .variantName {
    font-weight: bold;
    font-size: 13px;
  }

  .onlineProduct {
    .productImage {
      width: 60px;
      height: 60px;
    }
  }

  .variantGroupsActions {
    border-top-width: 1px;
    border-top-color: $light-border;
    border-top-style: solid;
    padding-top: 10px;
    text-align: right;
  }

  .variantGroups {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 16px;

    .variantItemSwitch {
      width: 15%;

      @media (max-width: $menu-toggle-width) {
        width: 100%;
      }
    }

    .variantProductCode {
      width: 55%;

      @media (max-width: $menu-toggle-width) {
        width: 100%;
      }
    }

    .variantItemPrice {
      width: 30%;

      @media (max-width: $menu-toggle-width) {
        width: 100%;
      }
    }

    .variantItemPriceFull {
      width: 85%;

      @media (max-width: $menu-toggle-width) {
        width: 100%;
      }
    }


  }

  .previewTile {
    padding: 15px 12px;
    background: $subtle-off-white;
    margin: 15px 0;
    width: 100%;

    .editTileButton {
      display: block;
      margin: 12px auto 0;
      width: 166px;
    }
  }
}

.removeCardMachineButton {
  border: 1px solid #b0b0b0;
  color: black;
  background: white;
}

.addItemsToCollection {
  margin-top: 8px;
}

.errorMessage {
   color: $dark-red;
   text-align: right;
   font-size: 12px;
   font-family: getThemeValue('font');
   position: absolute;
   bottom: 0;
   right: 20px;
 }

.removeErrorMessage {
  color: $dark-red;
  font-size: 12px;
  font-family: getThemeValue('font');
  margin: 6px 0;
}

.deleteIconType {
  color: $red !important;
}

.paymentIcons {
  font-size: 25px;
  position: absolute;
  top: 10px;
}

.variantGroupsOptions {
  display: block;
  position: relative;

  .variantGroupsName {
    width: 70%;
    margin-right: 15px;

    @media(max-width: $menu-toggle-width) {
      width: 75%;
      margin-right: 8px;
    }

    @media (max-width: 630px){
      width: 70%;
    }
  }

  .deleteButton {
    background: getThemeValue('button-destructive-background-color');
    color: getThemeValue('button-destructive-font-color');
    height: 38px;
    min-width: 40px;
    margin: auto 0;
    right: 0px;
    top: 0px;
    bottom: -4px;
    position: absolute;

    @media(max-width: $menu-toggle-width) {
      right: 0;
    }

    i {
      color: #FFF;
      margin-right: 0;
    }
  }
}

.buttonsContainer {
  margin: 16px 0;

  &.addItems {
    .removeButton {
      border: 1px solid $red;
      color: $red;
      background: white;
      float: left;

      @media(max-width: $phone-width) {
        min-width: 150px;
      }
    }

    .addButton {
      border: 1px solid $green;
      color: $green;
      background: white;
      float: right;

      @media(max-width: $phone-width) {
        float: left;
        min-width: 150px;
      }
    }
  }
}

.addCategory {
  margin-top: 10px;
  float: right;
  margin-right: 20px;

  @media(max-width: $menu-toggle-width) {
    margin-right: 0;
  }
}

.storeSortContainer {
  float: right;

  .sortLabel {
    font-family: getThemeValue('font');
    font-size: 14px;
  }

  .onoffswitch {
    float: right;
  }
}

.fullLine {
  position: relative;
  display: block;
  margin: 6px -24px;
  border-top: 1px solid #e5e5e5;
}

.storeProductsTable {
  table {
    tr {
      height: 56px;

      &.rowBackground {
      }

      &.selected {
        td {
          background-color: $subtle-blue-background;

          &.spacer {
            background: white;
          }
        }
      }

      th.image {
        max-width: 100px;
      }

      th.price {
        width: 200px;
        padding-right: 24px;
        text-align: right;
        line-height: 56px;
        font-weight: 500;
      }

      td.quantity {
        width: 50px;
        padding-right: 24px;
        text-align: right;
        line-height: 56px;
        font-weight: 500;
      }

      th.name {
         text-overflow: ellipsis;
         white-space: nowrap;
         max-width: 280px;

         @media(max-width: 600px) {
           width: 160px;
           left: 10px;
         }
       }
    }

    tbody {
      tr {
        td.image {
          max-width: 100px;
        }

        td.delete {
          max-width: 70px;
        }

        td.amount {
          width: 200px;
          text-align: right;
          font-weight: 500;
        }

        td.items {
          width: 200px;
          text-align: center;
          font-weight: 500;
        }

        td.quantity {
          width: 50px;
          text-align: right;
          font-weight: 500;
        }

        td.name {
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 280px;

          @media(max-width: 600px) {
            width: 160px;
            left: 10px;
          }
        }
      }
    }
  }
}

.popupBackground {
  margin: auto;
  margin-top: 116px;
  width: 350px;
  background: white;
  position: relative;
  min-height: 150px;
  padding-bottom: 20px;
  text-align: center;
  border-radius: 12px;

  @media (max-width: $menu-toggle-width) {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-top: 0;
  }
}

:global {
  .react-draggable-dragging {
    position: relative;
    z-index: 10;
  }

  table {
    tbody {
      font-family: getThemeValue('font');
      font-size: 14px;
      font-weight: normal;
      color: $font-table-dark;

      tr {
        position: relative;

        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }

        td.image {
          width: 100px;

          @media(max-width: $screen-xxs-max) {
            padding: 0 0 0 6px;
          }
        }

        td.items {
          width: 200px;
          padding-left: 24px;
          position: relative;

          @media (max-width: $phone-width) {
            display: none;
          }
        }

        td.showOnPhoneOnly {
          display: none;

          @media(max-width: $screen-xxs-max) {
            display: table-cell;
            padding: 6px 16px 6px 0;
            margin-left: -30px;
          }

          tr.stockPhoneRows {
            height: 20px;

            td.sku {
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 40%;
              padding: 0;
              font-size: 13px;
              font-weight: 500;
              color: $Gray-900;
            }

            td.stockLevel {
              padding: 0;
              text-align: right;
              font-weight: 500;
              width: 40%;
            }

            td.stockNote {
              width: 100px;
              padding: 0 10px;
              display: table-cell;

              .stockAlert {
                height: 32px;
                position: relative;
                margin: 0;
                padding: 4px 8px !important;
                border-radius: getThemeValue('container-border-radius');

                &.lowStock {
                  color: white;
                  background: getThemeValue('color-warning');
                }

                &.zeroStock {
                  color: white;
                  background: getThemeValue('color-danger');
                }
              }
            }
          }
        }

        td.sku {
          width: 160px;
          padding: 12px 24px;
        }

        td.variant {
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 280px;

          @media(max-width: $tablet-width) {
            max-width: 160px;
          }

          @media(max-width: $screen-xxs-max) {
            padding: 0;
          }
        }

        td.stockLevel {
          width: 100px;
          text-align: center;
          font-weight: 500;
          padding: 0 10px;

          @media(max-width: $tablet-width) {
            max-width: 250px;
          }

          @media (max-width: $phone-width) {
            text-align: right;
          }

          .stockUpdate {
            padding: 8px;
            text-align: center;

            @media (max-width: $phone-width) {
              text-align: right;
            }

            &:hover {
              cursor: pointer;
              background: $subtle-blue-background;
            }
          }
        }

        td.stockNote {
          width: 80px;
          padding: 0 10px;
          position: relative;

          @media (max-width: $phone-width) {
            display: none;
          }

          .stockAlert {
            height: 32px;
            position: absolute;
            margin: auto 0;
            top: 0;
            bottom: 0;
            padding: 4px 16px !important;
            width: 60px;
            border-radius: getThemeValue('container-border-radius');

            &.lowStock {
              color: white;
              background: getThemeValue('color-warning');
            }

            &.zeroStock {
              color: white;
              background: getThemeValue('color-danger');
            }
          }
        }

        td.stockAlertCell {
          width: 100px;
          text-align: right;
          font-weight: 500;

          @media(max-width: $phone-width) {
            display: none;
          }
        }
      }
    }
  }
}


.stockCountItem {
  position: relative;
  display: block;

  .stockDetails {
    padding: 20px 0;
    float: left;

    .stockCount {
      margin-left: 32px;
      max-width: 200px;
      float: right;
    }

    .stockLabel {
      font-weight: 500;
      color: $font-dark;
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: initial;
    }
  }

  .stockCountButtons {
    position: absolute;
    margin: auto 0;
    top: 12px;
    bottom: -18px;
    right: 0;

    .stockHistoryButton {
      min-width: 50px;
      border-radius: 4px;
      height: 40px;
      line-height: 40px;
      background: $orange;
      color: #FFF;
    }
  }
}

.cardButton {
  margin-top: 10px;
  float: right
}