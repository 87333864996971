@import '../../../../assets/styles/variables.scss';

.container {
    .header {
      color: $Brand-Black;
      text-align: center;
      line-height: 24px;
  
      .title {
        font-size: 18px;
        font-weight: bold;
  
        @media (max-width: $screen-xs-max) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      .subTitle {
        font-size: 15px;
        padding: 0 90px 0 90px;

        .subTitleInline {
          display: inline-block ;
        }
  
        @media (max-width: $phone-width) {
          padding: 0;
        }
      }
    }
  
    .section {
      padding-top: 20px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }
