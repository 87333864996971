@import "../../../../../assets/styles/variables.scss";

.root {
  position: relative;
  min-height: 160px;
}

.search {
  position: relative;
  margin-bottom: 12px;
  input {
    padding-right: 32px;
  }
  i {
    position: absolute;
    top: 5px;
    right: 6px;
  }
}

.empty {
  padding: 36px 12px;
  text-align: center;
  color: getThemeValue('font-color');
  img {
    width: 50px;
    height: auto;
    margin-bottom: 12px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  margin: 0 -12px -12px -12px;
  padding: 12px;
  background: #fff;

  button:last-of-type {
    margin: 0;
  }
}
