@import "../../assets/styles/variables.scss";


.tooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 24px;
  margin: -24px;
  bottom: 2px;
  right: -4px;

  img {
    height: 20px;
    width: auto;
    position: absolute;
    left: 30px;
  }

  @media(max-width: $phone-width) {
    padding: 0;
    margin: 0;
  }
}

.hoverContainer {
  position: absolute;
  background-color: #FFFFFF;
  z-index: 1000;
  width: 250px;
  height: auto;
  border-radius: 4px;
  bottom: 60px;
  margin-left: -51px;
  left: -40px;
  right: 0;
  letter-spacing: 0.48px;
  outline: none;
  padding: 6px 12px;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid $off-white;
  p {
    text-align: center;
    font-weight: light;
    font-size: 12px;
    color: #1A1919;
    line-height: 22px;
    padding: 2px;
  }

  @media(max-width: $tablet-width) {
    left: initial;
    right: 18px;
    margin-left: 0;
  }

  &.below {
    bottom: initial;

    &:after, &:before {
      top: initial;
      bottom: 100%;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
    }

    &:before {
      border-color: rgba(230, 230, 230, 0);
      border-bottom-color: $off-white;
    }
  }

  @media(max-width: $tablet-width) {
    &.expiry {
      right: initial;
      left: -104px;

      &:after, &:before {
        left: 50%;
      }
    }
  }
}


.hoverContainer:after, .hoverContainer:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  @media(max-width: $tablet-width) {
    left: 95%;
  }
}
.hoverContainer:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 14px;
  margin-left: -14px;
}
.hoverContainer:before {
  border-color: rgba(230, 230, 230, 0);
  border-top-color: $off-white;
  border-width: 15px;
  margin-left: -15px;
}
