@import "../../assets/styles/variables.scss";

.popoverFullPageContainer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9000;

  background: white;


  .escButton {
    background: transparent;
    border-radius: 32px;
    position: fixed;
    top: 30px;
    right: 56px;
    width: 64px;
    height: 64px;
    color: $font-light-gray;
    font-weight: bold;
    text-align: center;
    z-index: 1000;
    cursor: pointer;

    @media (max-width: $menu-toggle-width) {
      top: 12px;
      right: 12px;
    }

    i {
      font-size: 22px;
      margin-top: 10px;
      margin-bottom: 3px;
      display: block;

      @media (max-width: $menu-toggle-width) {
        margin-top: 6px;
        margin-bottom: 0;
      }
    }

    &:hover {
      background: #efefef;
      color: black;
    }
  }

  .backButton {
    margin-top: 24px;
    margin-left: 24px;
    display: inline-block;
    color: $font-light-gray;
    cursor: pointer;
    z-index: 1000;
    position: absolute;

    @media (max-width: $menu-toggle-width) {
      margin-top: 12px;
      margin-left: 0;
    }

    &:hover {
      background: #efefef;
      color: black;
    }

    i {
      float: left;
      font-size: 20px;
      padding-right: 12px;
      padding-left: 12px;
      padding-top: 8px;
    }

    .details {
      display: inline-block;
      font-weight: 500;
      padding-right: 12px;

      .previousPage {
        display: block;
        text-transform: uppercase;
        font-weight: light;
        margin-top: -2px;
        font-size: 12px;
      }
    }
  }

  &.hidden {
    display: none;
  }

  .popoverHeader {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;

    i {
      color:$yoco-blue;
      font-size: 36px;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      margin-top: 12px;
      text-transform: uppercase;
      margin-bottom: 6px;
    }

    .subtitle {
      font-family: getThemeValue('font');
      font-size: 16px;
    }
  }

  .outsideContentContainer {
    position: absolute;
    top:170px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    padding: 0 12px;
    -webkit-overflow-scrolling: touch;

    .contentColumn {
      margin: 0 auto;
      max-width: 1060px;
    }

    .transactionSummary {
      border: 1px solid $darker-border;
      margin: 0 auto;
      height: 48px;

      tr {
        background-color: white !important;
      }
    }
  }
}

.popoverPageContent {
  text-align: center;
  color: $font-light-gray;
  position: absolute;
  left: 0;
  right: 0;
  margin-bottom: 12px;
  margin-top: 12px;

  .pageTitle {
    margin-top: 8px;
  }

  .detailSoFar {
    color: $font-dark;
    margin-top: 10px;
    font-weight: 500;
  }

  .popoverOption {
    border-bottom: 1px solid #e1e1e1;
    padding-top: 24px;
    cursor: pointer;

    .details {
      margin-right: 64px;
      .title {
        font-weight: 500;
        color: $font-dark;
        text-align: left;
      }

      .detail {
        text-align: left;
        color: $font-dark;
        margin-bottom: 24px;
      }
    }

    i {
      float: right;
      font-size: 24px;
      margin-top: 12px;
      width: 64px;
    }
  }
}



:global {
  .fade-enter {
    opacity: 0.01;

    &.fade-enter-active {
      opacity: 1;
      transition: opacity 200ms ease-in;
    }
  }

  .fade-leave {
    opacity: 1;
    &.fade-leave-active {
      opacity: 0.01;
      transition: opacity 200ms ease-in;
    }
  }
}
