@import '../../assets/styles/variables.scss';

.actionBar {
  margin: 0;
  position: absolute;
  bottom: 0;
  padding: 14px;
  background: white;
  right: 0;
  left: 0;
  z-index: 3;
  box-shadow: 0 0 20px 1px $gray-background;

  @media (max-width: $phone-width) {
    padding-right: 75px;

    .cancelButton {
      display: none;
    }
  }

  .rightButtons {
    // No longer moving buttons to the right untill we re-design this to cater for zendesk widget
    float: none;
  }

  .deleteButtonContainer {
    float: left;

    .deleteButton {
      background: getThemeValue('button-destructive-background-color');
      color: getThemeValue('button-destructive-font-color');
      border: getThemeValue('button-destructive-border');
      display: inline-block;

      i {
        display: none;
        color: getThemeValue('button-destructive-font-color');
        margin-left: -5px;

        @media (max-width: $screen-xxxs-max) {
          display: inline-block;
        }
      }

      button .label {
        display: inline-block;
        @media (max-width: $screen-xxxs-max) {
          display: none;
        }
      }

      @media (max-width: $screen-xxxs-max) {
        min-width: 40px;
        width: 40px;
      }
    }
  }
}

a.flatButton {
  display: inline-block;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }
}

.flatButton {
  font-size: getThemeValue('button-font-size');
  font-weight: getThemeValue('button-font-weight');
  border-radius: getThemeValue('button-border-radius');
  overflow: hidden;
  padding: 0 16px;

  background: getThemeValue('button-tertiary-background-color');
  border: getThemeValue('button-tertiary-border');
  color: getThemeValue('button-tertiary-font-color');
  margin-right: 4px;
  display: inline;
  vertical-align: top;
  min-width: 100px;
  line-height: 40px;
  cursor: pointer;
  height: 40px;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
    color: getThemeValue('button-tertiary-font-color');
    text-decoration: none;
  }

  &.disabled {
    @if isNewTheme() {
      opacity: 0.3;
    } @else {
      background: transparentize($gray-background, 0.1);
      border-color: transparentize($gray-background, 0.1);
      //opacity: 0.4; Opacity and no background looks better to me.
      color: $font-dark !important;
    }
    cursor: not-allowed !important;

    i {
      color: transparentize(#2e3c50, 0.35);
    }
    &:hover,
    &:visited,
    &:focus {
      color: $White;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .hideOnMobile {
    @media (max-width: $screen-xxxs-max) {
      display: none;
    }
  }

  i {
    line-height: initial;
    display: inline;
    color: $font-dark;
    margin-right: 12px;
    position: relative;
    font-size: 18px;

    &:before {
      vertical-align: middle;
    }
  }

  &.dateButton {
    height: 44px;
    padding: 10px 15px;

    i {
      margin-right: 0;
    }
  }
}

.checkboxButton {
  display: block;
  padding: 3px;
  width: 28px;
  height: 28px;
  border: 1px solid $Gray-700;
  background: white;
  cursor: pointer;
  border-radius: 4px;

  i:before {
    display: block;
    color: transparent;
    text-align: center;
  }

  &.greyCheckBox {
    border: 1px solid $font-extra-light;

    width: 22px;
    height: 22px;

    i {
      margin-left: -2px;
      line-height: 22px;
    }

    &.selected {
      border: 1px solid transparent;
      background: #8a8a8a; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(
        left top,
        $gray-background,
        $light-gray
      ); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(
        bottom right,
        $gray-background,
        $light-gray
      ); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(
        bottom right,
        $gray-background,
        $light-gray
      ); /* For Firefox 3.6 to 15 */
      background: linear-gradient(
        to bottom right,
        $gray-background,
        $light-gray
      ); /* Standard syntax */

      i {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }

  &.selected {
    background: $Green-500;
    border: none;

    i:before {
      color: white;
      text-align: center;
    }
  }

  &.disabled {
    border: none;
    background: $Gray-500;
    cursor: not-allowed !important;
  }
}

.referButton {
  background: #33a8db;
  font-weight: 500;
  padding: 12px 26px;
  color: white;
  border-radius: 4px;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline;
  border: 0;
  font-size: 14px;
  min-width: 100px;
  cursor: pointer;

  a {
    color: white;
  }

  i {
    float: left;
    line-height: 1;
    display: inline-block;
    color: white;
    margin-right: 10px;
    font-size: 16px;
    vertical-align: bottom;
    font-style: normal;
    margin-left: -10px;
    margin-top: 2px;
  }
}

.taskCompleteButton {
  border-radius: 3px;
  width: 240px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  align-self: center;
  margin: 30px;
}

.dropdownModal {
  position: absolute;
  top: 46px;
  right: 4px;
  background-color: white;
  padding: $Padding-Tight;
  box-shadow: getThemeValue('card-box-shadow');
  z-index: 5;

  button {
    width: 100%;
  }

  @media (max-width: $menu-toggle-width) {
    right: auto;
  }
}

.materialIcons {
  color: white;
}

.buttonsContainer {
  margin: 16px 0;

  &.addItems {
    .removeButton {
      background: getThemeValue('button-destructive-background-color');
      color: getThemeValue('button-destructive-font-color');
      border: getThemeValue('button-destructive-border');
      float: left;
    }

    .addButton {
      border: 1px solid $green;
      color: $green;
      background: white;
      float: right;
    }
  }

  &.fixed {
    margin: 0;
    position: fixed;
    bottom: 0;
    padding: 14px;
    right: 0;
    background: #fff;
    width: 648px;
    z-index: 3;
    box-shadow: 0 0 20px 1px rgba(229, 229, 229, 1);

    @media (max-width: $menu-toggle-width) {
      width: 100%;
      left: 2px;
    }
  }

  .rightButtons {
    float: right;

    &.extraPadding {
      margin-right: 74px;
    }

    @media (max-width: $screen-sm-min) {
      float: left;
      margin-right: 0;

      .cancelButton {
        display: none;
      }
    }
  }

  .deleteButtonContainer {
    float: left;

    .deleteButton {
      background: getThemeValue('button-destructive-background-color');
      color: getThemeValue('button-destructive-font-color');
      border: getThemeValue('button-destructive-border');

      display: inline-block;

      i {
        display: none;
        color: getThemeValue('button-destructive-font-color');
        margin-left: -5px;

        @media (max-width: $screen-xxxs-max) {
          display: inline-block;
        }
      }

      button .label {
        display: inline-block;
        @media (max-width: $screen-xxxs-max) {
          display: none;
        }
      }

      @media (max-width: $screen-xxxs-max) {
        min-width: 40px;
        width: 40px;
      }
    }
  }

  .removeButton {
    float: left;

    @media (max-width: $menu-toggle-width) {
      float: right;
    }
  }

  .itemCancelButton {
    @media (max-width: $screen-xxs-max) {
      display: none;
    }
  }
}

:global {
  .formFieldButton {
    line-height: 34px;
  }

  a.flatButton {
    &:hover {
      text-decoration: none;
    }
  }

  .blueBackground {
    background: getThemeValue('button-primary-background-color');
    border: getThemeValue('button-primary-border');
    color: getThemeValue('button-primary-font-color');

    &:hover {
      opacity: 0.3;
      color: getThemeValue('button-primary-font-color');
    }

    i {
      color: getThemeValue('button-primary-font-color');
    }
  }

  .fullWidth {
    width: 100%;
    margin-right: 0;
  }

  .primary {
    @if isNewTheme() {
      background: getThemeValue('button-primary-background-color');
      border: getThemeValue('button-primary-border');
      color: getThemeValue('button-primary-font-color');
    } @else {
      background: $green;
      color: white;
      border: 1px solid $green;
    }

    background: getThemeValue('button-primary-background-color');
    border: getThemeValue('button-primary-border');
    color: getThemeValue('button-primary-font-color');

    &:hover {
      opacity: 0.8;
      color: getThemeValue('button-primary-font-color');
    }

    i {
      color: getThemeValue('button-primary-font-color');
    }
  }

  .secondary {
    background: getThemeValue('button-secondary-background-color');
    border: getThemeValue('button-secondary-border');
    color: getThemeValue('button-secondary-font-color');

    &:hover {
      opacity: 0.8;
      color: getThemeValue('button-secondary-font-color');
    }

    i {
      color: getThemeValue('button-secondary-font-color');
    }
  }

  .dismiss {
    background: getThemeValue('button-dismiss-background-color');
    border: getThemeValue('button-dismiss-border');
    color: getThemeValue('button-dismiss-font-color');

    &:hover {
      opacity: 0.8;
      color: getThemeValue('button-dismiss-font-color');
    }

    i {
      color: getThemeValue('button-dismiss-font-color');
    }
  }

  .destructive {
    background: getThemeValue('button-destructive-background-color');
    color: getThemeValue('button-destructive-font-color');
    border: getThemeValue('button-destructive-border');

    &:hover {
      opacity: 0.8;
      color: getThemeValue('button-destructive-font-color');
    }

    i {
      color: getThemeValue('button-destructive-font-color');
    }
  }

  .destructiveMinimal {
    background: getThemeValue('button-tertiary-background-color');
    color: getThemeValue('button-destructive-background-color');
    border: getThemeValue('button-tertiary-border');
    font-weight: 400;

    &:hover {
      opacity: 0.8;
      color: getThemeValue('button-destructive-background-color');
    }

    i {
      color: getThemeValue('button-destructive-font-color');
    }
  }

  .applyBackground {
    background: #8ace40;
    color: white;
    border: 1px solid #8ace40;

    i {
      color: white;
    }
  }

  .blueBorder {
    background: getThemeValue('button-tertiary-background-color');
    border: getThemeValue('button-tertiary-border');
    color: getThemeValue('button-tertiary-font-color');

    i {
      color: getThemeValue('button-tertiary-font-color');
    }
  }

  .greyBorder {
    background: getThemeValue('button-tertiary-background-color');
    border: getThemeValue('button-tertiary-border');
    color: getThemeValue('button-tertiary-font-color');

    i {
      color: getThemeValue('button-tertiary-font-color');
    }
  }

  .selectButton {
    background: white;
    color: getThemeValue('font-color');
    border: none;
    font-weight: normal;
    font-size: getThemeValue('form-field-font-size');
  }

  .clearBackground {
    background: white;
    color: getThemeValue('button-tertiary-font-color');
    border: none;
  }
}

:global {
  .onoffswitch {
    position: relative;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #FFFFFF;
    border-radius: 19px;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 26px;
    padding: 0;
    line-height: 26px;
    font-size: 16px;
    color: white;
    box-sizing: border-box;
    font-weight: light;
  }
  .onoffswitch-inner:before {
    content: 'on';
    padding-left: 10px;
    background-color: getThemeValue('form-control-active-color');
    color: #FFFFFF;
  }
  .onoffswitch-inner:after {
    content: 'off';
    padding-right: 10px;
    background-color: $Gray-500;
    color: #FFFFFF;
    text-align: right;
  }
  .onoffswitch-switch {
    display: block;
    width: 20px;
    margin: 4px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 31px;
    border: 2px solid #FFFFFF;
    border-radius: 40px;
    transition: all 0.3s ease-in 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
  }
}
