@import '../../assets/styles/variables';

.appDownloadLink {
  padding-right: 12px;
  .appDownloadImage {
    padding-bottom: 3px;
  }
}

.appDownloadContent {
  padding: 25px 0 25px 0;
}

.appDownloadButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: $phone-width) {
    flex-direction: column;
    align-items: flex-start;
  }
}
