@import "../../assets/styles/variables.scss";

.smallDate {
  display: none;
}

@media (max-width: $menu-toggle-width) {
  .longDate {
    display: none;
  }

  .smallDate {
    display: block;
  }
}

.yocoAlert {
  background-color: #FFFFFF;
  width: 100%;
  padding: 22px;
  margin:20px 0 20px 0;
  border: 1px solid transparent;
  border-radius: 0px;
  position: relative;

  i {
    font-size: 10px;
    top:0;
    right: 0;
    position: absolute;
    font-weight: bold;
    cursor: pointer;
    padding: 14px;
  }

  span {
    font-size: 14px;
  }

  &.alertWarning {
    background-color: transparentize(#FF7043, 0.9);
  }

  &.alertInfo {
    background-color: transparentize(#00A2E0, 0.9);
  }

  &.alertSuccess {
    background-color: transparentize(#6CCB59, 0.9);
  }

  &.alertDanger {
    background-color: transparentize(#B55F5D, 0.88);
  }
}
