@import '../../assets/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.inputField {
  width: 100%;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  @media (max-width: $screen-md-min) {
    padding-right: 0;
  }
}
