.box {
    display: inline-block;
    padding: 5px 8px;
    margin-right: 16px;
    background: #CCEAE3;
    border-radius: 4px;
}

.innerText {
    align-items: center;
    text-align: center;
}
