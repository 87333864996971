@import "../../assets/styles/variables.scss";

.transactionState {
  &.smallIndicator {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 6px;
    margin-bottom: 2px;

    display: inline-block;
    vertical-align: middle;
  }

  &.error {
    background: $Red-500;
  }

  &.declined {
    background: $Yellow-500;
  }

  &.approved {
    background: $Green-400;
  }

  &.aborted {
    background: $Gray-500;
  }

  &.refunded {
    background: $Blue-300;
  }

  &.pending {
    background: $Gray-200;
  }
}

.transactionStateIcon {
  width: 40px;
  height: 40px;
}

.transactionTypeIcon {
  margin-top: 3px;
  font-size: 22px;
}

.transactionType {
  position: relative;
  display: inline-block;
  .transaction-type-icon {
    font-size: 24px;
    margin: -5px 0px;
  }

  .transactionStateIcon {
    position: absolute;
    bottom: 4px;
    right: -5px;
    background: white;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    font-size: 18px;
  }
}

.transactionItemSummary {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  display: -webkit-flex;
  -webkit-flex-direction: row;

  &.overflowed {
    .itemsContainer {
      &:after {
        content: "...";
        position: absolute;
        right: 0;
        background: #efefef;
        padding: 0 4px;
      }
    }
  }

  .itemsContainer {
    flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
  }

  .noteContainer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
    padding: 0 12px;
    font-family: getThemeValue('font');
    font-size: 14px;
    font-weight: normal;
    color: #657683;
    margin-top: auto;
    margin-bottom: auto;

    i {
      margin-right: 12px;
      margin-left: 1px;

      &:before {
        vertical-align: middle;
        line-height: 13px;
      }
    }
  }

  .transactionItemDiv {
    background: #efefef;
    padding: 2px 14px;
    font-size: 10px;
    font-weight: 500;
    display: inline;
    margin-right: 8px;
    border-radius: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.hasNote {
      background-color: rgba(65, 194, 230, 0.27);
    }

    .quantity {
      padding-right: 8px;
    }

    .note {
      padding-left: 16px;
      padding-right: 12px;
      font-size: 12px;
    }
  }
}

.transactionSplitViewContainer {
  display: inline-block;

  .header {
    font-weight: bold;
  }
}

.receiptPanel {
  background: white;
  flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;

  @media (max-width: $menu-toggle-width) {
    margin-left: 0;
    margin-right: 0;
  }

  .receiptContainer {
    display: flex;
    flex-direction: column;
    .section {
      flex: 1;
      border-bottom: dashed 1px $light-border;
      padding: 8px 24px;

      .total {
        &.light {
          font-weight: light;
        }
      }

      .amount {
        align-self: flex-end;
        flex: 1;
      }

      .percent {
        flex: 1;
        color: $gray-text;
      }

      &.transactionNote {
        padding: 25px;
        display: flex;
        cursor: pointer;
        i {
          margin-right: 8px;
        }
      }

      .tableContainer {
        &.noInventoryData {
          td {
            height: 44px;
            padding-top: 12px;
            padding-bottom: 12px;

            &.item-icon-col {
              width: 0px;
              display: none;
            }
          }
        }
      }

      table {
        width: 100%;

        .itemText {
          &.inner {
            padding: 0px;
          }
        }

        .itemIconCol,
        .itemNameCol,
        .itemAmountCol {
          padding: 12px 0;
        }

        .itemIconCol {
          padding-right: 12px;
          width: 44px;

          @media (max-width:$phone-width) {
            display: none;
          }
        }

        .itemNameCol {
          font-weight: 500;

          .discountContainer {
            font-family: getThemeValue('font');
            margin: 16px 16px 0 0;
            font-size: 90%;
            float: right;
          }

          .discountText {
            display: inline-block;
          }

          .discountAmount {
            color: $Yellow-500;
          }

          .note {
            font-weight: light;
          }

          .variants {
            float: right;
            display: flex;

            .variant {
              font-size: 12px;
              padding: 4px 6px;
              background: $subtle-blue-background;
              margin: 0 4px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 60px;
              text-align: center;
              font-family: getThemeValue('font');

              @media (max-width:$phone-width) {
                margin: 2px 2px;
              }
            }

            @media (max-width:$phone-width) {
              display: block;
              padding: 4px 10px;
            }
          }
        }

        .itemAmountCol {
          font-size: 115%;
          font-weight: 600;
          text-align: right;
          width: 1px;
          white-space: nowrap;
          vertical-align: bottom;
        }
      }

      &.receiptHeader {
        font-weight: bold;
        padding: 16px 24px;

        .left {
          display: inline-block;

          @media (max-width: $phone-width) {
            max-width: 110px;
          }

          .date {
            color: initial;
            font-weight: normal;

            .smallDate {
              display: none;
            }

            @media (max-width: $menu-toggle-width) {
              .longDate {
                display: none;
              }

              .smallDate {
                display: block;
              }
            }
          }
        }

        .right {
          float: right;
          display: inline-block;
          text-align: right;
          font-size: 13px;
          font-weight: 500;
          text-transform: uppercase;
          margin-top: 20px;

          .card {
            font-weight: light;
            margin-top: 6px;
            font-weight: initial;
          }
          .billName {
            font-weight: light;
            margin-top: 6px;
            font-weight: initial;
            text-transform: initial;
          }
        }
      }

      &.totals {
        display: flex;
        flex-direction: column;

        .total {
          display: flex;
          flex-direction: row;
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }

      &.final {
        flex-direction: row;
        border-bottom: none;
        font-weight: bold;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &.footer {
        color: white;
        border-bottom: none;
        padding: 14px 24px;
        display: flex;
        align-items: center;
        gap: 12px;

        .footerMessage {
          flex: 1;
        }

        &.muted {
          background: $btn-muted;
        }

        i {
          color: white;

          &:before {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.transactionSplitContent {
  min-width: 647px;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 647px) {
    min-width: initial;
  }

}
.transactionButtonsContainer {
  height: 78px;
  padding: 16px;
  background: white;

  .actionsContainer {
    padding-right: 0px;
    margin-top: 0px;
  }
}

.transactionDetails {
  background: white;

  .totalType {
    margin: 0px auto;
  }
}

.billLifeSpan {
  margin-bottom: 16px;

  .inactivePayment {
    width: 100%;
    padding: 16px;
    display: inline-block;
    cursor: pointer;
  }
}

.transactionLifeSpan {
  flex: 1;

  @media (max-width: $menu-toggle-width) {
    margin: 0;
  }

  .dottedLine {
    position: absolute;
    top: 24px;
    bottom: 24px;
    left: 25px;
    border-left: dashed 1px $font-extra-light;
    z-index: 1;

    @media (max-width: $menu-toggle-width) {
      bottom: 48px;
      left: 24px;
      top: 34px;
    }
  }

  .transactionOverviewCard {
    display: flex;
    margin-bottom: $Padding-Default;

    .details {
      padding-right: 4px;
      padding-left: 4px;
      overflow: hidden;
      flex-grow: 1;

      .detailInfo {
        display: inline;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width:$phone-width) {
          display: none;
        }
      }

      .detail {
        display: inline;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .noSubtext {
        @media (max-width:$menu-toggle-width) {
          line-height: 20px;
        }
      }

      .amount {
        font-weight: bold;
      }
    }

    .date {
      text-align: right;
      margin-right: 12px;

      @media (max-width:$phone-width) {
        display: none;
      }
    }

    .lifeSpanAmount {
      float: right;
      margin: 10px 6px 0 12px;
      width: 80px;
    }
  }

  .paymentActions {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 0;
    }
  }
}

.transactionOptions {
  position: absolute;
  right: 48px;
  box-shadow: 0 0 5px 0 rgba(148,155,165,0.5);
  background: white;
  padding: 12px 24px;
  z-index: 5;

  .header {
    font-weight: 500;
    margin-bottom: 6px;
    padding-bottom: 12px;
    border-bottom: dashed 1px $light-border;
  }

  .options {
    display: flex;
    flex-direction: row;

    span {
      padding: 0 6px;
    }
  }
}

.paymentOptions {
  float: right;
  margin: 12px 14px 0;
  cursor: pointer;

  i {
    color: $yoco-blue;
    font-size: 16px;
    display: inline-block;

    &.active {
      transform: translate(0, -4px) rotate(90deg);
    }
  }

  :global {
    .icon-arrow-68 {
      transition: 200ms;
    }
  }
}

.itemContainer {
  flex: 1;
}
