@import "../../assets/styles/variables.scss";

.label {
    border-radius: 4px;
    font-size: 11px;
    padding: 1px 20px;
    line-height: 22px;
    color: white;
}

.betaLabel {
    @extend .label;
    background-color: $Yellow-300;
}

.newLabel {
    @extend .label;
    background-color: $Green-400;
}

.referralLabel {
    @extend .label;
    background-color: $Orange-800;
}

.noticeLabel {
    @extend .label;
    background-color: $Yellow-500;
}
