@import '../../../../../../assets/styles/variables.scss';

.formField {
  color: getThemeValue('card-header-font-color');
  font-weight: bold;
  font-size: 0.6em;
  line-height: 25px;
  font-weight: 500;
  width: -webkit-fill-available;

  .labelFormat {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 3em;
    .warning-hidden {
      display: none;
    }
    .warning {
      display: flex;
      gap: 0.3em;
      color: $Red-500;
      font-family: getThemeValue('font');
      font-size: 0.9em;
      i {
        display: flex;
        align-items: center;
        font-size: larger !important;
      }
    }
  }
}
