@import "../../assets/styles/variables.scss";

.selected {
  td {
    background-color: $subtle-blue-background;
  }
}

.buttonsContainer {
  margin: 16px 0;

  &.fixed {
    margin: 0;
    position: fixed;
    bottom: 0;
    padding: 14px;
    right: 0;
    background: #FFF;
    width: 648px;
    z-index: 3;
    box-shadow: 0 0 20px 1px rgba(229,229,229,1);

    @media(max-width: $menu-toggle-width) {
      width: 100%;
      left: 2px;
    }
  }

  .rightButtons {
    float: right;

    &.extraPadding {
      margin-right: 74px;

      @media(max-width: $menu-toggle-width) {
        margin-right: 0;
      }
    }

    @media(max-width: $screen-sm-min) {
      float: left;
      margin-right: 0;

      .cancelButton {
        display: none;
      }
    }
  }

  .deleteButtonContainer {
    float: left;

    .deleteButton {
      background: $red;
      color: #FFF;
      display: inline-block;

      i {
        display: none;
        color: #FFF;
        margin-left: -5px;

        @media(max-width: $screen-xxxs-max) {
          display: inline-block;
        }
      }

      button .label {
        display: inline-block;
        @media(max-width: $screen-xxxs-max) {
          display: none;
        }
      }

      @media(max-width: $screen-xxxs-max) {
        min-width: 40px;
        width: 40px;
      }
    }

    @media(max-width: $menu-toggle-width) {
      padding-bottom: 8px;
    }
  }

  .removeButton {
    float: left;

    @media(max-width: $menu-toggle-width) {
      float: right;
      padding-bottom: 8px;
    }
  }

  .itemCancelButton {
    @media(max-width: $screen-xxs-max) {
      display: none;
    }
  }
}

.hideOnMobile {
  @media (max-width: $screen-xs-max) {
    width: 0;
    overflow: hidden;
    font-size: 0;
    padding: 0;
    margin: 0;
  }
}

.defaultNoteHeader {
  width: 100px;
}

.defaultNote{
  width: 100px;
  padding: auto 0;
  position: relative;
  overflow: auto !important;
}

.defaultNoteText {
  color: #FFF;
  background: $green;

  height: 32px;
  margin: auto 0;
  top: 0;
  bottom: 0;
  padding: 8px 16px !important;
  width: 60px;
}

.description {
  margin-top: 10px;
  font-size: 11px;
  color: #737373;;

  @media(max-width: 560px) {
    a {
      display: none;
    }
  }
}

.deleteIconType {
  color: $red !important;
}