@import "../../../assets/styles/variables.scss";
.storeStepContainer {
    display: flex;
    flex-direction: column;
}
.deleteButton {
    background: getThemeValue('button-destructive-background-color');
    color: getThemeValue('button-destructive-font-color');
    min-width: 40px;
    @media(max-width: $menu-toggle-width) {
        right: 0;
    }

    i {
        color: #FFF;
        margin-right: 0;
    }
}
.cardHeading {
    padding-bottom: $Padding-Tighter;
    font-family: getThemeValue('heading-font');
    font-weight: getThemeValue('card-header-font-weight');
}
.cardBody {
    line-height: 150%;
}