@import "./default.module.scss";

.#{$prefix} {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.#{$prefix}-symbol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 20px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 200;
}

.#{$prefix}-symbol-absolute {
  display: flex;
  position: absolute;
  bottom: 10%; left: 50%;
  align-items: center;
  justify-content: center;
  transform: translate3d(-50%, -50%, 0);
  .#{$prefix}-symbol {
    padding: 0;
    width: 100%;
  }
}

.#{$prefix}-circle-outer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
