@import '../../../../assets/styles/variables.scss';

.container {
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 48px;

  .title {
    padding: 24px 42px 8px 42px;

    @media (max-width: $screen-xxs-max) {
      padding: 24px 24px 8px 24px;
    }
  }
}
