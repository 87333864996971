@import 'assets/styles/variables';

.documentTitle {
  color: #637381;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.documentSubtext {
  color: #637381;
  font-size: 12px;
  text-align: left;
}
