@import '../../../../assets//styles/variables.scss';

section {
  display: flex;
  flex-direction: column;
  padding: 0 40px;

  @media (max-width: $phone-width) {
    padding: 0 25px;
  }

  h4 {
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .email {
    text-decoration: none;
  }

  .firstParagraph {
    text-align: center;
    padding: 25px 0 10px 0;
    margin: 0;

    @media (max-width: $screen-xxs-max) {
      line-height: 24px;
      padding: 0px 25px 60px 25px;
      margin: 0;
    }
  }

  .secondParagraph {
    text-align: left;
  }

  .checkboxAndDownloadContractContainer {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    justify-content: space-between;

    .checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
