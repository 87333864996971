@import 'src/assets/styles/variables';

.subheading {
  text-align: left;
  font-weight: 700;
  font-size: 2rem;
}

.textLeft {
  text-align: left;
}