@import "../../assets/styles/variables.scss";

.loadingView {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 1;
  height: 100%;
  width: 100%;

  .spinnerContainer {
    text-align: center;
    min-height: 115px;
    display: table;
    margin: 0 auto;
    position: relative;
    top: 50%;
    margin-top: -57px;

    .message {
      margin-top: 24px;
      color: black;
      font-size: 15px;
      text-align: center;
    }

    &.spinnerOnTable {
      top: 120px;
    }
  }
}

@media(max-width: $tablet-width) {
  .loadingView {
    position: fixed;

    &.loaderOnTable {
      position: absolute;
    }
  }
}

.fullScreenView {
  background: $Gray-200;
  color: $Brand-Black;

  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .center {
    background-color: white;
    border-radius: getThemeValue('container-border-radius');
    width: 400px;
    padding: 32px;

    @media(max-width: $screen-xs-max) {
      box-shadow: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  .padded {
    margin: 32px 0;
  }
}

.bringToTop {
  z-index: 1005;
}
