@import '../../assets/styles/variables.scss';

$inputAddonWidth: 36px;

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  button {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: getThemeValue('form-field-font-size');
    line-height: 1.5;
    padding: $Padding-Tight;
    height: auto;
  }
}

.inputWrapper {
  position: relative;
  flex: 1;

  

  input {
    background-color: getThemeValue('form-field-background');
    font-size: getThemeValue('form-field-font-size');
    border: getThemeValue('form-field-border');
    border-radius: getThemeValue('form-field-border-radius');
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 1.5;
    padding: $Padding-Tight $inputAddonWidth;
    outline: none;

    &:active, &:focus {
      border: getThemeValue('form-field-active-border');
    }
  }
}

.inputPrefix, .inputSuffix {
  position: absolute;
  top: 0;
  width: $inputAddonWidth;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: getThemeValue('form-field-addon-color');
  i {
    transform: translateY(4px);
  }
}

.inputPrefix {
  left: 0;
}

.inputSuffix {
  right: 0;
  cursor: pointer;
}