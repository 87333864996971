@import "../../../../assets/styles/variables.scss";

.radioWithSubtitle {
  padding: 1rem 1rem 1rem 1rem;
  font-weight: normal;
  text-align: left;
}

.radioWithSubtitleUnselected {
  border-color: black;
}
.radioWithSubtitleSelected {
  border-color: getThemeValue('form-control-active-color');
}

.radioWithoutSubtitle {
  padding: 4px;
  font-weight: normal;
}

.radio {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  justify-content: center;

  @media (max-width: $menu-toggle-width) {
    font-size: 15px;
    padding: 1rem 0% 4rem 4rem;
  }
  @media (max-width: $screen-xxs-max) {
    font-size: 13px;
    padding: 1rem 0% 4rem 2rem;
  }
}
.radio .label {
  position: relative;
  display: block;
  float: left;
  font-weight: normal;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid $Brand-Black;
  border-radius: 100%;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.radio .label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: $Green-500;
  transform: scale(0);
  transition: all 0.2s ease;
  opacity: 0.08;
  pointer-events: none;
}

.radioSubtitle {
  margin-top: 8px;
  margin-left: 30px;
  font-size: 13px;
  font-weight: 500;
}

.radio:hover .label:after {
  transform: scale(3.6);
}
input[type="radio"]:checked + .label {
  border-color: $Green-500;
  padding: 0;
}
input[type="radio"]:checked + .label:after {
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
}
.hidden {
  display: none;
}
