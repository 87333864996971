@import '../../../../assets/styles/variables.scss';

.container {
  border-radius: getThemeValue('container-border-radius');
  background-color: getThemeValue('card-background-color');
  border: getThemeValue('card-border');
  padding: 8px 16px 8px 16px;

  .block {
    all: unset;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .title {
      text-align: left;
    }
  }
}

.description {
  flex: 1;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: left;
  line-height: 24px;
  font-size: 13px;
  font-weight: 500;
}
