@import '../../../../assets//styles/variables.scss';

section {
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-radius: 3px;

    @media (max-width: $screen-xs-max) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 0 5px;
    }
  }
}
