@font-face {
  font-family: 'SharpGrotesk';
  src: url("./SharpGroteskBook20-subset.woff2") format('woff2'),
    url('./SharpGroteskBook20-subset.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'SharpGrotesk';
  src: url("./SharpGroteskMedium20-subset.woff2") format('woff2'),
    url('./SharpGroteskMedium20-subset.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SharpGrotesk';
  src: url("./SharpGroteskSmBold20-subset.woff2") format('woff2'),
  url('./SharpGroteskSmBold20-subset.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SharpGrotesk';
  src: url("./SharpGroteskSmBold25-subset.woff2") format('woff2'),
    url('./SharpGroteskSmBold25-subset.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SharpGrotesk25';
  src: url("./SharpGroteskMedium25-subset.woff2") format('woff2'),
  url('./SharpGroteskMedium25-subset.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}