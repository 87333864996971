@import "src/assets/styles/variables.scss";

.placesInputFieldContainer {
  display: flex;
  flex-direction: column;
}

.placesLabelContainer {
  text-align: left;
}

.placesLabel {
  margin: 0;
}

.activePlacesLabel {
  margin: 0;
}

span.required {
  float: right;
  margin-right: 20px;
  color: $font-light-gray;
  font-family: getThemeValue('font');
  font-size: 10px;
}
