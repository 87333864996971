@import '../../../../assets/styles/variables.scss';

.middleBlock {
  border-right: 1px solid #cfd8dc;
  border-left: 1px solid #cfd8dc;
}

.container {
  flex: 1;
  padding-top: 40px;
  margin-top: 1px;
  color: $font-new-dark;
  text-align: center;

  @media (max-width: $screen-xs-max) {
    border: 0 !important;
  }

  .progressBarPercentage {
    text-align: right;

    .progressBarPercentageText {
      color: $Green-500;
      font-weight: bold;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
  }

  .actual {
    font-size: 36px;
    line-height: 45px;
    padding-top: 10px;
  }

  .achieved {
    font-size: 40px;
    line-height: 45px;
    padding-top: 10px;
    color: $Green-500;
  }

  .target {
    color: $Green-400;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    padding-bottom: 40px;
  }

  .barWrapper {
    background-color: $Green-100;
    height: 6px;
    :global {
      .react-progress-bar-percent {
        height: 100%;
        background-color: $Green-500;
      }
    }
  }

  .finishedBar {
    height: 6px;

    :global {
      .react-progress-bar-percent {
        height: 100%;
        background-color: $Green-500;
      }
    }
  }
}
