@import "../../assets/styles/variables.scss";

.loading {
  margin: auto;
  display: flex;

  .spacer {
    flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;

    @media(max-width: $menu-toggle-width) {
      display: none;
    }
  }
}

.graphDescription {
  font-weight: light;
  color: $font-light-gray;
  font-size: 14px;
  text-align: center;
  margin-bottom: 8px;
}

.graphBlock {
  position: relative;
  left: 0;
  right: 0;

  &.floatStyle {
    float: right;

    @media(min-width: $menu-toggle-width) and (max-width: 1130px) {
      float: left;
    }
  }

  .frame {
    @if not isNewTheme() {
      position: absolute;
      bottom: 0;
      z-index: 1;
      right: 0;
      left: 0;
      height: 44px;
      background: #95A8B5;
    }
  }

  :global {
    .tick {
      opacity: 0;
    }

    svg#line {
      width: 100%;

      @media(max-width: $phone-width) {
        margin-left: -50px;
        width: 110%;
      }

      @media(max-width: $screen-xxxs-max) {
        width: 120%;
      }

      .nv-lineChart {
        z-index: 2;

        g {
          clip-path: none;
        }

        .nv-x {
          .nv-axis {
            fill: #95A8B5;
          }
          .tick line {
            opacity: 0 !important;
          }
        }

        .nvd3.nv-scatter .nv-groups .nv-point {
          @if isNewTheme() {
            fill-opacity: 1 !important;
            fill: $Brand-Black !important;

            &.hover {
              stroke-width: 8px;
              stroke-opacity: 0.3 !important;
            }
          } @else {
            fill-opacity: 1 !important;
            fill: #95A8B5 !important;
            stroke-opacity: 0.3 !important;
            stroke: #95A8B5;
            stroke-width: 8px;
          }
        }

        .nvd3.nv-scatter .nv-groups .nv-point.hover {
          @if isNewTheme() {
          } @else {
            stroke-width: 14px;
          }
        }

        .nv-y {
          .tick line {
            stroke-width: 1px !important;
            opacity: 1 !important;

            @if isNewTheme() {
              stroke: $Gray-200;
              stroke-dasharray: 5 5;
            } @else {
              stroke: $off-white;
              stroke-dasharray: 10,10;
            }
          }

          g .tick {
            text {
              @if isNewTheme() {
                font: 11px getThemeValue('font') !important;
                font-weight: bold !important;
                fill: $Gray-700 !important;
              } @else {
                fill: #95A8B5;
              }

              @media(max-width: $phone-width) {
                display: none;
                margin-left: -10px;
              }
            }
          }
        }

        .domain {
          display: none !important;
        }

        .nv-x text {
          @if isNewTheme() {
            font: 11px getThemeValue('font') !important;
            font-weight: bold !important;
            fill: $Gray-700 !important;
          } @else {
            font: 18px getThemeValue('font') !important;
            fill: #FFF;
          }
        }

        text {
          font-family: getThemeValue('font');
          font-size: 12px;
          fill: #2E3C50;

          &.nv-axislabel {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    svg#simpleLine {
      max-width: 120px;
      width: 100%;
      height: 90px;

      @media(max-width: $screen-xxxs-max) {
        margin-right: 8px;
      }

      .nv-lineChart {

        g {
          clip-path: none;
        }

        .nvd3.nv-scatter .nv-groups .nv-point {
          fill-opacity: 0 !important;
          fill: #FFF !important;
          stroke-opacity: 0 !important;
          stroke: #FFF;
          stroke-width: 8px;

          &.hover {
            fill-opacity: 1 !important;
            stroke-opacity: 0.3 !important;
          }

          &.nv-point-0 {
            fill-opacity: 1 !important;
            stroke-opacity: 0.3 !important;
          }

          &.nv-point-5 {
            fill-opacity: 1 !important;
            stroke-opacity: 0.3 !important;
          }
        }
      }
    }

    svg#pie {
      width: 100%;

      .nv-pieChart {
        .nv-pie path {
          border-radius: 5px;
          stroke: transparent;
        }

        g.nv-slice text {
          font-size: 50px;
          font-weight: 500;
          fill: #56CBD7;
        }
      }
    }

    .nvd3 text {
      font: 15px getThemeValue('font') !important;
      fill: #FFF;
    }
  }
}

:global {
  div.nvtooltip {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    border: none;
    background: #FFF;
    font-family: getThemeValue('font');
    font-size: 14px;
    color: $font-table-dark;

    span.title {
      text-transform: capitalize;
    }

    tbody tr {
      height: 36px;
    }

    td.items {
      float: left;
      text-align: left;
    }

    td.floatRight {
      font-weight: 500;
      float: right;
    }

    tr strong {
      font-weight: normal;
    }

    td.legend-color-guide {
      display: none;
    }
    td.value {
      font-weight: 500;
    }
  }
}

