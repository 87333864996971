@import "../../../assets/styles/variables.scss";

.cardContentsContainer {
  padding: 0 20px;
  margin-bottom: 20px;
  @media (max-width: $phone-width) {
    width: 100%;
    padding: 0 20px;
  }

  h4 {
    font-weight: bold;
  }
}

.continueButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 3px;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
  }
}

.reAdvanceSliderContainer {
  padding: 0px 32px;
}

.repaymentPeriod {
  color: $Green-500;
  font-weight: bolder;
  text-align: center;
  margin: 0;
}

.repaymentPeriodQuote {
  background-color: $Gray-100;
  flex-wrap: wrap;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
