@import "../../assets/styles/variables.scss";

.notFound {
  padding: 120px 0px;
  :global {
    .inner {
      border: 2px solid #ccc;
      border-radius: 5px;
      margin: 0px auto;
      overflow: auto;
      text-align: center;
      background-color: #ffffff;
      font-weight: 500;
      max-width: 460px;

      img {
        margin-top: 26px;
      }

      .title {
        border-bottom: 2px solid #ccc;
        background-color: #f2f7f8;
        font-size: 16px;
        height: 56px;
        line-height: 56px;
        color: #00a2e0;
      }

      .title2 {
        font-size: 24px;
        margin-top: 32px;
      }

      p {
        font-family: getThemeValue('font');
        font-size: 16px;
        color: #a9b1b8;
        line-height: 22px;
        margin: 16px 20px 30px 20px;
      }

      .btn {
        font-weight: 500;
        font-weight: normal;
        font-size: 16px;
        border: 0px;
        padding: 12px 26px;
        color: #fff;
        background-color: #428bca;
        border-color: #357ebd;
        margin: 1em;
      }
    }
  }
}
