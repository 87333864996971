@import '../../assets/styles/variables.scss';

.urlBox {
  padding: 20px;
  text-align: center;
  background-color: $gray-background;
}
.secure {
  color: $gray-text;
}

.linkText {
  font-size: 16px;
}

.copyText {
  display: inline-block;
  padding: 10px;
}

.currency {
  text-align: right;
  width: 20%;
}

.currencyHeader {
  text-align: right;
  padding-right: 24px !important;
}
