// Old colours. References to these should be replaced with semantic names in theme (ideal),
// or reference colours from themes file
$dark-blue: #1a405c;
$dark-blue-highlight: #173856;
$dark-blue-nav: #1d4969;
$light-blue: #00aee4;
$yoco-blue: #00a2e0;
$subtle-blue-background: #d0e9f1;
$dark-blue-text: #31708f;

$light-green: #66cc52;
$lime-green: #bbe340;
$green-background: #3c763d;
$green: #42af5f;
$pastal-green: #52d788;

$black-text: #000000;

$gray-text: #888e93;
$light-gray: #cfd8dc;
$light-gray-with-opacity: #F4F6F7;
$gray-background: #efefef;
$light-gray-divider: #e4eaed;
$gray-blue: #95a8b5;

$dark-red: #fe465c;
$light-red: #fdf0f0;
$red: #fe465c;

$orange: #ffa834;

$change-required-border: #ff4c4c;
$change-required-background: #ffefef;
$change-required-foreground: #ff4c4c;

$light-border: #e4eaed;
$darker-border: darken($light-border, 20%);
$off-white: #edf3f7;
$subtle-off-white: #f7f7f7;
$white: #fff;

$font-extra-light: #acb5bf;
$font-light-gray: #949ba5;
$font-medium: #657683;
$font-dark: #2e3c50;
$font-table-light: #b8d4dd;
$font-table-dark: #3e3e3e;

$font-new-dark: #212b36;
$font-new-text-gray: #637381;
$font-dashed-gray: #6e7886;

$table-header: #70a9bb;
$table-group-header: #fcfcfb;

$navbar-highlight: #2c4254;
$navbar-border: #596f80;

$btn-primary: #33a8db;
$btn-muted: #bfcfd9;

$menu-toggle-width: 1019px;
$minimum-width: 1024px;
$content-respond-width: 600px;

// Screen sizes
$tablet-width: 1024px;
$phone-width: 736px;

$screen-xxxs-max: 370px;
$screen-xxs-max: 500px;
$screen-xs-max: 767px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1440px;
$screen-xxl-min: 1250px;
$screen-xxl-max: 1500px;
$screen-sm-min: 768px;

// Theme: yoco2021 colours
// as far as possible, these colours should not be referenced
// from components, but rather create a value in the themes below
// so that updating themes can just happen here and not all over the app
$Brand-Orange: #EE4200;
$Brand-Yellow: #FFA400;
$Brand-Green: #014737;
$Brand-Blue: #00A9E0;
$Brand-Black: #222222;
$White: #FFFFFF;
$Gray-900: #4C4E52;
$Gray-700: #878B90;
$Gray-500: #C3C8D1;
$Gray-300: #CED4DE;
$Gray-200: #E4E9F1;
$Gray-100: #F8F9FB;
$Blue-900: #005570;
$Blue-700: #0087B3;
$Blue-500: #00A9E0;
$Blue-300: #66CBEC;
$Blue-100: #CCEEF9;
$Yellow-900: #CC8615;
$Yellow-700: #E69717;
$Yellow-500: #FFA81A;
$Yellow-300: #FFCB76;
$Yellow-100: #FFEED1;
$Green-900: #015944;
$Green-700: #018567;
$Green-500: #019472;
$Green-400: #48AF97;
$Green-300: #67BFAA;
$Green-100: #CCEAE3;
$Green-50: #E6F4F1;
$Red-900: #190102;
$Red-700: #7C060A;
$Red-500: #F70c13;
$Red-100: #FDCED0;
$Red-300: #FA6D71;
$Onboarding-Green: #E7EDEB;
$Orange-800: #FC4C02;

$SharpGrotesk: 'SharpGrotesk', 'Inter', Helvetica, sans-serif;
$SharpGrotesk25: 'SharpGrotesk25', 'Inter', Helvetica, sans-serif;
$Inter: 'Inter', Helvetica, sans-serif;

$Padding-Loose: 32px;
$Padding-Default: 16px;
$Padding-Tight: 12px;
$Padding-Tighter: 8px;


/**
 * Yoco themes
 *
 * theme values should be named `{componentName}-{className}-{property}`
 * for example the value for:
 * .blockHeader.closeButton {
 *   color: ...colorValue
 * }
 * should be `blockHeader-closeButton-color: $someColor,`
 *
 * Make sure the same value exist in all themes.
 * Please try order these in alphabetical order as it will grow significantly
 * and will make it easier to find some value.
 */
$themes: (
  yoco2021: (
    font: $Inter,
    font-size: 15px,
    font-color: $Brand-Black,
    font-color-negative: $Red-500,

    title-font-size: 32px,
    title-font: $SharpGrotesk,
    title-font-weight: bold,
    title-font-color: $Brand-Black,

    subtitle-font: $Inter,
    subtitle-font-size: 24px,
    subtitle-font-weight: bold,
    subtitle-font-color: $Brand-Black,

    heading-font-size: 32px,
    heading-font: $SharpGrotesk,
    heading-font-weight: bold,
    heading-font-color: $Brand-Black,
    heading-line-height: 1.35,

    sub-heading-font-size: 13px,
    sub-heading-font: $Inter,
    sub-heading-font-weight: bold,
    sub-heading-font-color: $Brand-Black,

    divider-color: $light-gray-divider,

    onboarding-background-color: $Onboarding-Green,
    onboarding-task-border-radius: 10px,

    content-background-color: $Gray-100,
    content-overflow-background-color: $Gray-100,
    content-padding-size: $Padding-Loose,
    content-md-padding-size: $Padding-Default,
    content-sm-padding-size: $Padding-Tight,

    container-border-radius: 4px,
    container-warning-border-radius: 8px,
    container-border: 1px solid $Gray-200,

    alert-border: 0px,

    alert-info-background-color: $Blue-100,
    alert-info-border-color: $Blue-900,
    alert-info-font-color: $Blue-900,

    alert-warning-background-color: $Yellow-100,
    alert-warning-border-color: $Yellow-900,
    alert-warning-font-color: $Yellow-900,

    alert-danger-background-color: $Red-100,
    alert-danger-border-color: $Red-900,
    alert-danger-font-color: $Red-900,

    alert-success-background-color: $Green-100,
    alert-success-border-color: $Green-900,
    alert-success-font-color: $Green-900,

    alert-padding: 16px,
    alert-font-size: 15px,
    alert-margin-bottom: 16px,

    spinner-color: $Brand-Black,

    menu-container-background: $White,
    menu-background-color: $White,
    menu-active-font: $Inter,
    menu-active-font-weight: bold,
    menu-font: $Inter,
    menu-font-size: 13px,
    menu-font-color: $Brand-Black,
    menu-font-weight: 500,
    menu-text-transform: none,
    menu-active-background-color: $Brand-Black,
    menu-active-font-color: $White,
    menu-bottom-border: 0px,
    submenu-font: $Inter,
    submenu-font-weight: normal,
    submenu-background-color: $White,
    submenu-font-color: $Brand-Black,
    submenu-active-background-color: $Gray-100,
    submenu-active-font-weight: 500,
    submenu-active-font-color: $Green-700,
    submenu-border-color: $Gray-200,

    top-bar-background-color: $Gray-100,
    top-bar-font-color: $Brand-Black,
    top-bar-font: $Inter,

    card-border: 1px solid $Gray-200,
    card-box-shadow: 2px 2px 4px 0 $Gray-300,
    card-background-color: $white,
    card-warning-color: $Yellow-100,
    card-font-color: $Brand-Black,

    card-header-background-color: $White,
    card-header-text-transform: none,
    card-header-font-size: 15px,
    card-header-font-weight: 500,
    card-header-font-color: $Brand-Black,
    card-header-border-bottom: 1px solid $Gray-200,
    card-header-padding: 12px 10px 12px 20px,

    card-sub-heading-font-color: $Gray-900,
    card-sub-heading-font-size: 11px,
    card-sub-heading-font-weight: 500,
    card-sub-heading-text-transform: uppercase,

    insight-tab-inactive-background-color: $Gray-100,
    insight-tab-border: 1px solid $Gray-200,
    insight-tab-active-background-color: $White,

    insight-value-font: $Inter,
    insight-value-font-size: 24px,
    insight-value-font-weight: 500,
    insight-fact-color: $Green-400,
    insight-neutral-color: $Brand-Black,
    insight-positive-color: $Green-400,
    insight-negative-color: $Red-300,

    insight-positive-background-color: white,
    insight-negative-background-color: white,
    insight-neutral-background-color: white,

    form-label-font-size: 13px,
    form-label-font-weight: 500,
    form-label-text-transform: none,
    form-label-active-color: $Brand-Black,
    form-label-disabled-color: $Brand-Black,
    form-label-line-height: 25px,

    form-field-background: $White,
    form-field-font-size: 15px,
    form-field-border: 1px solid $Gray-200,
    form-field-active-border: 1px solid $Blue-700,
    form-field-disabled-border: 1px solid $Gray-200,
    form-field-disabled-background: $Gray-200,
    form-field-border-radius: 4px,
    form-field-addon-color: $Gray-500,
    form-field-padding: $Padding-Tight,
    form-field-placeholder-color: $Gray-500,

    form-control-inactive-color: $Brand-Black,
    form-control-inactive-border-color: $Brand-Black,
    form-control-active-color: $Green-500,
    form-control-active-border-color: $Brand-Black,

    list-border: 1px solid $Gray-200,
    list-border-radius: 4px,
    list-item-color: $Brand-Black,
    list-item-padding: $Padding-Tight,
    list-item-border: 1px solid $Gray-200,
    list-item-title-color: $Brand-Black,
    list-item-title-font-size: 15px,
    list-item-title-font-weight: normal,
    list-item-subtitle-color: $Gray-700,
    list-item-subtitle-font-size: 13px,
    list-item-subtitle-font-weight: normal,
    list-item-description-color: $Gray-900,
    list-item-description-font-size: 15px,
    list-item-description-font-weight: normal,
    list-item-subdescription-color: $Gray-900,
    list-item-subdescription-font-size: 15px,
    list-item-subdescription-font-weight: normal,

    tab-font-size: 11px,
    tab-font-weight: bold,
    tab-text-transform: uppercase,
    tab-active-border-color: $Brand-Black,
    tab-active-border-width: 0px 0px 2px 0px,
    tab-active-background-color: transparent,

    tabs-dropdown-background: transparent,
    tabs-dropdown-font-size: 11px,
    tabs-dropdown-font-weight: bold,
    tabs-dropdown-text-transform: uppercase,
    tabs-dropdown-border-color: $Brand-Black,
    tabs-dropdown-border-width: 0px 0px 2px 0px,

    table-header-font-color: $Gray-900,
    table-header-font-size: 11px,
    table-header-font-weight: 500,
    table-header-text-transform: uppercase,
    table-body-border: 1px solid $Gray-200,
    table-row-border-bottom: 1px solid $Gray-200,
    table-selected-row-background: $Blue-100,

    filters-background-color: transparent,
    filter-background-color: white,
    filter-border-color: $Gray-200,
    filter-border-width: 1px,
    filter-standalone-border-width: 1px,
    filter-border-radius: 4px,
    filter-font-size: 13px,
    filter-font-weight: 500,
    filter-font-color: $Gray-900,

    button-border-radius: 4px,
    button-font-weight: bold,
    button-font-size: 15px,

    button-charge-font-color: $White,
    button-charge-background-color: $Brand-Blue,
    button-charge-border: none,

    button-primary-font-color: $White,
    button-primary-background-color: $Green-500,
    button-primary-border: none,

    button-secondary-font-color: $White,
    button-secondary-background-color: $Brand-Black,
    button-secondary-border: none,

    button-tertiary-font-color: $Gray-900,
    button-tertiary-background-color: $Gray-200,
    button-tertiary-border: none,

    button-quaternary-font-color: $White,
    button-quaternary-background-color: $Orange-800,
    button-quaternary-border: none,

    button-destructive-font-color: $White,
    button-destructive-background-color: $Red-500,
    button-destructive-border: none,

    button-subtle-font-color: $Brand-Black,
    button-subtle-background-color: $Gray-100,
    button-subtle-border: none,

    button-dismiss-font-color: $Brand-Black,
    button-dismiss-background-color: $Gray-100,
    button-dismiss-border: none,

    popup-title-font-size: 24px,

    color-info: $Blue-500,
    color-warning: $Yellow-500,
    color-danger: $Red-500,

    flagship-error-card-background: $Gray-200,
    flagship-error-card-sub-heading-font-size: 14px,
    flagship-error-card-font-size: 12px,
    flagship-error-card-button-font-size: 18px,
    flagship-success-heading-font-size: 20.8px,
    flagship-upload-label: 16px,
  ),
);

// set $use-theme variable to the theme name you want to use above.
$use-theme: 'yoco2021';

/**
 * get a key from a map if it exists, else throw an error
 * so that the styles don't compile.
 */
@function getValidMapValue($map, $key) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  } @else {
    @error "Property '#{$key}' not found in map: #{$map}.";
  }
}

/**
 * Use this function to get any value from the current theme:
 * ` color: getThemeValue('blockHeader-h1-color');`
 */
@function getThemeValue($value) {
  $currentTheme: getValidMapValue($themes, $use-theme);
  @return getValidMapValue($currentTheme, $value);
}

@function isNewTheme() {
  @return $use-theme == 'yoco2021';
}

/**
* In lieu of adding an entire proper theme for Flagship in portal, we define some
* relatively-common variables here that may or may not be re-used.
**/

$flagship-subheading-font-weight: 600;
$flagship-subheading-font-size: 17.6px;
$flagship-subheading-margin: 0 0 5px 0;

.flagshipSubheading {
  text-align: left;
  font-size: $flagship-subheading-font-size;
  font-family: $SharpGrotesk;
  font-weight: $flagship-subheading-font-weight;
  margin: $flagship-subheading-margin;
}
