@import "../../../../../../assets/styles/variables.scss";

.root {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    margin: 0;
  }
}

.inputWrapper {
  position: relative;
  flex: 1;
  margin-right: $Padding-Tighter;

  input {
    width: 100%;
    padding: $Padding-Tighter 24px $Padding-Tighter $Padding-Tighter;
  }
}

.inputIcon {
  position: absolute;
  right: $Padding-Tighter;
  top: $Padding-Tighter;
  line-height: 1;
  cursor: pointer;
}

.action {
  position:relative;
}

.toast {
  opacity: 0;
  transition: all .3s ease;
  position: absolute;
  top: -5px;
  left: 50%;
  width: 80%;
  border-radius: 4px;
  text-align: center;
  transform: translateY(50%) translateX(-50%);
  background: getThemeValue('alert-success-background-color');
  padding: 2px 3px;
  font-size: 12px;
  color: getThemeValue('alert-success-font-color');
  border-radius: getThemeValue('form-field-border-radius') / 2;
}

.showToast {
  opacity: 1;
  transform: translateY(-102%) translateX(-50%);
}

.button {
  position: relative;
  z-index: 1;
  @media (max-width: $screen-xxs-max) {
    display: none;
  }
}
