@import '../../../../assets/styles/variables.scss';

.section {
  text-align: center;

  .faqContainer {
    padding-top: 20px;

    @media (max-width: $tablet-width) {
      padding: 20px;
    }
  }
}
