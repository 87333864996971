@import "../../assets/styles/variables.scss";

.dashboard {
  color: $font-dark;
  font-family: getThemeValue('font');

  .column {
    margin-right: 18px;

    @media (max-width: $menu-toggle-width) {
      margin-right: 0px;
    }
  }

  .insightsBanner {
    display: flex;
    flex-direction: row;

    @if isNewTheme() {
    } @else {
      margin: 24px 18px;
    }

    .flexNone {
      flex: none;
    }

    .flexOne {
      flex: 1
    }

    .flexTwo {
      flex: 2
    }
  }

  .tabContent {
    @if isNewTheme() {
      background-color: white;
      margin: 0;
      padding: 24px 18px;
      border: getThemeValue('card-border');
      border-width: 0px 1px 1px 1px;
      box-shadow: getThemeValue('card-box-shadow');
      @media (max-width: $menu-toggle-width) {
        padding: 24px 0px;
        border: 0;
      }
    } @else {
    }
  }

  @media (max-width: 1020px) {
    .insightsBanner {
      display: block;
      @if isNewTheme() {
        margin: 0;
      } @else {
        margin: 0px 16px;
        margin-top: 16px;
      }
    }
  }

  .insightsBanner .graphWrapper {
    flex: 1;
    @if isNewTheme() {
    } @else {
      margin: 0 9px;
    }
    background: #fff;
    color: #333;
    border: getThemeValue('card-border');
    border-radius: getThemeValue('container-border-radius');
    overflow: none;

    &.tabBlock {
      box-shadow: getThemeValue('card-box-shadow');
      @media (max-width: $menu-toggle-width) {
        margin: 0px 10px 24px;
      }
    }

    &.nested {
      @if isNewTheme() {
        border: 0;
      }
    }

    tbody {
      @if isNewTheme() {
        border: 0;
      }
    }

    .graphHeader {
      text-align: left;
      padding: getThemeValue('card-header-padding');
      height: 56px;

      color: getThemeValue('card-header-font-color');
      font-size: getThemeValue('card-header-font-size');
      font-weight: getThemeValue('card-header-font-weight');
      text-transform: getThemeValue('card-header-text-transform');
      border-bottom: getThemeValue('card-header-border-bottom');
      background-color: getThemeValue('card-header-background-color');

      &.noBottomBorder {
        border-bottom: 0;
      }

      i {
        float: right;
        line-height: 24px;
      }

      .headerLink {
        float: right;
        padding-right: 6px;
        text-shadow: none;
        font-size: 11px;
        font-family: getThemeValue('font');
        font-weight: bold;

        a {
          text-transform: uppercase;
          color: getThemeValue('font-color');
        }

        span.headerText {
          @media(max-width: $screen-xxs-max) {
            display: none;
          }

          @media (min-width: $menu-toggle-width) and (max-width: 1330px) {
            display: none;
          }
        }
      }

      span {
        float: left;
        line-height: 24px;
      }
    }

    &.flexNone {
      flex: none;
    }

    &.flexOne {
      flex: 1
    }

    &.flexTwo {
      flex: 2
    }

    .salesData {
      @if isNewTheme() {
        margin: 0px 20px;
        padding: 20px;
        border: getThemeValue('card-border');
        border-bottom-width: 0px;

        &:last-of-type {
          border-bottom-width: 1px;
          margin-bottom: 20px;
        }
      } @else {
        height: 133.3px;
        padding: 18px 10px;
      }
      text-align: left;
      display: flex;
      height: 133.3px;

      @if isNewTheme() {
      } @else {
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        text-shadow: 0 1px transparentize(black, 0.85);
      }

      &.shadow {
        @if not isNewTheme() {
          box-shadow: inset 0px 4px 4px -2px rgba(0,0,0,0.15);
        }
      }

      &.first {
        @if not isNewTheme() {
          background: #2BB0D9;
        }
      }

      &.second {
        @if not isNewTheme() {
          background: #56CBD7;
        }
      }

      &.third {
        @if not isNewTheme() {
          background: #8CDBDB;
        }
      }

      .salesDataDivider {
        @if isNewTheme() {
          display: none;
        }
      }

      .salesDataDetails {
        min-width: 160px;
        max-width: 216px;
        width: 100%;

        @if isNewTheme() {
          display: flex;
          flex-direction: column;
        }

        @media(max-width: 1130px) {
          min-width: 140px;
          width: 140px;
        }

        @media(max-width: $menu-toggle-width) {
          width: 100%;
        }

        .salesDataValue {
          font-size: getThemeValue('insight-value-font-size');
          font-weight: getThemeValue('insight-value-font-weight');

          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;

          @if isNewTheme() {
            width: 100%;
            margin-top: auto;
          } @else {
          }

          @media(max-width: 1200px) {
            font-size: 28px;
            width: 160px;
          }

          @media(max-width: $menu-toggle-width) {
            font-size: 36px;
          }

          @media(max-width: 330px) {
            font-size: 30px;
          }
        }
      }

      .salesDataGraph {
        flex: 1;
        align-items: right;
        min-width: 130px;
        float: right;
        margin-top: 24px;

        .salesValue {
          text-align: right;
        }
      }
    }

    @media(max-width: $menu-toggle-width) {
      margin: 0 9px 24px;
    }
  }

  .insightsBanner .insightWrapper {
    flex: 1;
    @if isNewTheme() {
      margin: 0;
    } @else {
      margin: 0 -28px 0 18px;
    }
    background: getThemeValue('card-background-color');
    border: getThemeValue('card-border');
    border-radius: getThemeValue('container-border-radius');
    overflow: hidden;

    // Don't double up on the boarders from our card
    table {
      border: none !important;

      tbody {
        border: none;
      }
    }

    .insightHeader {
      text-align: left;
      padding: getThemeValue('card-header-padding');
      font-size: getThemeValue('card-header-font-size');
      text-transform: getThemeValue('card-header-text-transform');
      font-weight: getThemeValue('card-header-font-weight');
      background-color: getThemeValue('card-header-background-color');
      color: getThemeValue('card-header-font-color');
      border-bottom: getThemeValue('card-header-border-bottom');

      i {
        float: right;
        line-height: 24px;
      }

      a {
        color: getThemeValue('card-header-font-color');
        text-transform: uppercase;
      }

      a:hover {
        text-decoration: none;
      }

      .headerLink {
        float: right;
        padding-right: 6px;
        text-shadow: none;
        font-size: 11px;
        font-family: getThemeValue('font');
        font-weight: bold;

        span.headerText {
          @media(max-width: $screen-xxs-max) {
            display: none;
          }
        }
      }

      span {
        line-height: 24px;
      }
    }

    .insightLine {
      padding: 0 8px;
      width: 20px;
      border-bottom: 1px solid #fff;
    }

    .insightBody {
      overflow-y: auto;
      font-size: 16px;
    }

    .insightValue {
      font-size: 52px;
      line-height: 60px;
      color: #00a2e0;
      display: inline-block;
      padding: 20px;
      text-align: center;
    }

    .insightFooter {
      text-align: right;
      text-transform: uppercase;
      background-color: #00A2E0;
      color: #fff;
      padding: 6px;
    }

    @media(max-width: $menu-toggle-width) {
      @if not isNewTheme() {
        margin: 0px -14px 0 -14px;
      }
    }
  }

  .insightsBanner .settlementWrapper {
    flex: 1;
    margin: 0 18px 0 -28px;
    background: #fff;
    color: #333;
    border: 1px solid #e8ebeb;

    .settlementHeader {
      text-align: left;
      padding: 17px 10px 17px 20px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: #00A2E0;
      color: #fff;
      height: 56px;

      .settlementDate {
        font-family: getThemeValue('font');

        @media(max-width: $screen-sm-min) {
          display: none;
        }
      }

      a:hover {
        text-decoration: none;
      }

      i {
        float: right;
        padding: 0 8px;
        font-size: 20px;
        text-shadow: none;
      }

      .headerLink {
        float: right;
        padding-right: 6px;
        text-shadow: none;
        font-size: 14px;
        font-family: getThemeValue('font');

        span.headerText {

          @media(max-width: $phone-width) {
            display: none;
          }
        }
      }

      span {
        float: left;
        line-height: 24px;
      }
    }

    .settlementBlockAmount {
      text-align: left;
      font-size: 40px;
      line-height: 60px;
      background-color: #00A2E0;
      color: #fff;
      display: flex;
      padding: 20px;
    }

    .settlementBlockLink {
      text-align: right;
      padding: 5px;
      text-transform: uppercase;
      background-color: #00A2E0;
      color: #fff;

      a {
        color: #fff;
        padding-right: 8px;
      }
    }

    @media(max-width: $menu-toggle-width) {
      @if not isNewTheme() {
        margin: 0 -28px;
      }
    }
  }

  @media (max-width: 1020px) {
    .insightsBanner .insightWrapper {
      margin-bottom: 24px;
      display: block;
    }
  }

  table {
    tbody {
      tr {
        &.underlined {
          border-bottom: 1px solid #E4E4E4;
        }
      }

      tr:last-child {
        &.underlined {
          @media(max-width: $menu-toggle-width) {
            border-bottom: none;
          }
        }
      }

      td.icon {
        width: 50px;
      }

      td.date {
        text-overflow: ellipsis;
        white-space: nowrap;

        @media(max-width: 600px) {
          width: 160px;
          left: 10px;
        }
      }

      td.amount {
        width: 150px;
        text-align: right;
        font-weight: 500;
      }

      td.name {
        text-overflow: ellipsis;
        white-space: nowrap;

        @media(max-width: 600px) {
          width: 160px;
          left: 10px;
        }
      }

      td.itemDetails {
        text-overflow: ellipsis;
        white-space: nowrap;

        @media(max-width: 600px) {
          width: 160px;
          left: 10px;
        }
      }

      td.itemCount {
        width: 190px;
        text-align: right;
        font-weight: 500;

        span {
          font-family: getThemeValue('font');
        }
      }
    }
  }
}
