@import '../../assets/styles/variables';

.outerBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: getThemeValue('content-background-color');

  .pageContainer {
    height: 100%;
    overflow: auto;
  }
}
