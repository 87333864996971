@import '../../../assets/styles/variables.scss';

.container {
  position: relative;

  h1 {
    font-family: getThemeValue('heading-font');
    font-size: getThemeValue('heading-font-size');
    font-weight: normal;
    line-height: getThemeValue('heading-line-height');
    margin: 24px 0;
    padding: 0;
    letter-spacing: -0.02em;
    color: getThemeValue('heading-font-color');
    display: inline-block;

    @media (max-width: $menu-toggle-width) {
      text-align: left;
      display: block;
    }
  }

  @media (max-width: $menu-toggle-width) {
    padding: 0;
  }

  .actionContainer {
    position: absolute;
    bottom: 21px;
    right: -4px;
    display: inline-block;

    @media (max-width: $menu-toggle-width) {
      display: inline-block;
      position: relative;
      right: initial;
      bottom: 16px;
    }

    @media (max-width: $content-respond-width) {
      display: block;
    }
  }
}
