.root {
  text-align: left;
  margin: -32px;
  padding: 12px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
