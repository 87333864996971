@import '../../../../assets/styles/variables.scss';

.row {
  align-items: center;
  width: 50%;
  text-align: justify;
  margin: 0 auto;
  flex: 1;
  flex-direction: row;
  display: flex;
  min-height: 45px;

  @media (max-width: $screen-xs-max) {
    width: 80%;
    align-items: flex-end;
  }

  @media (max-width: $screen-xxs-max) {
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }

  @media (max-width: $screen-xxxs-max) {
    width: 80%;
  }

  .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    flex: 0.5;
  }

  .titleDisplay {
    font-size: 15px;
    font-weight: bold;
    flex: 0.5;
  }
}
