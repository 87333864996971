@import '../../../../../assets/styles/variables.scss';

.forms_formWrapper {
  height: 100%;
}

.userInfo {
  padding: 0em 1em 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .flexContainer {
    display: flex;
    gap: 0.6em;

    @media screen and (max-width: $phone-width) {
      flex-direction: column;
    }
  }
}

.permissionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0em 1em 1em;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c3c8d1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c3c8d1;
  }
}

.card {
  display: flex;
  overflow-x: auto;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }

  .carousel {
    gap: 0.6em;
    display: flex;
    overflow: scroll;
    margin-bottom: 16px;

    .carouselItem {
      display: flex;
      padding: 0.1em 0.6em;
      align-items: center;
      border-radius: 36px;
      border-width: 0.2rem;
      background-color: #e7e9ed;
      border-style: solid;
      border-color: white;
      h6 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
    }

    .carouselItemSelected {
      @extend .carouselItem;
      border-style: solid;
      border-color: #31a183;
      background-color: $Green-100;
    }
  }

  .description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    max-height: 54px;
    margin-bottom: 16px;
  }

  .dropdownButton {
    border: unset;
    padding: unset;
    font-size: 0.8em;
    width: fit-content;
    background-color: inherit;
    display: flex;
    color: #31a183;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 9px;

    i {
      color: $Green-300 !important;
    }
  }

  .roleContainer {
    background: #f3f5f8;
    border-radius: 0.5em;

    -webkit-animation: scale-in-ver-top 0.26s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-ver-top 0.26s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    padding: 0.1em 16px;
  }

  .roles {
    .roleHeader {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      text-transform: uppercase;
      color: #878b90;
      margin-bottom: 16px;
    }

    .itemContainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 0.2em;
      h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin: 0px 0px 8px 0px;
      }
      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .itemContainerCustom {
      @extend .itemContainer;
      gap: 1em;
      display: flex;
      align-items: flex-start;
      flex-direction: row;

      .checkboxButton {
        width: 24px;
        height: 24px;
        i {
          font-size: 20px !important;
        }
      }
    }

    @-webkit-keyframes scale-in-ver-top {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
      }
    }
    @keyframes scale-in-ver-top {
      0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
      }
    }

    hr {
      margin: unset;
      border-top: 1px solid #c3c8d1;
      margin-top: 16px;
    }

    &:last-child {
      margin-bottom: 0em;
      hr {
        display: none;
      }
    }
  }
}

.actionButtons {
  left: 0px;
  bottom: 0px;
  width: 100%;
  position: absolute;
  padding: 1em 1em 1em;
  background-color: white;
}
