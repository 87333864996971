@import '../../../../assets/styles/variables.scss';

.mainContainer-base {
  padding: 0em 2em;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.splitViewStart {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  .mainContainer {
    @extend .mainContainer-base;
  }
  .sideContainer {
    display: none;
  }
}

.splitViewBase {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  .sideContainer {
    position: fixed;
    right: 0px;
    width: 40em;
    height: 100vh;
    top: 0px;
    background-color: $Gray-100;
    box-shadow: 0 0 4px 0 #ced4de;

    .sideContainerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1em;
      h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: semi-bold;
        font-size: 24px;
        line-height: 125%;
      }
      .title {
        font-family: getThemeValue('subtitle-font');
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
      }
      .header {
        display: flex;
        align-items: flex-end;
      }
      .hiddenBackButton {
        display: none;
      }
      button {
        background-color: inherit;
        border: none;
      }
    }
    .actionBar {
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      padding-left: 1em;
    }
  }
}

.splitViewClosed {
  @extend .splitViewBase;

  .mainContainer {
    @extend .mainContainer-base;
  }

  .sideContainer {
    @-webkit-keyframes slide-out-right {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
      }
    }
    @keyframes slide-out-right {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
      }
    }
    -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.splitViewOpen {
  @extend .splitViewBase;

  .mainContainer {
    @extend .mainContainer-base;
  }

  .sideContainer {
    @-webkit-keyframes slide-in-right {
      0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-right {
      0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }
    -webkit-animation: slide-in-right 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    display: grid;
    grid-template-rows: 6% 90%;
  }

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;

    .mainContainer {
      display: none;
    }

    .sideContainer {
      width: 100%;
    }
  }
}

.clickableText {
  cursor: pointer;
  background-color: inherit;
  border: none;
}

.actionButtons {
  left: 0px;
  bottom: 0px;
  width: 100%;
  position: absolute;
  padding: 1em 1em 1em;
  background-color: white;
}
