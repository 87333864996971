@import "../../assets/styles/variables.scss";

.toggleContainer {
  @media (max-width: 1408px) {
    display: block;
    position: absolute;
    top: 48px;
  }
}

.filterBatchInner{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}


.newTable {
  width: 100%;
}

thead {
  th.spacer {
    width: 45px;
  }

  th.transactionStatus {
    width: 180px;

    @media (max-width: $tablet-width) {
      width: 120px;
    }
  }

  th.time {
    width: 90px;
    padding-left: 24px;

    @media (max-width: $tablet-width) {
      padding-left: 8px;
    }
  }

  th.date {
    width: 110px;
  }

  th.transactionName {
    width: 200px;

    @media (max-width: $screen-xs-max) {
      width: 130px;
    }
  }

  th.billName {
    width: 200px;

    @media (max-width: $screen-xl-min) {
      width: 130px;
    }
  }

  th.transactionType {
    width: 180px;

    @media (max-width: $tablet-width) {
      width: 130px;
    }
  }

  th.transactionItems {
  }

  th.transactionAmount {
    width: 140px;
    padding-right: 24px !important;
    text-align: right;

    @media (max-width: $screen-xs-max) {
      width: initial;
      padding-right: 12px !important;
    }
  }

  tr {
    th {
      background-color: $off-white;
      color: $yoco-blue;
      font-weight: 500;
      font-weight: normal;
      font-size: 16px;
      border-bottom: 1px solid #e7f4f8;
    }

    .invisible-header {
      th {
        height: 0px;
        border-bottom: none;
        border-top: none;
        padding: 0;
      }

      th.hideOnMobile {
        @media (max-width: $screen-xs-max) {
          width: 0;
          overflow: hidden;
          font-size: 0px;
          padding: 0px;
          margin: 0px;
        }
      }
    }


    th.noPadding {
      padding-left: 4px;
      padding-right: 4px;
    }

    th.headerDateStyle {
      width: 150px;
    }

    th.headerStyle {
      text-align: right;
      padding-right: 24px !important;
    }
  }
}

.invoicePeriod {
  .littleOne {
    display: none;
  }

  @media(max-width: $screen-md-min) {
    .bigOne {
      display: none;
    }
    .littleOne {
      display: block;
    }
  }
}

.settlementTransactionAmount, .settlementFeeAmount {
  .amount {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.salesHistoryHeaderSpacer {
  cursor: initial !important;
  height: 16px !important;
}

.blockDescription {
  font-size: 14px;
  color: #657683;
  flex: 2;
  height: 156px;

  p {
    line-height: 15px;
  }

  i {
    color: $yoco-blue;
    line-height: 0.75;
    vertical-align: -15%;
    width: 20px;
    height: 21px;
    display: inline-block;
  }

  .transactionPeriod {
    letter-spacing: 0.38px;
    color: #a7a7a7;
    font-weight: bold;
    font-size: 12px;
  }

  @media(max-width: 1146px) {
    font-size: 14px;
  }

  @media(max-width: $menu-toggle-width) {
    height: 100%;
    margin: 5px 5px 20px;
  }
}

.rightButtons {
  float: right;
  margin: 16px;

  button {
    border-radius: 5px;
    background: $off-white;
  }
}

.insightsBanner {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin: 24px -18px;

  @media(max-width: $menu-toggle-width) {
    display: block;
  }
}

.insightsBanner .wrapper {
  flex: 1;
  margin: 0 18px;
  background: $yoco-blue;
  max-height: 196px;
  border: 1px solid #e8ebeb;

  .settlementBlockAmount {
    line-height: 100px;
  }

  @media(max-width: 1077px) {
    max-height: 216px;
  }

  @media(max-width: 1018px) {
    background: #FFF;
    margin: 0 18px 24px;
  }
}

.insightsBanner .insightWrapper {
  flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  margin: 0 18px;
  background: #fff;
  color: #333;
  border: 1px solid #e8ebeb;

  .insightHeader{
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #e8ebeb;
    font-size: 16px;
  }

  .settlementBlockAmount{
    text-align: center;
    font-size: 40px;
    line-height: 30px;
    color: #00a2e0;
    display: flex-box;
    padding: 20px;
  }

  .settlementBlockText{
    text-align: center;
  }

  .settlementBlockLink {
    text-align: center;
    color: #868686;
    padding: 5px;
  }

  &.doubleFlex {
    flex: 2;
  }

}

.overlayStyle {
  display: inline-flex;
  float: right;
}

.tableStyle {
  display: flex;
  width: 100%;
}


@media (max-width: 1020px) {
  .insightsBanner .insightWrapper {
    margin-bottom: 24px;
    display: block;
  }
}

.hideOnMobile {
  @media(max-height: $menu-toggle-width) {
    width: 0;
    overflow: hidden;
    font-size: 0px;
    padding: 0px;
    margin: 0px;
  }
}

.outerBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
  min-height: 100%;
}

.transactionSplitContent {
  min-width: 647px;

  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 647px) {
    min-width: initial;
  }
}

.payoutBlocks {
  @media (min-width: $tablet-width) {
    display: flex;
    margin-left: -$Padding-Default;
    margin-right: -$Padding-Default;
  }

  .payoutBlock {
    @media (min-width: $tablet-width) {
      flex-grow: 1;
      flex-basis: 100%;
      margin: 0px $Padding-Default;
    }

    margin-bottom: $Padding-Default;
  }
}

.salesReport {
  table {
    position: relative;
    border-bottom: none;
    width: 100%;
    table-layout: fixed;

    @media (max-width: $screen-xs-max) {
      table-layout: auto;
    }

    tr {
      height: 46px;

      td {
        border-bottom: none;
      }

      &.strikeout td:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
      }
      &.footerDiscountRow {
        border: solid 1px rgb(229, 229, 229);
      }
      &.underlinedRow {
        border-bottom: 1px solid #E4E4E4;
      }

      &.groupHeaderRow {
        height: 33px;

        td {
          line-height: 24px;
          padding-bottom: 0;
          vertical-align: bottom;
        }
      }

      &.childRow {
        height: 30px;

        &.underlinedRow {
          td {
            height: 36px;
          }
        }

        td {
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      &.invisibleHeader {
        height: 0px;

        th {
          border-bottom: 0 !important;
        }
      }

      &.visibleHeader {
        height: 34px;

        th {
          padding: 0;
          padding-left: 24px;
          border: none;
          color: #3E3E3E;
          font-size: 14px;
          font-weight: 500;
          background: none;
          border-bottom: 1px solid #BBBBBB;
          text-overflow: ellipsis;
          overflow: hidden;

          @media(max-width: $tablet-width) {
            padding-left: 8px;
          }
        }
      }

      &.spacerHeader {
        height: 0px;
      }

      &.subtleBackground {
        td {
          background-color: #F1F6F6;

          &.spacer {
            background: white;
          }
        }
      }

      &.selected {
        td {
          background-color: $subtle-blue-background;

          &.spacer {
            background: white;
          }
        }
      }
    }

    td {
      border-top: none;
      padding-top: 6px;
      padding-bottom: 6px;
      line-height: 33px;
      overflow: hidden;
      text-overflow: ellipsis;
      &.spacer {
        border-top: none;
      }
    }

    &.tableClickable {
      tbody tr {
        &:hover {
          cursor: pointer;
        }
      }
    }

    thead {
      th.time {
        width: 60px;

        @media (max-width: $screen-xs-max) {
          padding-left: 12px;
        }
      }

      th.date {
        width: 110px;
      }

      th.transactionStatus {
        width: 28px;
      }

      th.transactionName {
        width: 250px;

        @media (max-width: $screen-xs-max) {
          width: 130px;
        }
      }

      th.transactionType {
        width: 100px;
      }

      th.transactionItems {
        @media (max-width: $screen-md-min) {
          display: none;
        }
      }

      th.transactionAmount {
        width: 250px;
        padding-right: 24px !important;
        text-align: right;

        @media (max-width: $screen-xs-max) {
          padding-right: 12px !important;
          width: initial;
        }
      }

      tr {
        .invisible-header {
          th {
            height: 0px;
            border-bottom: none;
            border-top: none;
            padding: 0;
          }

          th.hideOnMobile {
            @media (max-width: $screen-xs-max) {
              width: 0;
              overflow: hidden;
              font-size: 0px;
              padding: 0px;
              margin: 0px;
            }
          }
        }

        th {
          background-color: $off-white;
          color: $yoco-blue;
          font-weight: 500;
          font-weight: normal;
          font-size: 14px;
          border-bottom: 1px solid #e7f4f8;
        }

        th.noPadding{
          padding-left:4px;
          padding-right:4px;
        }

        th.headerDateStyle {
          width: 150px;
        }

        th.headerStyle {
          text-align: right;
          padding-right: 24px !important;
        }
      }
    }

    tbody {
      font-family: getThemeValue('font');
      font-size: 14px;
      font-weight: normal;
      color: $font-medium;

      tr {
        position: relative;
        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }

        td {
          position: relative;
          line-height: 24px;

          .hideOnMobile {
            @media(max-height: $menu-toggle-width) {
              width: 0;
              overflow: hidden;
              font-size: 0px;
              padding: 0px;
              margin: 0px;
            }
          }
        }

        td.hideOnMobile {
          @media (max-width: $screen-xs-max) {
            width: 0;
            overflow: hidden;
            font-size: 0px;
            padding: 0px;
            margin: 0px;
          }
        }
      }

      td.date {
        width: 110px;
        padding: 0;
      }

      td.dateTime {
        width: 150px;
        padding: 0;
      }

      td.transactionAmount {
        font-size: 14px;
        text-align: right;

        @media (max-width:$screen-xs-max) {
          padding-right: 12px;

          .amount {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      td.transactionName {
        padding-left:4px;
        width: 300px;

        @media (max-width: $screen-xs-max) {
          width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      td.itemSummary {
        white-space: nowrap;
        overflow: hidden;
      }

      td.billName {
        white-space: nowrap;
        overflow: hidden;
      }

      td.transactionStatus {
        padding: 0;
      }

      td.amount {
        width: 200px;
        text-align: right;
      }

      td.time {
        width: 1px;
        padding-left: 24px;
        padding-right: 4px;
        white-space: nowrap;
      }

      td.typeAndState {
        width: 60px;
      }

      td.image {
        width: 44px;
        padding: 12px 12px;
      }

      tr.newGroupHeader {
        color: black;
        font-weight: 500;
        font-size: 14px;
        background-color: #efefef;

        .bigTotal {
          font-size: 20px;
          padding-right: 24px;

          @media (max-width: $screen-xs-max) {
            padding-right: 12px;

            .amount {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .tipAmount {
          font-size: 10px;
          font-weight: light;
          color: $Brand-Green;
        }

        &:hover {
          cursor: default;
        }
      }

      tr.groupHeader{
        height: auto;

        &:hover {
          cursor: default;
        }

        td::before, td.groupHeader::after,
        td:last-child::before, td:first-child::before{
          background: #ddd !important;
          top: 21px;
          z-index: 1;
        }

        td{
          background: $table-group-header;
          color: $table-header;
          line-height: 12px;
          font-size: 90%;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }

      tr.subtleBackground {
        td {
          background-color: #F1F6F6;

          &.spacer {
            background: white;
          }
        }
        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }
      }

      tr.spacerHeader {
        height: 0px;
      }

    }
  }

  .exportButton{
    flex: 1;
    border: 1px solid $yoco-blue;
    color: $yoco-blue;
    text-align: center;
    padding: 10px;
    max-width: 300px;
  }

  :global {
    .insights-banner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      display: -ms-flexbox;
      -ms-flex-direction: row;
      display: -webkit-flex;
      -webkit-flex-direction: row;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;

      @media(min-width: $content-respond-width) {
        margin: 24px 0;
        margin-right: -36px;
      }

      @media(max-width: $content-respond-width) {
        display: block;
        margin: 0;
        margin-top: 16px;
      }

      .insight-wrapper {
        flex: 1;
        -ms-flex: 1;
        -webkit-flex: 1;
        margin: 0 36px 0 0;

        @media(max-width: $content-respond-width) {
          margin: 0 0 24px 0;
          display: block;
        }

        &.insight-content-block {
          box-shadow: getThemeValue('card-box-shadow');

          @media(max-width: $menu-toggle-width) {
            display: none;
          }
        }
      }
    }

    .tabs {
      padding: 0 36px 36px;
    }

    .report-date-display {
      line-height: 20px;
      font-size: 16px;
      font-weight: light;
      color: #333;

      .date {
        font-family: getThemeValue('font');
      }

      @media(max-width: $menu-toggle-width) {
        display: none;
      }
    }

    table {
      tr.visible-header {
        th.headerStyle {
          text-align: right;
          padding-right: 24px !important;
        }
      }
    }

    th {
      padding: 0;
      padding-left: 24px;
      border: none;
      color: #3E3E3E;
      font-size: 14px;
      font-weight: 500;
      background: none;
      border-bottom: 1px solid #BBB;
      text-overflow: ellipsis;
      overflow: hidden;

      &.ranking {
        width: 26px;
      }

      &.product-quantity {
        text-align: center;
        width: 130px;
      }

      &.product-total {
        text-align: right;
        width: 165px;
      }

      &.amount {
        text-align: right;
        padding-right: 24px !important;
      }

      &.number {
        width: 120px;
        text-align: center;
        padding-left: 0;
      }

      &.sku {
        width: 120px;
        text-align: center;
        padding-right: 24px !important;
      }
    }

    td {
      font-family: getThemeValue('font');
      font-size: 14px;
      color: $font-medium;

      @media(max-width: $menu-toggle-width) {
        font-size: 14px;
      }

      &.ranking {
        width: 10px;
        padding: 0;
        text-align: center;
        @if isNewTheme() {
          color: $Gray-500;
        } @else {
          color: $yoco-blue;
        }
      }

      &.product-quantity {
        text-align: center;
      }

      &.product-total {
        text-align: right;
      }

      &.sku {
        margin-right: 8px;
        font-weight: 500;
        font-size: 12px;
        opacity: 0.6;
        text-align: center;
      }

      &.product-name {
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          position: relative;
          top: 2px;
          color: $yoco-blue;
        }

        &.subtext {
          padding-left: 42px;
        }
      }

      &.amount {
        white-space: nowrap;

        .amount {
          display: inline;
        }
      }

      &.number {
        .amount {
          text-align: center;
          display: inline-block;
          width: 100%;
        }
      }

      &.staff-member {
        &.subtext {
          padding-left: 42px !important;
        }
      }

      &.payment-type {
        &.subtext {
          padding-left: 42px !important;
        }
      }

      &.subtext {
        font-size: 14px;
        font-weight: light;
      }
    }

    @media(max-width: $menu-toggle-width) {
      th {
        &.ranking {
          display: none;
        }
      }

      td {
        &.ranking {
          display: none;
        }
      }
    }

    .payment-row {
      position: relative;

      @if isNewTheme() {
        padding: 8px;
        border-bottom: getThemeValue('table-row-border-bottom');

        &:last-of-type {
          border-bottom: none;
        }
      }

      .title {
        font-weight: 500;
      }

      .amount {
        float: right;
        margin-right: 24px;
        font-weight: 500;
      }

      .list {
        display: none;
        padding-left: 8px;

        .subItem {
          margin-left: 12px;
          @if isNewTheme() {
            padding-top: 4px;
          }

          .title {
            font-family: getThemeValue('font');
          }

          .line-item {
            font-weight: light;
            margin-left: 12px;
            @if isNewTheme() {
              padding-top: 4px;
            }

            .amount {
              font-family: getThemeValue('font');
            }
          }
        }
      }

      &.expandable {
        &:after {
          position: absolute;
          top: 10px;
          right: 4px;
          display: block;
          width: 0;
          height: 0;
          margin-top: -3px;
          border-color: #3e3e3e transparent transparent transparent;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          content: ' ';

          @if isNewTheme() {
            top: 18px;
            right: 8px;
          }
        }
      }

      &.expanded {
        &:after {
          border-width: 5px 5px 5px 5px;
          border-color: transparent transparent #3e3e3e transparent;
          border-style: solid;
          margin-top: -8px;
        }

        .list {
          display: block;
        }
      }
    }

    .product-row {
      position: relative;

      @if isNewTheme() {
        padding: 8px;
        border-bottom: getThemeValue('table-row-border-bottom');

        &:last-of-type {
          border-bottom: none;
        }
      }

      .title {
        font-weight: 500;
      }

      .quantity, .amount {
        float: right;
        margin-right: 24px;
        font-weight: 500;
      }

      .list {
        display: none;
        padding-left: 8px;

        .subItem {
          @if isNewTheme() {
            padding-top: 4px;
          }
          .title {
            font-family: getThemeValue('font');
          }

          .line-item {
            font-weight: light;

            @if isNewTheme() {
              padding-top: 4px;
            }

            .amount {
              font-family: getThemeValue('font');
            }
          }
        }
      }

      &.expandable {
        &:after {
          position: absolute;
          top: 10px;
          right: 4px;
          display: block;
          width: 0;
          height: 0;
          margin-top: -3px;
          border-color: #3e3e3e transparent transparent transparent;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          content: ' ';
        }
      }

      &.expanded {
        &:after {
          border-width: 5px 5px 5px 5px;
          border-color: transparent transparent #3e3e3e transparent;
          border-style: solid;
          margin-top: -8px;
        }

        .list {
          display: block;
        }
      }
    }

    .feeDescription {
      margin-left: 4px;
      position: absolute;
    }

    .no-red {
      .red-text {
        color: #3E3E3E !important;
      }
    }

    .faded {
      color: transparentize(#3E3E3E, 0.5);
    }

  }
  .settlementHistoryInfo{
    margin-top: 20px;
    margin-bottom: 28px;

    @media (max-width: $screen-xxs-max) {
      margin-top: 14px;
      margin-bottom: 21px;
    }
  }
}

.transactionList {

  @media(max-width: $phone-width) {
    padding: 0;
  }
}

.salesHistoryPage {
  padding: 0 getThemeValue('content-padding-size') getThemeValue('content-padding-size');

  @media (max-width: $menu-toggle-width) {
    padding: 0px getThemeValue('content-md-padding-size') getThemeValue('content-md-padding-size');
  }

  @media (max-width: $phone-width) {
    padding: 0px getThemeValue('content-sm-padding-size') getThemeValue('content-sm-padding-size');
  }

  table {
    position: relative;

    tr {
      height: 54px;

      &.underlinedRow {
        border-bottom: 1px solid #E4E4E4;
      }

      &.groupHeaderRow {
        height: 33px;

        td {
          line-height: 24px;
          padding-bottom: 0;
          vertical-align: bottom;
        }
      }

      &.childRow {
        height: 30px;

        &.underlinedRow {
          td {
            height: 36px;
          }
        }

        td {
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      &.invisibleHeader {
        height: 0px;

        th {
          border-bottom: 0;
        }
      }

      &.visibleHeader {
        height: 34px;

        th {
          padding: 0;
          padding-left: 24px;
          border: none;
          color: #3E3E3E;
          font-size: 16px;
          font-weight: 500;
          background: none;
          border-bottom: 1px solid #BBBBBB;
          text-overflow: ellipsis;
          overflow: hidden;

          @media(max-width: $tablet-width) {
            padding-left: 8px;
          }
        }
      }

      &.spacerHeader {
        height: 0px;
      }

      &.subtleBackground {
        td {
          background-color: #F1F6F6;

          &.spacer {
            background: white;
          }
        }
      }

      &.selected {
        td {
          background-color: $subtle-blue-background;

          &.spacer {
            background: white;
          }
        }
      }
    }

    td {
      border-top: none;
      padding-top: 6px;
      padding-bottom: 6px;
      line-height: 33px;
      overflow: hidden;
      text-overflow: ellipsis;
      &.spacer {
        border-top: none;
        background: white;
      }
    }

    &.tableClickable {
      tbody tr {
        &:hover {
          cursor: pointer;
        }
      }
    }

    tbody {
      font-family: getThemeValue('font');
      font-size: 14px;
      font-weight: normal;
      color: $font-medium;

      tr {
        position: relative;

        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }

        td {
          position: relative;
          line-height: 24px;

          .hideOnMobile {
            @media(max-height: $menu-toggle-width) {
              width: 0;
              overflow: hidden;
              font-size: 0px;
              padding: 0px;
              margin: 0px;
            }
          }
        }

        td.hideOnMobile {
          @media (max-width: $screen-xs-max) {
            width: 0;
            overflow: hidden;
            font-size: 0px;
            padding: 0px;
            margin: 0px;
          }
        }
      }

      td.date {
        width: 110px;
        padding: 0;
      }

      td.dateTime {
        width: 150px;
        padding: 0;
      }

      td.transactionAmount {
        font-size: 14px;
        text-align: right;
        font-weight: bold;

        @media (max-width:$screen-xs-max) {
          padding-right: 12px;

          .amount {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      td.transactionName {
        width: 300px;

        @media (max-width: $screen-xs-max) {
          width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      td.itemSummary {
        white-space: nowrap;
        overflow: hidden;
      }

      td.transactionStatus {
      }

      td.amount{
        width: 200px;
        text-align: right;
        padding-right: 24px;
      }

      td.time{
        padding-left: 24px;
        padding-right: 0px;
        white-space: nowrap;

        @media (max-width: $tablet-width) {
          padding-left: 8px;
        }
      }

      td.typeAndState{
        width: 60px;
      }

      td.image {
        width: 44px;
        padding: 12px 12px;
      }

      tr.newGroupHeader{
        color: black;
        font-weight: 500;
        font-size: 14px;
        background-color: #F4F6F8;

        .bigTotal {
          font-size: 18px;
          padding-right: 24px;

          @media (max-width: $screen-xs-max) {
            padding-right: 12px;

            .amount {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .tipAmount {
            display: flex;
            float: right;
            font-size: 12px;
            color: $green;
          }
        }

        &:hover {
          cursor: default;
        }
      }

      tr.groupHeader{
        height: auto;

        &:hover {
          cursor: default;
        }

        td::before, td.groupHeader::after,
        td:last-child::before, td:first-child::before{
          background: #ddd !important;
          top: 21px;
          z-index: 1;
        }

        td{
          background: $table-group-header;
          color: $table-header;
          line-height: 12px;
          font-size: 90%;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }

      tr.subtleBackground {
        td {
          background-color: #F1F6F6;

          &.spacer {
            background: white;
          }
        }
        &.selected {
          td {
            background-color: $subtle-blue-background;

            &.spacer {
              background: white;
            }
          }
        }
      }

      tr.spacerHeader {
        height: 0px;
      }
    }
  }
}


.feePopoverTable {
  tbody {
    tr {
      height: 20px;
    }
  }
}

.transactionSummary {
  border: 1px solid #a7bac4;
  margin: 0 auto;
  font-family: getThemeValue('font');
  font-size: 14px;
  font-weight: normal;
  color: #657683;

  .transactionItemSummary {
    line-height: 30px;
  }

  thead {
    tr {
      height: 0;
    }
  }
}

.exportReport {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px; // Half button height
  cursor: pointer;

  @media (max-width: $menu-toggle-width) {
    position: initial;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.export {
  position: absolute;
  right: getThemeValue('content-padding-size');
  cursor: pointer;
  top: 21px;
  height: 32px;
  min-width: initial;

  button {
    margin-right: 0px;
  }

  @media (max-width: $menu-toggle-width) {
    right: getThemeValue('content-md-padding-size');
  }

  @media (max-width: $phone-width) {
    right: getThemeValue('content-sm-padding-size');
  }

  @media (max-width: $menu-toggle-width) {
    top: 23px !important;
    z-index: 2;
  }
}

.voucherMessage {
  margin-top: 20px;
}

.wideButton {
  width: 100%;
  max-width: $content-respond-width;
}

.instantPayout {
  padding: 16px;
  div {
    font-family: getThemeValue('font');
  }
}
