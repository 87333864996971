@import '../../../assets/styles/variables.scss';

.greenTick {
  max-width: 150px;
  max-height: 150px;
}

.approvedHeader {
  font-weight: bold;
}

.reflectMessage {
  padding: 10px 30px;
  font-size: 16px;
}

.buttonsContainer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $screen-xs-max) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.offerSummary {
  display: flex;
  justify-content: center;
  padding: 0 50px 20px 50px;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .amountBlock {
    flex: 1;
    margin: 30px 50px 0 0;
    padding: 30px 0 40px 0;

    @media (max-width: $screen-xs-max) {
    margin-right: 0;
    }
  }
}
