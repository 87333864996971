@import "../../assets/styles/variables.scss";

.uploadButtonBlockInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fileUploadIcon {
  float: left;
  margin: 20px 0 10px 20px;
  height: 80px;
  width: 50px;
  font-size: 60px;
  text-align: center;

  @media (max-width: 500px) {
    margin: 30px 10px 10px 10px;
    font-size: 50px;
  }
}

.uploadButtonText {
  color: #637381;
  font-size: 16px;
  line-height: 24px;
  padding: 0 20px;

  @media (max-width: 500px) {
    padding: 0;
    line-height: 18px;
  }
}

.documentFileUploadEnabled, .documentFileUploadDisabled {
  display: flex;
  border: 2px dotted #cfd8dc;
  border-radius: 3px;
  background-color: #F8F9FA;
  margin: 8px 0 0px 0;
  min-height: 90px;
}
.documentFileUploadEnabled {
  cursor: pointer;
}

.flagshipDocumentFileUploadEnabled, .flagshipDocumentFileUploadDisabled{
  border: 1px dashed $Green-500;
  border-radius: 2px;
  background: $White;
  height: 56px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-align: left;
  
  &>div{
    width: 100%;
    display: flex;
  }

  * {
    color: $Green-500 !important;
    font-weight: bold !important;
    font-size: getThemeValue('flagship-upload-label');
    line-height: 19px;

    div {
      flex-grow: 1;
    }

    i {
      font-size: 20px;
      height: unset;
      margin: 0;
      width: 50px;
      flex-shrink: 0;
    }
  }
}

.hasDocument{
  border: none !important;
  background: getThemeValue('flagship-error-card-background') !important;
}

.flagshipUploadedFileName{
  color: $Brand-Black !important;
  font-weight: normal !important;
  font-family: getThemeValue('font');
  font-size: getThemeValue('flagship-error-card-sub-heading-font-size');
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
}

.documentFileUploadDisabled {
  cursor: not-allowed;
}

.uploadButtonBlockInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submittedFileUploadIcon {
  float: left;
  margin: 20px 0 10px 20px;
  height: 80px;
  width: 50px;
  font-size: 60px;
  text-align: center;

  @media (max-width: 500px) {
    margin: 30px 10px 10px 10px;
    font-size: 50px;
  }
}

