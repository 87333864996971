@import "../../assets/styles/variables.scss";

tbody {
  tr {
    td.flushImage {
      padding-left: 0px;
      width: 60px;
      height: 80px;
    }

    td.image {
      width: 100px;
    }

    td.checkbox {
      width: 80px;

      .checkboxButton {
        float: right;
      }

      @media(max-width: 767px) {
        width: 64px;
      }
    }

    td.delete {
      max-width: 70px;
    }

    td.amount {
      width: 200px;
      text-align: right;
      font-weight: 500;
    }

    td.quantity {
      width: 50px;
      text-align: right;
      font-weight: 500;
    }

    td.name {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 280px;

      @media(max-width: 600px) {
        left: 10px;
      }
    }
  }
}