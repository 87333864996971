@import "../../../../../../assets/styles/variables.scss";

.list {
  text-align: left;
  margin-bottom: 32px;
}

.title {
  font-size: 20px;
  margin-bottom: 32px;
}

.field {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
}

.fieldError {
  margin-top: 6px;
  padding-left: 40px;
  font-size: 12px;
  color: getThemeValue('color-danger');
}

.alert {
  text-align: left;
}