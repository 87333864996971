@import "../../assets/styles/variables.scss";

.slogan {
  font-family: $SharpGrotesk;
  text-align: center;
  color: $Brand-Black;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
}

.tagline {
  font-family: $SharpGrotesk;
  text-align: center;
  color: $Brand-Green;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
}

.accountWrapper {
  position: fixed;
  background: $Gray-200;

  @media(max-width: $screen-xs-max) {
    position: initial;
    background: white;
  }

  display: table;
  height: 100%;
  width: 100%;

  .accountTable {
    display: table-cell;
    vertical-align: middle;
  }

  .accountInner {
    background-color: white;
    border-radius: 6px;
    padding: 0;
    width: 530px;
    margin: 0px auto;
    position: relative;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);

    @media(max-width: $screen-xs-max) {
      box-shadow: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    .formBlock {
      padding: 38px 18px 38px 38px;

      .formAlert {
        // This is horrible, but we have an uneven padding because of how our form
        // components are written (float left, padding 20) - when the forms are fixed
        // then we should remove this
        margin-right: 20px;
      }

      @media(max-width: $screen-md-min) {
        padding: 38px 26px;

        .formAlert {
          margin-right: 0;
        }
      }

    }

    .logo {
      max-width: 130px;
      margin: 0 auto;
      padding-top: 30px;
      display: block;
    }
  }
}

.loginButton {
  float: right;
  margin-right: 20px;

  @media(max-width: $menu-toggle-width) {
    margin-right: 0;
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
  .bg-responsive {
    background: none;
  }
}

.flexButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 15px;

  @media(max-width: $menu-toggle-width) {
    margin-right: 0px;
  }

  @media(max-width: 435px) {
    flex-direction: column-reverse;
  }
}
.cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 188px;
  background: #CED4DE;
  color: #222222;
  border-radius: 8px; 
  font-weight: 700;
  
  &:hover {
    opacity: 0.8;
    color: getThemeValue('button-tertiary-font-color');
    text-decoration: none;
  }

  @media(max-width: 435px) {
    margin-top: 4px;
    margin-right: 4px;
    height: 40px;
  }
}

.shopifyLoginButton {
  min-width: 188px;
  background: #019472;
  border-radius: 8px;
  color: #FFF;
}

.unsubscribeBlock {
  padding: 24px;
  text-align: center;
  min-height: 90px;

  &.terms-block {
    p {
      font-weight: light;
      font-size: 16px;
    }
  }

  a {
    cursor: pointer;
  }

  h2 {
    text-align: center;
  }
}

.formLoader {
  background: transparentize(black, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
