@import "../../assets/styles/variables.scss";

.filterContainer {
  position: relative;
  display: inline-block;
  top: 14px;
  margin-left: 16px;
  flex: 1;

  @media(max-width: $menu-toggle-width) {
    top: -10px;
    margin-left: 0;
    width: 100%;
  }
}
.dateHeader {
  padding: 0 36px;

  .dateFilter {
    padding: 0;
  }
  header {
    margin-bottom: 32px;

    h1 {
      float: left;
    }

    .title {
      line-height: 44px;
      display: inline-block;
      margin-bottom: 16px;
    }

    .filter {
      float: left;
      position: relative;
      margin-top: 23px;
      margin-left: 16px;
    }
  }

  @media(max-width: $menu-toggle-width) {
    padding: 0 16px;

    header {
      border-bottom: none;

      .filter {
        position: initial;
        top: 0;
        left: 0;
      }

      .title {
        margin: 8px 0;
      }
    }

    .filter {
      &.dateFilter {
        &.dateHeader {
          margin: 12px 0 !important;
        }
      }
    }
  }
}
