@import "src/assets/styles/variables.scss";

.keyBlock {
  padding: 12px;
  margin-top: 3px;
  border: 1px solid #cfd8dc;
  margin-right: 32px;
  background-color: white;
  width: 350px;
  display: inline-block;
  position: relative;
  @media (max-width: $menu-toggle-width) {
    width: 100%
  }
}
