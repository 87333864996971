.hidden {
  display: none;
}

.cursorPointer {
  cursor: pointer;
}

.visibleOnHover {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}