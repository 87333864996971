@import "../../../../assets/styles/variables.scss";

.container {
  display: flex;
  font-size: 2rem;
  justify-content: space-evenly;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
  }
}

.progress {
  padding: 30px;
  align-self: center;
  overflow: hidden;
  width: 400px;
  position: relative;

  h4 {
    font-size: 46px;
    color: $yoco-blue;
    margin: 32px 10px 10px;
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }

  @media (max-width: $screen-xs-max) {
    min-width: 350px;
    h4 {
      font-size: 40px;
      color: $yoco-blue;
      margin: 10px;
    }

    p {
      font-size: 16px;
    }
  }

  @media (max-width: $screen-xxxs-max) {
    width: 300px;
    padding-bottom: 0;
    h4 {
      font-size: 30px;
      color: $yoco-blue;
      margin: 10px;
    }

    p {
      font-size: 12px;
    }
  }

  :global {
    .react-sweet-progress-circle-outer {
      width: 100%;
      height: 187px;
      .capital {
        bottom: 40%;
      }
      @media (max-width: $screen-xs-max) {
        height: 180px;
        max-width: 373px;
        .capital {
          bottom: 40%;
        }
      }
      @media (max-width: $screen-xxxs-max) {
        .capital {
          bottom: 50%;
        }
      }
    }
  }
}

.overview {
  align-self: center;
  padding: 30px 10px;
  @media (max-width: $screen-xs-max) {
    padding: 0;
  }

  .overviewDetailsBlock {
    @media (max-width: $phone-width) {
      .downloadButtonCover {
        display: none;
      }
    }
    @media (max-width: $screen-xs-max) {
      display: flex;
      padding: 0;
      flex-direction: column;
    }
    .amountBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      .indicatorBlock {
        .indicator {
          height: 24px;
          width: 6px;
          border-radius: 6.5px;
          background-color: #03a9f4;
          &.grey {
            background-color: #cfd8dc;
          }
        }
      }

      .amountInfoBlock {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 23px;
        color: #637381;
        letter-spacing: 1px;
        line-height: 40px;
        font-size: 1.5em;

        p {
          margin: 0;

          &.amountType {
            text-transform: uppercase;
            font-size: 0.5em;
            margin-left: 12px;
          }
        }
      }
    }
  }
}

.downloadButtonCover {
  .downloadButtonContainer {
    display: flex;
    padding-top: 12px;
  }

  .downloadButton {
    height: 48px;
    min-width: 165px;
    background-color: $yoco-blue;
    border-radius: 3px;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 14px;
  }

  .viewContractLink {
    display: flex;
  }

  .link {
    margin-top: 13px;
    color: #03a9f4;
    font-size: 13px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
  }
}

.indicatorTextContainer {
  color: #212b36;
  margin-top: 30px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    letter-spacing: 0px;
  }

  .indicatorFractionText {
    font-size: 2.2rem;
    line-height: 45px;
  }

  .indicatorCompletedText {
    color: #212b36;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 22px;
  }
}

.acceptOfferPageSummary {
  .blocksContainer {
    display: flex;
    justify-content: center;
    padding: 0 50px 20px 50px;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      padding: 0 20px 0 20px;
    }

    .amountBlock {
      flex: 1;
      margin: 30px 0 0 0;
      padding: 30px 0 40px 0;

      .percentage {
        color: $Green-500;
      }

      p {
        font-size: larger;
        color: $Gray-700;
        font-weight: bold;
      }

      .amount {
        white-space: pre;
      }
    }

    .activeAmountBlock {
      flex: 1;
      padding: 30px;
      background-color: $Gray-200;
    }

    .downloadButtonCover {
      display: none;
      @media (max-width: $phone-width) {
        display: block;
        padding-top: 12px;
      }
    }
  }

  .blocksActiveCapitalContainer {
    display: flex;
    margin: -20px;
    background-color: $Gray-200;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      padding-bottom: 0;
    }

    .activeAmountBlock {
      flex: 1;
      padding: 30px;
      align-self: center;

      h1 {
        font-size: 18px;
      }
    }

    .detailsBlock {
      flex: 2;
      display: flex;
      padding: 30px 10px;
      flex-direction: column;

      .detail {
        flex: 1;
        display: flex;
        padding: 15px 10px 15px 50px;

        p {
          padding-right: 30px;
          width: 290px;
          text-align: left;
          color: $Gray-700;
        }

        b {
          font-weight: bold;
        }
        @media (max-width: 1160px) {
          b {
            width: 100%;
          }
          p {
            width: 100%;
          }
        }
        @media (max-width: $screen-xs-max) {
          flex-direction: column;
          padding: 10px 10px;
          b {
            text-align: left;
          }
        }
      }
    }
  }
}
