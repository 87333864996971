@import '../../../assets/styles/variables.scss';

.container {
  margin: 10px 0;

  .header {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    display: -ms-flexbox;
    -ms-flex-direction: row;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    align-items: center;

    @media (max-width: $menu-toggle-width) {
      padding-bottom: 8px;
      overflow-x: auto;
    }

    .title {
      display: inline-block;
      font-size: getThemeValue('subtitle-font-size');
      font-family: getThemeValue('subtitle-font');
      color: getThemeValue('subtitle-font-color');
      font-weight: getThemeValue('subtitle-font-weight');
      flex: 1;
      -ms-flex: 1;
      -webkit-flex: 1;
      margin: auto 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .actions {
      @media (max-width: $menu-toggle-width) {
        white-space: nowrap;
        float: right;

        .flatButton {
          i {
            display: none;
          }
        }
      }
    }
  }
}

.closeButton {
  all: unset;
  color: getThemeValue('button-dismiss-font-color');
  cursor: pointer;
  margin-left: -10px;
  padding-right: 8px;

  @media (max-width: $menu-toggle-width) {
    max-width: 60px;
    padding-right: 2px;
    margin-left: 0;
  }

  i {
    padding: 10px;

    &:hover {
      background-color: $Gray-200;
      opacity: 0.6;
      border-radius: 24px;
    }

    &:before {
      vertical-align: middle;
    }
  }
}
