@import "../../assets/styles/variables.scss";

.tile {
  height: 120px;
  max-width: 124px;
  border-radius: 4px !important;
  box-shadow: 1px 2px 10px 0px rgba(148, 155, 165, 0.5);
  font-family: getThemeValue('font');
  text-overflow: ellipsis;
  border-left: none !important;

  .tileImage {
    height: 76px;
    border-radius: 4px 4px 0 0;
    margin: 0;

    &.withPadding {
      padding-top: 6px;
    }

    .fullImage {
      width: 124px;
      border-radius: 4px 4px 0 0;
      border-bottom: none;

      .text {
        color: #FFF;
        font-weight: bold;
        font-size: 22px;
        line-height: 70px;
        width: 50px;
        margin: auto;
      }
    }

    .circleImage {
      margin: auto;
      height: 60px;
      width: 60px;
      border-radius: 30px;
      background: white !important;
    }

    .tileIcon {
      overflow: hidden;
      background-size: cover !important;
      height: 100%;
      width: 124px;
      line-height: 80px;
      margin: 0 auto;
      border-radius: 4px 4px 0 0;

      .tileShape {
        background-size: cover !important;
        height: 100%;
        line-height: 80px;
        border-radius: 4px 4px 0 0;

        &.circle {
          height: 60px;
          width: 60px;
          margin: 8px auto;
          border-radius: 30px;
        }

        &.rounded {
          height: 60px;
          width: 60px;
          margin: 8px auto;
          align-self: center;
          justify-content: center;
          border-radius: 8px;
        }

        &.diamond {
          height: 50px;
          width: 50px;
          transform: rotate(45deg);
          margin: 12px auto 0;
          overflow: hidden;
          line-height: 30px !important;
        }

        .inner {
          position: relative;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          height: 100%;

          &.itemText {
            line-height: 40px;
            font-size: 26px;
            color: white;
            font-weight: 500;
            text-align: center;
            padding: 10px;
          }

          &.rotate {
            transform: rotate(315deg);
            line-height: 50px;
          }
        }
      }
    }
  }

  .tileText {
    height: 34px;
    width: 124px;
    line-height: 30px;
    color: $font-table-dark;
    padding: 4px;
    font-size: 12px;
    text-align: center;
    background: #FFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;
  }

  .tileFooter {
    background: #FFF;
    height: 5px;

    .halfCircle {
      margin: auto;
      height: 10px;
      width: 16px;
      border-radius: 20px 20px 0 0;
    }
  }

  .placeholder {
    height: 110px;
    color: #FFF;
    background: $yoco-blue;
    padding: 8px;
    font-size: 14px;
    text-align: center;
    box-shadow: none;
    border-radius: 8px !important;
  }
}

.tileAppearance {
  display: flex;
  width: 100%;

  @media(max-width: $phone-width){
    display: inline-block;
  }

  .tileDisplay {
    margin: auto;
    display: table;
    border: 0;
    padding: 32px 26px;
    background: $subtle-off-white;

    @media(max-width: $phone-width){
      margin: 0 auto 20px;
    }
  }
}

.editItemIcon {
  text-align: center;
  color: $gray-text;
  margin: auto;
  position: relative;

  .sectionTitle {
    text-align: left;
    color: $gray-text;
    margin: 12px 0;
  }

  .tileAppearance {
    background: $subtle-off-white;
    padding: 8px 12px;
    min-height: 85px;

    .sectionTitle {
      text-align: center;
      color: $gray-text;
      margin: 4px 0;
    }
  }

  .iconInfo {
    font-size: 11px;
    text-align: 'center';
    margin-top: 8px;
  }

  .designOptions {
    display: inline-flex;
    margin: auto;
    font-size: 13px;

    @media(max-width: 320px) {
      display: flex;
      display: -webkit-box;
    }

    span {
      font-family: getThemeValue('font');
      text-transform: capitalize;
    }

    .option{
      margin: 5px 20px;
      height: 70px;
      width: 70px;
      cursor: pointer;
      border-radius: 4px;
      background: white;
      border: 1px solid $Gray-200;
      position: relative;

      @media(max-width: $phone-width) {
        margin: 5px;
      }

      i {
        font-size: 26px;
        line-height: 70px;

      }

      &.active {
        position: relative;
        border: 2px solid getThemeValue('form-control-active-color');

        i:before {
          color: getThemeValue('font-color');
        }
      }
    }
  }

  .btn {
    width: 100%;
  }

  .actions {
    .btn {
      width: auto;
    }
  }

  .btnRemoveImage {
    margin-bottom: 5px;
  }

  .colorPicker {
    width: 176px;
    margin: 0px auto 5px;

    .choice {
      width: 32px;
      height: 32px;
      padding: 4px;
      margin-right: 3px;
      display: inline-block;
      cursor: pointer;
      border-radius: 2px;

      &.active {
        position: relative;

        &:before {
          position: absolute;
          bottom: 0px;
          right: 4px;
          color: white;
          font-style: normal;
          font-weight: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -mos-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .tileColorPicker {
    margin: 0px auto 5px;

    @media(max-width: $screen-xxxs-max) {
      display: block;
    }

    .choice {
      width: 40px;
      height: 40px;
      padding: 6px;
      margin-right: 5px;
      display: inline-block;
      cursor: pointer;
      border-radius: 2px;

      @media (max-width: $screen-xs-max) {
        width: 30px;
        height: 30px;
      }

      &.active {
        position: relative;

        &:before {
          position: absolute;
          bottom: 0px;
          right: 8px;
          color: white;
          font-style: normal;
          font-weight: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -mos-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .section {
    margin: 10px 0px;
  }

  label {
    display: inline-block;
  }

  .nameInput {
    border: 1px solid $darker-border;
    border-radius: 3px;
    height: auto;
    width: 50%;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .popoverTitle {
    margin: 0px -14px;
    border-radius: 0;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: $subtle-off-white;
    border-bottom: 1px solid $gray-background;
    margin: 0 -14px;
    font-weight: 500;
  }

  .actions {
    border-bottom: none;
    border-top: 1px solid $gray-background;
    margin-bottom: -8px;
    border-radius: 0 0 5px 5px;
    margin: 0 -28px -8px -28px;
  }
}

.loaderStyle {
  width: 68px;
}
