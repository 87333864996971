@import 'assets/styles/variables';

.popupHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flagshipPopupHeaderContainer{
  display: none;
}


.backArrowContainer {
  cursor: pointer;
}

.headerTitleContainer {
  flex: 1;

  &.headerTitle {
    font-size: 28px;
    font-weight: 600;
  }
}


.closeButtonContainer {
  cursor: pointer;
  height: 24px;
}
