@import '../../assets/styles/variables.scss';

.pageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  overflow: scroll;
  justify-content: center;
  background-color: rgba(207, 216, 220, 0.1);
}

.centeredContainerPage {
  background: getThemeValue('onboarding-background-color');
}

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 123px 0 123px;
  padding-bottom: 65px;

  @media (max-width: 1160px) {
    margin: 0 90px 0 90px;
  }

  @media (max-width: 1095px) {
    margin: 0 60px 0 60px;
  }

  @media (max-width: 1040px) {
    margin: 0 30px 0 30px;
  }

  @media (max-width: 785px) {
    margin: 0 12px;
  }

  &.setupCompleted {
    justify-items: center;
    align-items: center;
    color: #222222;
    margin-top: 50px;

    .completedTitle {
      display: flex;
      flex: auto;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    .completedHeading {
      width: 100%;
      font-size: 36px;
      font-weight: 300;
      line-height: 42px;
      text-align: center;

      @media (max-width: 485px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    .completedImage {
      max-width: 360px;
      max-height: 360px;
      object-fit: contain;
      margin: 60px 0 60px 0;

      @media (max-width: 486px) {
        width: 90%;
        max-height: 300px;
        margin: 20px 0 20px 0;
      }
    }

    .completedDescription {
      font-size: 24px;
      font-weight: 300;
      line-height: 42px;
      width: 100%;
      text-align: center;
      padding: 0 5px 0 5px;

      @media (max-width: 485px) {
        font-size: 18px;
        line-height: 36px;
      }
    }

    .goToDashboardButtonContainer {
      display: flex;
      flex: 1;
      margin: 33px 0 33px 0;
      padding-bottom: 60px;

      .goToDashboardButton {
        background-color: #03a9f4;
        border-radius: 3px;
        width: 240px;
        height: 48px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
        text-decoration: none;
      }
    }
  }
}

.onBoardingPageTaskErrorBanner {
  text-align: center;
  background-color: #f2dede;
  padding: 10px;
}

.onBoardingPageTaskErrorMessage {
  border-color: #ebccd1;
  color: #a94442;
  font-size: medium;
  margin: 0px;
}

.onboardingResetStyles {
  h1 {
    line-height: 3.5rem;
  }
  input[type='radio'] {
    border: 1px solid #019472;
    border-radius: 100%;
    outline: none;
    appearance: auto;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    margin-top: 0;
    margin-right: 16px;
  }
  label {
    margin-bottom: 0;
  }
  height: 100%;
}

.onBoardingPageContainer {
  @media (max-width: $screen-xs-max) {
    background-color: white;
    height: 100%;
  }
}

.onboardingContainer {
  padding-top: 15px;
  z-index: 99;
}

.onboardingDownloadContent {
  z-index: 99;
  margin: auto;

  h2 {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

.backgroundImage {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right bottom;
  background-size: contain;
  overflow: overlay;
  background-image: url('onboarding-background.svg');

  @media (max-width: $screen-xs-max) {
    background-image: none;
  }
}

.onboardingBackground {
  background-color: #eaf0ee;
  height: 100%;

  @media (max-width: $screen-xs-max) {
    background-color: white;
  }
}
