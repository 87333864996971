@font-face {
  font-family: "yoco";
  src: url("./yoco.eot");
  src: url("./yoco.eot?#iefix") format("embedded-opentype"),
  url("./yoco.woff") format("woff"),
  url("./yoco.ttf") format("truetype"),
  url("./yoco.svg#yoco") format("svg");
  font-weight: normal;
  font-style: normal;
}

:global {
  [data-icon]:before {
    font-family: "yoco" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    font-family: "yoco" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-edit:before {
    content: "J";
  }
  .icon-lock:before {
    content: "U";
  }
  .icon-magnifier:before {
    content: "I";
  }
  .icon-tag:before {
    content: "G";
  }
  .icon-arrange:before {
    content: "P";
  }
  .icon-call:before {
    content: "v";
  }
  .icon-mail:before {
    content: "8";
  }
  .icon-profile:before {
    content: "s";
  }
  .icon-staff:before {
    content: "t";
  }
  .icon-basket-2:before {
    content: "r";
  }
  .icon-cart:before {
    content: "b";
  }
  .icon-receipt:before {
    content: "l";
  }
  .icon-battery-charging:before {
    content: "O";
  }
  .icon-battery-full:before {
    content: "m";
  }
  .icon-battery-high:before {
    content: "n";
  }
  .icon-battery-low:before {
    content: "6";
  }
  .icon-bluetooth:before {
    content: "X";
  }
  .icon-pin-character:before {
    content: "V";
  }
  .icon-block:before {
    content: "Q";
  }
  .icon-note-paper:before {
    content: "F";
  }
  .icon-add-square:before {
    content: "S";
  }
  .icon-minus-square:before {
    content: "T";
  }
  .icon-refresh:before {
    content: "q";
  }
  .icon-check:before {
    content: "c";
  }
  .icon-check-box:before {
    content: "N";
  }
  .icon-check-circle:before {
    content: "e";
  }
  .icon-check-list:before {
    content: "R";
  }
  .icon-cross:before {
    content: "d";
  }
  .icon-calendar:before {
    content: "w";
  }
  .icon-clock:before {
    content: "x";
  }
  .icon-question-mark:before {
    content: "y";
  }
  .icon-information:before {
    content: "z";
  }
  .icon-alert:before {
    content: "A";
  }
  .icon-alert-round:before {
    content: "B";
  }
  .icon-balance:before {
    content: "C";
  }
  .icon-coins:before {
    content: "D";
  }
  .icon-coins-pile:before {
    content: "f";
  }
  .icon-credit-card:before {
    content: "h";
  }
  .icon-visa-card:before {
    content: "j";
  }
  .icon-shop:before {
    content: "k";
  }
  .icon-calculator:before {
    content: "M";
  }
  .icon-cash-register:before {
    content: "g";
  }
  .icon-box:before {
    content: "K";
  }
  .icon-hand-coin:before {
    content: "W";
  }
  .icon-dollar-bag:before {
    content: "L";
  }
  .icon-master-card:before {
    content: "i";
  }
  .icon-arrow-right:before {
    content: "4";
  }
  .icon-arrow-left:before {
    content: "5";
  }
  .icon-rands:before {
    content: "2";
  }
  .icon-battery-medium:before {
    content: "a";
  }
  .icon-check-1:before {
    content: "o";
  }
  .icon-sync-2:before {
    content: "p";
  }
  .icon-check-2:before {
    content: "u";
  }
  .icon-delete-garbage-streamline:before {
    content: "E";
  }
  .icon-streamline-sync:before {
    content: "H";
  }
  .icon-customamount:before {
    content: "Z";
  }
  .icon-removecard:before {
    content: "7";
  }
  .icon-manageitems:before {
    content: "9";
  }
  .icon-arrow-68:before {
    content: "#";
  }
  .icon-log-out-1:before {
    content: "\"";
  }
  .icon-plane-paper-2:before {
    content: "%";
  }
  .icon-mail-inbox:before {
    content: "$";
  }
  .icon-mail-time:before {
    content: "'";
  }
  .icon-mail-block:before {
    content: "(";
  }
  .icon-mail-attention:before {
    content: ")";
  }
  .icon-items-1:before {
    content: "*";
  }
  .icon-miura:before {
    content: "Y";
  }
  .icon-bag-o-rands:before {
    content: "0";
  }
  .icon-globe-2:before {
    content: "!";
  }
  .icon-bookmark-1:before {
    content: "&";
  }
  .icon-basket-1:before {
    content: "+";
  }
  .icon-location:before {
    content: ",";
  }
  .icon-verifone:before {
    content: "3";
  }
  .icon-m10-6:before {
    content: "-";
  }
  .icon-delete-3:before {
    content: ".";
  }
  .icon-minus-3:before {
    content: "/";
  }
  .icon-filter-1:before {
    content: ":";
  }
  .icon-one:before {
    content: ";";
  }
  .icon-icomoon-1:before {
    content: "<";
  }
  .icon-linkedin:before {
    content: "=";
  }
  .icon-twtr:before {
    content: ">";
  }
  .icon-lnkn:before {
    content: "?";
  }
  .icon-fb:before {
    content: "@";
  }
  .icon-facebook:before {
    content: "[";
  }
  .icon-two:before {
    content: "]";
  }
  .icon-three:before {
    content: "^";
  }
  .icon-four:before {
    content: "_";
  }
  .icon-logo:before {
    content: "`";
  }
  .icon-cardreader:before {
    content: "{";
  }
  .icon-arrow-65:before {
    content: "|";
  }
  .icon-file-1:before {
    content: "}";
  }
  .icon-star-1:before {
    content: "~";
  }
  .icon-arrow-circle-30:before {
    content: "\\";
  }
  .icon-add-1:before {
    content: "\e000";
  }
  .icon-add-2:before {
    content: "\e001";
  }
  .icon-hand-like-2:before {
    content: "\e002";
  }
  .icon-hand-unlike-2:before {
    content: "\e003";
  }
  .icon-arrow-67:before {
    content: "\e004";
  }
  .icon-bubble-2:before {
    content: "\e006";
  }
  .icon-clip-1:before {
    content: "\e007";
  }
  .icon-loop-1:before {
    content: "\e005";
  }
  .icon-shopping-1:before {
    content: "\e008";
  }
  .icon-television:before {
    content: "\e009";
  }
  .icon-delivery:before {
    content: "\e00a";
  }
  .icon-box-2:before {
    content: "\e00b";
  }
  .icon-mouse-2:before {
    content: "\e00c";
  }
  .icon-hand-eco:before {
    content: "\e00d";
  }
  .icon-wrench:before {
    content: "\e00e";
  }
  .icon-satellite:before {
    content: "1";
  }
  .icon-bug-1:before {
    content: "\e00f";
  }
  .icon-temple:before {
    content: "\e010";
  }
  .icon-server-2:before {
    content: "\e011";
  }
  .icon-connection-3:before {
    content: "\e012";
  }
  .icon-android:before {
    content: "\e013";
  }
  .icon-apple:before {
    content: "\e014";
  }
  .icon-iphone-streamline:before {
    content: "\e015";
  }
  .icon-light-house:before {
    content: "\e016";
  }
  .icon-business-chart-1:before {
    content: "\e017";
  }
  .icon-arrow-down:before {
    content: "\e018";
  }
  .icon-arrow-up:before {
    content: "\e019";
  }
  .icon-pencil-2:before {
    content: "\e01a";
  }
  .icon-phone-4:before {
    content: "\e01b";
  }
  .icon-mail-box-1:before {
    content: "\e01c";
  }
  .icon-link-2:before {
    content: "\e01d";
  }
  .icon-list-1:before {
    content: "\e01e";
  }
  .icon-align-justify:before {
    content: "\e01f";
  }
  .icon-map-pin-streamline:before {
    content: "\e020";
  }
  .icon-arrow-57:before {
    content: "\e021";
  }
  .icon-arrow-58:before {
    content: "\e022";
  }
}
