.filtersContainer {
  margin-bottom: 16px;

  .actions{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
