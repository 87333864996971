@import '../../assets/styles/variables';

.textSmall {
  font-size: 13px;
}

p.textSmall {
  margin-bottom: 13px;
}

.textBold {
  font-weight: bold;
}

.textLight {
  font-weight: 300;
}

.textUppercase {
  text-transform: uppercase;
}

.h1 {
  font-family: getThemeValue('title-font');
  font-size: getThemeValue('title-font-size');
  font-weight: getThemeValue('title-font-weight');
  color: getThemeValue('title-font-color');
}

.h2 {
  font-family: getThemeValue('subtitle-font');
  font-size: getThemeValue('subtitle-font-size');
  font-weight: getThemeValue('subtitle-font-weight');
  color: getThemeValue('subtitle-font-color');
}

.h3 {
  font-family: getThemeValue('sub-heading-font');
  font-size: getThemeValue('sub-heading-font-size');
  font-weight: getThemeValue('sub-heading-font-weight');
  color: getThemeValue('sub-heading-font-color');
}

.textRight {
  text-align: right;
}

.lineThrough {
  text-decoration: line-through;
}

.red {
  color: $Red-500;
}

.subText {
  font-size: 13px;
  font-weight: 500;
  color: $Gray-700;
}

.label {
  font-size: getThemeValue('form-label-font-size');
  text-transform: getThemeValue('form-label-text-transform');
  font-weight: getThemeValue('form-label-font-weight');
  color: getThemeValue('font-color');
  line-height: getThemeValue('form-label-line-height');
  margin: 0
}

