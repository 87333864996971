@import "../../assets/styles/variables.scss";

.paddedBlock {
  padding: 0 getThemeValue('content-padding-size') getThemeValue('content-padding-size');

  @media (max-width: $menu-toggle-width) {
    padding: 0px getThemeValue('content-md-padding-size') getThemeValue('content-md-padding-size');
  }

  @media (max-width: $phone-width) {
    padding: 0px getThemeValue('content-sm-padding-size') getThemeValue('content-sm-padding-size');
  }
}

.outerBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: getThemeValue('content-overflow-background-color');
  overflow-y: auto;

  .maxWidthBlock {
    padding: 0 getThemeValue('content-padding-size') getThemeValue('content-padding-size');
    max-width: 1440px;
    background: getThemeValue('content-background-color');
    min-height: 100%;

    @media (max-width: $menu-toggle-width) {
      padding: 0px getThemeValue('content-md-padding-size') getThemeValue('content-md-padding-size');
    }

    @media (max-width: $phone-width) {
      padding: 0px getThemeValue('content-sm-padding-size') getThemeValue('content-sm-padding-size');
    }
  }

  .splitView {
    visibility: visible;
    flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: visibility 200ms, right 200ms;
    box-shadow: 0 0 4px 0 $Gray-300;
    z-index: 1005;

    @media (max-width: $menu-toggle-width) {
      left: 0;
      width: auto;
      overflow-y: hidden;

      &.gone {
        display: none;
      }
    }

    &.gone {
      right: -650px;
      visibility: hidden;
    }

    .content {
      background-color: $Gray-100;
      overflow-y: auto;
      height: 100%;
      padding: 0 25px 76px;
    }
  }
}

.backdrop {
  position: fixed;
  inset: 0;
  z-index: 0;
}