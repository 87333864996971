@import '../../../../assets/styles/variables.scss';

.row {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  grid-gap: 5px;

  p {
    color: $Gray-700;
    text-align: left;
  }

  b {
    font-weight: bold;
    text-align: left;
  }

  @media (max-width: 1160px) {
    b {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }

  @media (max-width: $screen-xs-max) {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
}
