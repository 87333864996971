@import "../../assets/styles/variables.scss";

.abbreviationPopover {
  min-width: 200px;
}

.itemIcon {
  border: 0;
  height: 150px;
  width: 150px;
  margin: 20px auto 40px;
  position: relative;
  text-align: center;
  font-size: 18px;

  @media(max-width: $screen-xs-max) {
    margin: 0px auto 40px;
  }

  .itemQuantity {
    z-index: 1;
    position: absolute;
    width: 18px;
    line-height: 18px;
    font-size: 60%;
    font-weight: 700;
    border-radius: 2px;
    background: $gray-background;
    bottom: 2px;
    right: 2px;
  }

  .inner {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;

    &.circle {
      border-radius: 74px;
    }

    &.diamond {
      transform: rotate(45deg);
      overflow: hidden;
    }

    &.rounded {
      border-radius: 12px;
    }
  }

  .itemImage {
    img {
      width: 100%;
    }
  }

  .isTile {
    line-height: inherit;
    border-radius: 0;
  }

  .itemBackground {
    overflow: hidden;
    background-size: cover !important;

    &.isTile {
      height: 100%;
      width: 124px;
      line-height: 80px;
      margin: 8px auto 0;
      border-radius: 8px 8px 0 0;

      &.diamond {
        top: 12px;
        margin: 0px auto;
        border-radius: 0 !important;
      }
    }

    &.circle {
      background-size: cover !important;
      height: 60px;
      width: 60px;
      border-radius: 30px;
      line-height: 80px;
    }

    &.rounded {
      background-size: cover !important;
      height: 60px;
      width: 60px;
      line-height: 80px;
      align-self: center;
      justify-content: center;
      border-radius: 8px;
    }

    &.diamond {
      background-size: cover !important;
      height: 50px;
      width: 50px;
      line-height: 80px;
      margin: 12px auto;
      transform: rotate(45deg);
    }
  }

  .itemText {
    line-height: 112px;
    font-size: 32px;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 15px;
  }

  a {
    cursor: pointer;
  }

  a#edit-item {
    position: absolute;
    bottom: 0;
    margin-bottom: -30px;
    margin-top: 8px;
    left: 0px;
    right: 0px;
    text-align: center;
  }

  .editItem {
    left: 0px;
    right: 0px;
    text-align: center;
    line-height: 3;
  }

  &.smaller {
    width: 60px;
    height: 60px;
    margin: 0;
    border-radius: 3px;

    .itemText {
      line-height: 60px;
      font-size: 24px;
      padding: 0;

      &.rotate {
        transform: rotate(315deg);
        line-height: 50px;
      }

      i {
        font-size: 30px;
        line-height: 70px;
      }
    }
  }
}

.editItemIcon {
  text-align: center;
  color: $gray-text;
  margin: auto;
  position: relative;

  .sectionTitle {
    text-align: left;
    color: $gray-text;
    margin: 12px 0;
  }

  .tileAppearance {
    background: $subtle-off-white;
    padding: 8px 12px;
    min-height: 85px;

    .sectionTitle {
      text-align: center;
      color: $gray-text;
      margin: 4px 0;
    }
  }

  .designOptions {
    display: inline-flex;
    margin: auto;

    @media(max-width: 320px) {
      display: flex;
    }

    span {
      font-family: getThemeValue('font');
      text-transform: capitalize;
    }

    .option{
      margin: 5px 20px;
      height: 50px;
      width: 50px;
      cursor: pointer;
      border-radius: 8px;
      background: $subtle-off-white;
      position: relative;

      i {
        font-size: 20px;
        line-height: 50px;
      }

      &.active {
        position: relative;
        border: 3px solid $green;
        background: #FFF;
      }
    }
  }

  .btn {
    width: 100%;
  }

  .actions {
    .btn {
      width: auto;
    }
  }

  .btnRemoveImage {
    margin-bottom: 5px;
  }

  .colorPicker {
    width: 176px;
    margin: 0px auto 5px;

    .choice {
      width: 32px;
      height: 32px;
      padding: 4px;
      margin-right: 3px;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;

      &.active {
        position: relative;

        &:before {
          position: absolute;
          bottom: 0px;
          right: 4px;
          content: "e";
          color: white;
          font-style: normal;
          font-weight: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -mos-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .tileColorPicker {
    width: 320px;
    margin: 0px auto 5px;

    @media(max-width: $screen-xxxs-max) {
      width: 200px;
      display: block;
    }

    .choice {
      width: 40px;
      height: 40px;
      padding: 6px;
      margin-right: 5px;
      display: inline-block;
      cursor: pointer;
      border-radius: 4px;

      &.active {
        position: relative;

        &:before {
          position: absolute;
          bottom: 0px;
          right: 8px;
          content: "e";
          color: white;
          font-style: normal;
          font-weight: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -mos-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .section {
    margin: 10px 0px;
  }

  label {
    display: inline-block;
  }

  .nameInput {
    border: 1px solid $darker-border;
    border-radius: 3px;
    height: auto;
    width: 50%;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .popoverTitle {
    margin: 0px -14px;
    border-radius: 0;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    margin: 0 -14px;
    font-weight: 500;
  }

  .actions {
    border-bottom: none;
    border-top: 1px solid #ebebeb;
    margin-bottom: -8px;
    border-radius: 0 0 5px 5px;
    margin: 0 -28px -8px -28px;
  }
}
