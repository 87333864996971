@import "../../assets/styles/variables.scss";

.root {
  display: flex;
  flex-direction: row;
}

.preview {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: $Padding-Default;

  img {
    width: 100%;
    height: 100%;
  }
}

.previewSpinner {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
}

.uploadDescription {
  margin-bottom: $Padding-Tight;
}

.uploadActions {
  display: flex;
}

.uploadButtonContainer {
  position: relative;
  margin-right: $Padding-Tighter;
  overflow: hidden;

  button {
    margin: 0;
  }
  input[type="file"] {
    padding: 40px 0 0 0;
  }
}

.hideDefaultLoader {
  display: none;
}