@import '../../assets/styles/variables';

.popover {
  display: block;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);

  top: 100%;

  background: white;
  padding: getThemeValue('content-md-padding-size');
  box-shadow: getThemeValue('card-box-shadow');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('container-border-radius');

  z-index: 10000;
}