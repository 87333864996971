@import "../../assets/styles/variables.scss";

.tabs {
  .tabsNavigation {
    @if isNewTheme() {
      margin-bottom: 12px;
    } @else {
      padding: 0 25px;
      border-bottom: 1px solid $yoco-blue;
    }

    &.mobile {
      display: none;
    }

    &.noBorder {
      padding: 0 25px;
      border: none;
    }

    .tabsMenu {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;

      .tabsMenuItem {
        min-width: 150px;
        text-align: center;
        flex: 1;

        &.isActive {
          position: relative;
          border-style: solid;
          border-color: getThemeValue('tab-active-border-color');
          border-width: getThemeValue('tab-active-border-width');
          background-color: getThemeValue('tab-active-background-color');
          @if not isNewTheme() {
            // Need to overlap the container div to hide the border in the tab
            margin-bottom: -1px;
          }
        }

        &.isActiveNoBorder {
          position: relative;
          top: 1px;
          border: none;

          .activeArrow {
            width: 0;
            height: 0;
            margin: 16px;
            margin-top: 24px;
            cursor: pointer;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 15px solid $gray-background;
          }
        }

        a {
          color: #3e3e3e;
          display: block;
          padding: 0 40px;
          line-height: 50px;
          height: 50px;
          border-bottom: 0;
          font-family: getThemeValue('font');
          font-size: getThemeValue('tab-font-size');
          font-weight: getThemeValue('tab-font-weight');
          text-transform: getThemeValue('tab-text-transform');
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -o-user-select: none;
          user-select: none;

          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .tabPanel {
    margin: 30px 48px;
    @if isNewTheme() {
    } @else {
      background-color: #fff;
    }
  }

  @media(max-width: $menu-toggle-width) {
    .tabsNavigation {
      display: none;

      &.mobile {
        display: block;
        position: relative;
        line-height: 44px;
        text-align: center;

        background: getThemeValue('tabs-dropdown-background');
        font-size: getThemeValue('tabs-dropdown-font-size');
        text-transform: getThemeValue('tabs-dropdown-text-transform');
        font-weight: getThemeValue('tabs-dropdown-font-weight');
        border-style: solid;
        border-color: getThemeValue('tab-active-border-color');
        border-width: getThemeValue('tab-active-border-width');

        &:after {
          position: absolute;
          top: 22px;
          right: 17px;
          display: block;
          width: 0;
          height: 0;
          margin-top: -3px;
          border-color: #3e3e3e transparent transparent transparent;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          content: ' ';
        }

        .selectedTab {
        }

        .list {
          display: none;
        }

        &.toggled {
          .list {
            display: block;

            a {
              color: getThemeValue('font-color');
            }
          }

          &:after {
            border-width: 5px 5px 5px 5px;
            border-color: transparent transparent #3e3e3e transparent;
            border-style: solid;
            margin-top: -8px;
          }

          .selectedTab {
          }
        }
      }
    }

    .tabPanel {
      margin: 0;
      min-height: 320px;
    }
  }
}

.dashboardTabs {
  @if isNewTheme() {
  } @else {
    margin: 0 -38px;
  }

  @media(max-width: $phone-width) {
    @if isNewTheme() {
      margin: 0 -10px;
    } @else {
      margin: 0 -24px;
    }
  }

  .loaderView {
    min-height: 150px;

    @media(max-width: $menu-toggle-width) {
      min-height: 140px;
    }

    @media(max-width: 650px) {
      min-height: 40px;
    }
  }

  .tabsNavigation {
    @if isNewTheme() {
    } @else {
      background: $subtle-off-white;
    }

    &.mobile {
      display: none;
    }

    .tabsMenu {
      display: flex;
      list-style: none;
      @if isNewTheme() {
        padding: 0px 0px 10px 20px;
      } @else {
        padding: 10px 20px;
        width: 100%;
      }
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: getThemeValue('insight-tab-border');
      position: relative;
      z-index: 1;

      .tabsMenuItem {
        min-width: 150px;
        text-align: center;
        flex: 1;

        .tabItem {
          padding: 8px 8px 12px;

          @if isNewTheme() {
            margin: 0px;
            padding: 12px;
            height: 100%;
          } @else {
            margin: 38px 8px 1px 8px;
            min-height: 150px;
          }
          position: relative;
          color: getThemeValue('font-color');
          background: getThemeValue('insight-tab-inactive-background-color');
          cursor: pointer;

          &.tabActive {
            background: getThemeValue('insight-tab-active-background-color');
            border: getThemeValue('insight-tab-border');
            border-radius: getThemeValue('container-border-radius');
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            position: relative;
            z-index: 2;

            @if isNewTheme() {
              margin-top: 0px;
              bottom: -1px;
              min-height: 130px;
              border-bottom: 1px solid white;
            } @else {
              margin-top: 34px;
              bottom: -4px;
              border-bottom: 4px solid white;
              min-height: 154px;
            }
          }

          .tabShadow {
            @if isNewTheme() {
              display: none;
            } @else {
            }
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            background: -webkit-linear-gradient(white, #edf3f7); /* For Safari 5.1 to 6.0 */
            background: -o-linear-gradient(white, #edf3f7); /* For Opera 11.1 to 12.0 */
            background: -moz-linear-gradient(white, #edf3f7); /* For Firefox 3.6 to 15 */
            background: linear-gradient(white, #edf3f7); /* Standard syntax */
          }

          .tabHeader {
            text-align: left;
            padding: 10px 5px;
            display: flex;
            align-items: center;

            @if isNewTheme() {
              font-weight: 500;
              font-size: 15px;
            } @else {
              font-size: 14px;
              text-transform: uppercase;
            }
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none;   /* Chrome/Safari/Opera */
            -khtml-user-select: none;    /* Konqueror */
            -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* Internet Explorer/Edge */
            user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */

            .tabIcon {
              display: inline-block;
              height: 24px;
              padding-left: 5px;
            }

            .tabHeaderText {
              display: inline;
            }

            @media (max-width: 650px) {
              padding: 6px 0;

              .tabHeaderText {
                display: inline-block;
              }

              .tabIcon {
                float: none;
                position: relative;
                top: 11px;
                left: 4px;
              }
            }
          }

          .tabLine {
            padding-left: 6px;
            text-align: left;
            margin-top: -16px;

            @if isNewTheme() {
              display: none;
            }
          }

          .tabValue{
            text-align: left;
            font-family: getThemeValue('insight-value-font');
            font-size: getThemeValue('insight-value-font-size');
            font-weight: getThemeValue('insight-value-font-weight');
            line-height: 40px;
            @if isNewTheme() {
              padding: 25px 0 0px 5px;
            } @else {
              padding: 15px 0 15px 5px;
            }
            display: flex;
            white-space: nowrap;

            @media(max-width: 1150px) {
              font-size: 36px;
            }
          }

          .positive {
            color: getThemeValue('insight-positive-color');
          }
          .negative {
            color: getThemeValue('insight-negative-color');
          }

          .tabSales{
            text-align: right;
            font-size: 16px;
            color: #333;
            display: block;
            padding: 10px 5px;

            span {
              text-align: left;
              align-items: left;
            }
          }
        }
      }
    }
  }

  .tabPanel {
    margin: 30px 48px;
    @if isNewTheme() {
    } @else {
      background-color: #fff;
    }
  }

  @media(max-width: $menu-toggle-width) {
    .tabsNavigation {
      .tabsMenu {
        padding: 10px 30px;
        padding-bottom: 0;

        .tabsMenuItem {

          .tabItem {
            min-height: 140px;

            &.tabActive {
              @if isNewTheme() {
                margin-top: 0px;
                bottom: -1px;
                min-height: 130px;
                border-bottom: 1px solid white;
              } @else {
                margin-top: 34px;
                bottom: -4px;
                border-bottom: 4px solid white;
                min-height: 154px;
              }
            }

            .tabValue{
              font-size: 30px;
              line-height: 40px;

              i {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 650px) {
    .tabsNavigation {
      @if isNewTheme() {
      } @else {
        padding: 0 24px;
        background: $subtle-off-white;
      }
      position: relative;
      z-index: 1;

      .tabsMenu {
        @if isNewTheme() {
          padding: 0px;
        } @else {
          padding: 30px 20px;
        }
        padding-bottom: 0;
        margin: 0;
        flex-direction: column;
        border: none;

        .tabsMenuItem {

          .tabItem {
            padding: 8px 8px 12px;
            min-height:40px;
            max-height: 40px;
            min-width: 130px;
            max-width: 260px;
            margin: 0;
            @if isNewTheme() {
              color: getThemeValue('font-color');
              background: $White;
              border: none;
            } @else {
              border: 1px solid #FFF;
              color: #FFF;
              background: $yoco-blue;
            }
            float: right;
            z-index: 2;

            &.tabActive {
              max-width: 650px;
              min-width: 210px;
              width: 75%;
              min-height:124px;
              margin: 0;
              top: 30px;
              background: #fff;
              position: absolute;
              @if isNewTheme() {
                top: 0;
              } @else {
                height: 124px;
              }
              bottom: 0;
              z-index: 1;
              border: none;

              .tabHeader{
                text-align: left;
                padding: 10px 5px;
                @if not isNewTheme() {
                  color: #333;
                }

                .tabIcon {
                  display: inline-block;
                }
              }

              .tabLine {
                padding-left: 6px;
                text-align: left;
                margin-top: -16px;
              }

              .tabValue{
                text-align: left;
                font-size: 36px;
                line-height: 36px;
                padding: 15px 0 15px 5px;
                display: flex;
                white-space: nowrap;

                @media(max-width: 330px) {
                  font-size: 28px;
                  line-height: 28px;
                }
              }

              .tabSales{
                text-align: right;
                font-size: 16px;
                color: #333;
                display: block;
                padding: 10px 5px;

                span {
                  text-align: left;
                  align-items: left;
                }
              }
            }

            .tabShadow {
              display: none;
            }

            .tabHeader{
              text-align: center;
              padding: 4px 4px;

              .tabIcon {
                display: none;
              }
            }

            .tabLine {
              display: none;
            }

            .tabValue{
              display: none;
            }

            .tabSales{
              display: none;
            }
          }
        }
      }
    }
  }
}
