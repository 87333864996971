@charset "UTF-8";

@font-face {
  font-family: "yoco-register";
  src:url("./yoco-register.eot");
  src:url("./yoco-register.eot?#iefix") format("embedded-opentype"),
  url("./yoco-register.woff") format("woff"),
  url("./yoco-register.ttf") format("truetype"),
  url("./yoco-register.svg#yoco-register") format("svg");
  font-weight: normal;
  font-style: normal;
}

:global {
  [data-icon]:before {
    font-family: "yoco-register" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [class^="icon2-"]:before,
  [class*=" icon2-"]:before {
    font-family: "yoco-register" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon2-chevron-right:before {
    content: "\61";
  }

  .icon2-chevron-left:before {
    content: "\62";
  }

  .icon2-chevron-up:before {
    content: "\63";
  }

  .icon2-chevron-down:before {
    content: "\64";
  }

  .icon2-bubble-ask-2:before {
    content: "\65";
  }

  .icon2-credit-card:before {
    content: "\66";
  }

  .icon2-visa-card:before {
    content: "\67";
  }

  .icon2-master-card:before {
    content: "\68";
  }

  .icon2-camera-front:before {
    content: "\69";
  }

  .icon2-photo-1:before {
    content: "\6a";
  }

  .icon2-delete-2:before {
    content: "\6b";
  }

  .icon2-close:before {
    content: "\6c";
  }

  .icon2-connecting:before {
    content: "\6f";
  }

  .icon2-retry:before {
    content: "\70";
  }

  .icon2-done:before {
    content: "\71";
  }

  .icon2-more:before {
    content: "\75";
  }

  .icon2-share:before {
    content: "\76";
  }

  .icon2-search:before {
    content: "\77";
  }

  .icon2-down:before {
    content: "\78";
  }

  .icon2-up:before {
    content: "\79";
  }

  .icon2-next:before {
    content: "\7a";
  }

  .icon2-note:before {
    content: "\41";
  }

  .icon2-back:before {
    content: "\42";
  }

  .icon2-split:before {
    content: "\43";
  }

  .icon2-menu:before {
    content: "\44";
  }

  .icon2-add-medium:before {
    content: "\45";
  }

  .icon2-cashnotes:before {
    content: "\47";
  }

  .icon2-clear:before {
    content: "\48";
  }

  .icon2-tile-plus:before {
    content: "\49";
  }

  .icon2-e105:before {
    content: "\6d";
  }

  .icon2-m010:before {
    content: "\4a";
  }

  .icon2-shuttle:before {
    content: "\4b";
  }

  .icon2-plane-paper-2:before {
    content: "\4d";
  }

  .icon2-credit-card-lock:before {
    content: "\4c";
  }

  .icon2-bluetooth-1:before {
    content: "\4e";
  }

  .icon2-wireless-signal:before {
    content: "\4f";
  }

  .icon2-arrow-left:before {
    content: "\50";
  }

  .icon2-arrow-right:before {
    content: "\51";
  }

  .icon2-arrow-up:before {
    content: "\52";
  }

  .icon2-arrow-down:before {
    content: "\53";
  }

  .icon2-information:before {
    content: "\54";
  }

  .icon2-question-mark:before {
    content: "\55";
  }

  .icon2-alert-2:before {
    content: "\56";
  }

  .icon2-alert-1:before {
    content: "\57";
  }

  .icon2-check-circle-2:before {
    content: "\58";
  }

  .icon2-delete-3:before {
    content: "\59";
  }

  .icon2-minus-3:before {
    content: "\5a";
  }

  .icon2-return:before {
    content: "\30";
  }

  .icon2-add-3:before {
    content: "\31";
  }

  .icon2-power-1:before {
    content: "\32";
  }

  .icon2-clock-2:before {
    content: "\33";
  }

  .icon2-timer-full-1:before {
    content: "\34";
  }

  .icon2-amex-card:before {
    content: "\35";
  }

  .icon2-percent-3:before {
    content: "\36";
  }

  .icon2-timer-quarter-1:before {
    content: "\37";
  }

  .icon2-usb:before {
    content: "\38";
  }

  .icon2-filter-1:before {
    content: "\39";
  }

  .icon2-refund:before {
    content: "\21";
  }

  .icon2-check:before {
    content: "\22";
  }

  .icon2-coins-1:before {
    content: "\23";
  }

  .icon2-coins-r:before {
    content: "\24";
  }

  .icon2-minus-thick:before {
    content: "\27";
  }

  .icon2-small-caps:before {
    content: "\28";
  }

  .icon2-all-caps:before {
    content: "\29";
  }

  .icon2-qr-code:before {
    content: "\2a";
  }

  .icon2-leading-2:before {
    content: "\2c";
  }

  .icon2-chart-up:before {
    content: "\2d";
  }

  .icon2-sort:before {
    content: "\2e";
  }

  .icon2-sort-alpha-asc:before {
    content: "\2f";
  }

  .icon2-arrow-combo:before {
    content: "\3a";
  }

  .icon2-house-4:before {
    content: "\3b";
  }

  .icon2-shop-1:before {
    content: "\3e";
  }

  .icon2-chat-4:before {
    content: "\3f";
  }

  .icon2-user-2:before {
    content: "\5b";
  }

  .icon2-cash-register-1:before {
    content: "\60";
  }

  .icon2-full-moon:before {
    content: "\7c";
  }

  .icon2-smiley-happy-4:before {
    content: "\7d";
  }

  .icon2-check-2:before {
    content: "\7e";
  }

  .icon2-cross:before {
    content: "\5c";
  }

  .icon2-bubble-conversation-4:before {
    content: "\e000";
  }

  .icon2-yoco-register:before {
    content: "\6e";
  }

  .icon2-letter-1:before {
    content: "\e002";
  }

  .icon2-present:before {
    content: "\e003";
  }

  .icon2-keypad:before {
    content: "\e004";
  }

  .icon2-list:before {
    content: "\e006";
  }

  .icon2-divide:before {
    content: "\e007";
  }

  .icon2-tile:before {
    content: "\e008";
  }

  .icon2-elipses:before {
    content: "\e009";
  }

  .icon2-snapscan:before {
    content: "\e00a";
  }

  .icon2-bank-note-1:before {
    content: "\e00e";
  }

  .icon2-tag-1:before {
    content: "\3c";
  }

  .icon2-tag-2:before {
    content: "\3d";
  }

  .icon2-tag-delete:before {
    content: "\40";
  }

  .icon2-tag-add:before {
    content: "\5d";
  }

  .icon2-tags-1:before {
    content: "\5e";
  }

  .icon2-tags-2:before {
    content: "\5f";
  }

  .icon2-star-2:before {
    content: "\7b";
  }

  .icon2-thin-chevron:before {
    content: "\e001";
  }

  .icon2-split-bill:before {
    content: "\e00f";
  }

  .icon2-business-chart-2:before {
    content: "\e010";
  }

  .icon2-shop:before {
    content: "\e011";
  }

  .icon2-business-chart-2-1:before {
    content: "\e012";
  }

  .icon2-shop-settings:before {
    content: "\e013";
  }

  .icon2-staff-settings:before {
    content: "\e014";
  }

  .icon2-minus-1:before {
    content: "\e017";
  }

  .icon2-arrow-left-1:before {
    content: "\e018";
  }

  .icon2-arrow-right-1:before {
    content: "\e019";
  }

  .icon2-credit-card-1:before {
    content: "\e01b";
  }

  .icon2-house-4-1:before {
    content: "\e01c";
  }

  .icon2-cash-register:before {
    content: "\e01d";
  }

  .icon2-user-2-1:before {
    content: "\e01e";
  }

  .icon2-log-out:before {
    content: "\e021";
  }

  .icon2-end-shift:before {
    content: "\e022";
  }

  .icon2-add-thin:before {
    content: "\e023";
  }

  .icon2-minus-thin:before {
    content: "\e024";
  }

  .icon2-add-thick:before {
    content: "\e025";
  }

  .icon2-information-1:before {
    content: "\e029";
  }

  .icon2-location:before {
    content: "\e02a";
  }

  .icon2-alarm-1:before {
    content: "\e02b";
  }

  .icon2-microphone-3:before {
    content: "\e02c";
  }

  .icon2-enter-amount:before {
    content: "\2b";
  }

  .icon2-wish-list-selected:before {
    content: "\e02e";
  }

  .icon2-wish-list:before {
    content: "\e02f";
  }

  .icon2-yoco-register-1:before {
    content: "\e02e";
  }

  .icon2-yoco-register-2:before {
    content: "\e02f";
  }

  .icon2-settings:before {
    content: "\e01a";
  }

  .icon2-support:before {
    content: "\e015";
  }

  .icon2-exclamation:before {
    content: "\e016";
  }

  .icon2-calendar-2:before {
    content: "\e01f";
  }

  .icon2-calendar-1:before {
    content: "\e020";
  }

  .icon2-add-4:before {
    content: "\e026";
  }

  .icon2-minus-4:before {
    content: "\e027";
  }

  .icon2-add-a-product:before {
    content: "\e02d";
  }

  .icon2-add-a-staff-member:before {
    content: "\e030";
  }

  .icon2-manage-your-favourites:before {
    content: "\e031";
  }

  .icon2-bank-details:before {
    content: "\e032";
  }

  .icon2-brands-and-categories:before {
    content: "\e033";
  }

  .icon2-pair-your-reader:before {
    content: "\e034";
  }

  .icon2-set-up-tips:before {
    content: "\e037";
  }

  .icon2-edit-modify-streamline:before {
    content: "\e028";
  }

  .icon2-profile-1:before {
    content: "\e038";
  }

  .icon2-sign-new-1:before {
    content: "\e039";
  }

  .icon2-connection-2:before {
    content: "\e03a";
  }

  .icon2-bin-2:before {
    content: "\25";
  }

  .icon2-connection-2-1:before {
    content: "\e03b";
  }

  .icon2-power-1-1:before {
    content: "\26";
  }

  .icon2-power-2:before {
    content: "\e03c";
  }

  .icon2-star-1:before {
    content: "\e03d";
  }

  .icon2-star-1-1:before {
    content: "\e03e";
  }

  .icon2-tablet:before {
    content: "\e03f";
  }

  .icon2-heart-1:before {
    content: "\e040";
  }

  .icon2-bed:before {
    content: "\e041";
  }

  .icon2-shopping-1:before {
    content: "\e042";
  }

  .icon2-eftstep:before {
    content: "\e044";
  }

  .icon2-downloadstep:before {
    content: "\e045";
  }

  .icon2-ficastep:before {
    content: "\e046";
  }

  .icon2-transactionstep:before {
    content: "\e047";
  }

  .icon2-bankstep:before {
    content: "\e043";
  }

  .icon2-statepending:before {
    content: "\e04a";
  }

  .icon2-capital:before {
    content: "\e048";
  }

  .icon2-capital-deselected:before {
    content: "\e049";
  }

  .icon2-dasboard-deselected:before {
    content: "\e04b";
  }

  .icon2-dashboard:before {
    content: "\e04c";
  }

  .icon2-copy:before {
    content: "\e04f";
  }

  .icon2-setup:before {
    content: "\e04d";
  }

  .icon2-setup-deselected:before {
    content: "\e04e";
  }

  .icon2-green-tick:before {
    content: "\e052";
  }

  .icon2-add-files:before {
    content: "\e050";
  }

  .icon2-edit-pen:before {
    content: "\e051";
  }

  .icon2-file-success:before {
    content: "\e053";
  }

  .icon2-file-upload:before {
    content: "\e054";
  }

  .icon2-lock-unlock-2:before {
    content: "\e056";
  }

  .icon2-lock-2-1:before {
    content: "\e057";
  }

  .icon2-lock-unlock-2-1:before {
    content: "\e058";
  }

  .icon2-lock-2:before {
    content: "\e055";
  }

  .icon2-bubble-comment-1:before {
    content: "\e059";
  }

  .icon2-home:before {
    content: "\e005";
  }

  .icon2-map-pin-streamline:before {
    content: "\e05a";
  }

  .icon2-saved-bills:before {
    content: "\e05c";
  }

  .icon2-wallet:before {
    content: "\72";
  }

  .icon2-help-circled:before {
    content: "\73";
  }

  .icon2-live-chat:before {
    content: "\74";
  }

  .icon2-ink:before {
    content: "\46";
  }

  .icon2-cart-2:before {
    content: "\e00b";
  }

  .icon2-refer-icon:before {
    content: "\e035";
  }

  .icon2-bluetooth:before {
    content: "\e036";
  }

  .icon2-adjust:before {
    content: "\e05b";
  }

  .icon2-yoco-register-5:before {
    content: "\e05d";
  }

  .icon2-percentage:before {
    content: "\e05e";
  }

  .icon2-refund-your-test:before {
    content: "\e05f";
  }

  .icon2-cash-note:before {
    content: "\e060";
  }

  .icon2-cart:before {
    content: "\e061";
  }

  .icon2-perform-a-test-amount:before {
    content: "\e062";
  }

  .icon2-cart-full:before {
    content: "\e063";
  }

  .icon2-baseline-new-releases:before {
    content: "\e00c";
  }

  .icon2-outline-new-releases:before {
    content: "\e00d";
  }

  .icon2-app-window-cart-1:before {
    content: "\e079";
  }
}
