@import '../../assets/styles/variables';

.container {
  text-align: center;
  display: block;
  margin: auto;
  max-width: 800px;

  @media(max-width: 1160px) {
    margin: 0 90px 0 90px;
  }

  @media(max-width: 1095px) {
    margin: 0 60px 0 60px;
  }

  @media(max-width: 1040px) {
    margin: 0 30px 0 30px;
  }

  @media(max-width: 785px) {
    margin: 0;
  }

  .supportCopy {
    padding: 20px 0;
    color: $font-new-dark;
    font-size: 16px;
    letter-spacing: 0.12px;
    line-height: 20px;

    @media(max-width: screen-xxs-max) {
      line-height: 24px;
    }
  }
}

.image{
  padding: 10px 0;
}

.errorDescription {
  color: red;
  padding: 5px;
}
