@import "../../../../../../../../../../assets/styles/variables.scss";

.errorMessage {
    color: $red;
}

.locationButton {
    border: none;
    background: none;
    color: $yoco-blue;
    text-decoration: underline;
}

.locationButtonSearching {
    border: none;
    background: none;
    color: $black-text;
}