@import '../../assets/styles/variables';

.tooltip {
  position: relative;
  z-index: 999;
  font-weight: normal;

  .content {
    display: none;
  }

  &:hover {
    .content {
      display: block;
      position: absolute;

      left: 50%;
      transform: translateX(-50%);

      top: 100%;

      background: white;
      padding: getThemeValue('content-md-padding-size');
      box-shadow: getThemeValue('card-box-shadow');
      border: getThemeValue('card-border');
      border-radius: getThemeValue('container-border-radius');
      min-width: 150px;
    }
  }
}