@import "../../../../assets/styles/variables.scss";

.container {
  background-color: $Gray-100;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  padding: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;

  @media (max-width: $tablet-width) {
    display: block;
    text-align: center;
  }

  .textWrapper {
    flex: 2;
    text-align: center;

    @media (max-width: $tablet-width) {
      flex: 1;
      margin-bottom: 20px;
    }

    .text {
      font-weight: bold;
      font-size: 22px;
      margin: 20px 10px;
    }
  }
}
