@import '../../../../assets//styles/variables.scss';

.summary {
  .heading {
    text-align: center;
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }

    .amount {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-top: 30px;

      h4 {
        margin: 0;
      }

      .percentage {
        color: $Green-500;
      }

      p {
        font-size: larger;
        color: $Gray-700;
        font-weight: bold;
      }
    }

    .details {
      flex: 1.2;
    }
  }
}
