@import "../../assets/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: getThemeValue('list-item-padding');
  border-bottom: getThemeValue('list-item-border');
  white-space: normal;

  &:last-of-type {
    border-bottom: none;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.left {
  margin-right: getThemeValue('list-item-padding');
  & > i[class*="yocodsicon-"] {
    display: flex;
  }
}

.middle {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middleLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.middleRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right {
  margin-left: getThemeValue('list-item-padding');
  & > i[class*="yocodsicon-"] {
    display: flex;
  }
}

.title {
  color: getThemeValue('list-item-title-color');
  font-size: getThemeValue('list-item-title-font-size');
  font-weight: getThemeValue('list-item-title-font-weight');
}

.subTitle {
  color: getThemeValue('list-item-subtitle-color');
  font-size: getThemeValue('list-item-subtitle-font-size');
  font-weight: getThemeValue('list-item-subtitle-font-weight');
}

.description {
  color: getThemeValue('list-item-description-color');
  font-size: getThemeValue('list-item-description-font-size');
  font-weight: getThemeValue('list-item-description-font-weight');
}

.subDescription {
  color: getThemeValue('list-item-subdescription-color');
  font-size: getThemeValue('list-item-subdescription-font-size');
  font-weight: getThemeValue('list-item-subdescription-font-weight');
}