.root {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  border-radius: 30px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
