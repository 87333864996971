@import '../../../../assets/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.image {
  width: 60px;
  margin-bottom: 8px;
}

.description {
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;

  @media (max-width: $screen-xxs-max) {
    padding-bottom: 22px;
  }
}

.updatedImageStyle {
  width: 65;
  height: 50;
  margin-top: 10;
  margin-left: 5;
}
