@import "../../assets/styles/variables.scss";

.fileUpload {
  margin: 6px;
  background: $yoco-blue;
  color: #fff;
  border-radius: 40px;
  padding: 11px 17px;
  margin-right: 4px;
  display: inline;
  border: none;
  font-size: 14px;
  vertical-align: top;
  min-width: 100px;

  .btnFileUpload {
    position: relative;

    input {
      opacity: 0;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 130px;
    }
  }

  .progress {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.inputFileUpload {
  opacity: 0;
  max-height: 50px;
  max-width: 50px;
  cursor: pointer;
}

.fileUploadInput {
  display: none;
  border-bottom: none !important;
}

:global {
  input#exampleInputFile {
    :focus {
      outline: none;
    }

    label {
      outline: none;
    }
  }
}

.progressWrapper {
  margin-top: 4px;
  height: 12px;
  border-radius: 2px;
  background: $light-blue;
}

.messageAndCancel {
  position: relative;
  line-height: 22px;

  .cancelButton {
    position: absolute;
    right: 0;
    background: $light-border;
    line-height: 18px;
    border: none;
    border-radius: 4px;
    margin-top: 4px;
    padding-top: 4px;

    i {
      font-size: 11px;
      line-height: 18px;
    }
  }
}

.productsTable {
  table {
    tr {
      height: 56px;

      &.rowBackground {
      }

      &.selected {
        td {
          background-color: $subtle-blue-background;

          &.spacer {
            background: white;
          }
        }
      }

      th.image {
        max-width: 100px;
      }

      th.price {
        width: 200px;
        padding-right: 24px;
        text-align: right;
        line-height: 56px;
        font-weight: 500;
      }

      td.quantity {
        width: 50px;
        padding-right: 24px;
        text-align: right;
        line-height: 56px;
        font-weight: 500;
      }

      th.name {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 280px;

        @media(max-width: 600px) {
          width: 160px;
          left: 10px;
        }
      }
    }

    tbody {
      tr {
        td.image {
          max-width: 100px;
        }

        td.delete {
          max-width: 70px;
        }

        td.amount {
          width: 200px;
          text-align: right;
          font-weight: 500;
        }

        td.items {
          width: 200px;
          text-align: center;
          font-weight: 500;
        }

        td.quantity {
          width: 50px;
          text-align: right;
          font-weight: 500;
        }

        td.name {
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 280px;

          @media(max-width: 600px) {
            width: 160px;
            left: 10px;
          }
        }
      }
    }
  }
}

.option {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 70px;
  margin-bottom: 8px;
}

.fullCover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fullCoverinputFileUpload {
  opacity: 0;
  height: 100%;
  cursor: pointer;
}

