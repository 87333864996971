@import '../../assets/styles/variables.scss';

.page {
  text-align: center;
  display: block;
  margin: auto;
  max-width: 961px;
  min-width: 300px;

  @media (max-width: 1160px) {
    margin: 0 90px 0 90px;
  }

  @media (max-width: 1095px) {
    margin: 0 60px 0 60px;
  }

  @media (max-width: 1040px) {
    margin: 0 30px 0 30px;
  }

  @media (max-width: 785px) {
    margin: 0;
  }

  .lineBreak {
    height: 21px;
  }

  .supportCopy {
    padding: 25px 0 10px 0;
    margin: 0;

    @media (max-width: $screen-xxs-max) {
      line-height: 24px;
      padding: 0px 25px 60px 25px;
      margin: 0;
    }
  }
}

.solePropLine {
  float: left;
  width: 50%;

  .name {
    flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
  }

  .optionsContainer {
    flex: 1;
    width: 100px;
    display: inline-block;

    span {
      margin-left: 8px;
    }
  }
}

.bankingInnerDetailsContainer {
  padding: 20px;
  //margin: 0 auto 24px;
  background: white;
  max-width: 650px;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

.bankingDetails {
  padding: 8px 0 10px 16px;
  background: #f7f7f7;
  border-radius: 4px;
  p {
    color: black;
  }
}

.conditionsText {
  font-weight: lighter;
  margin-bottom: 20px;
  font-size: 14px;
  color: $gray-text;
}

.summaryBlock {
  font-size: 12px;
  font-weight: light;
  padding: 12px;
  background: #f7f7f7;
  margin-bottom: 24px;
  line-height: 22px;
  text-align: left;
  margin-right: 20px;

  strong {
    font-family: getThemeValue('font');
  }
}

.disbursementPayment {
  font-weight: light;
  font-size: 14px;
  color: #31708f;
  padding: 12px;
  background: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  margin-top: 12px;

  &.newOffersNotice {
    margin-bottom: 20px;
    background: #d9edf7;
    box-shadow: 0 0 12px 4px rgba(229, 229, 229, 1);

    @media (max-width: $phone-width) {
      margin: 6px auto 14px;
    }
  }

  b {
    font-family: getThemeValue('font');
  }
}

.consumerAgreementLink {
  text-decoration: none !important;
  border-bottom: 1px solid $light-blue;
  font-weight: light;
  font-size: 14px;
  color: $light-blue;
  float: left;
}

/***********Landing page**************/
.topLandingContainer {
  padding: 20px;
  display: block;
  text-align: center;

  img {
    max-width: 100%;
    padding: 10px;
  }

  h1 {
    span {
      font-size: 20px;
    }
  }

  h2 {
    color: #3e3e3e;
    line-height: 36px;
    font-weight: light;

    @media (max-width: 399px) {
      font-size: 15px;
    }
  }
}

.middleLandingContainer {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  @media (max-width: $phone-width) {
    display: block;
  }
}

.applicationCardSummaryHeader {
  .formCardHeader {
    margin-bottom: 8px;
    height: 30px;
    color: $font-dark;
    line-height: 30px;
    font-size: 15px;
    text-transform: capitalize;

    @media (max-width: 778px) {
      font-size: 13px;
      font-weight: medium;
    }
    @media (max-width: 736px) {
      font-size: 15px;
    }
    @media (max-width: 437px) {
      font-size: 13px;
    }
  }

  .howItWorks {
    @media (max-width: 404px) {
      color: transparent;
    }
  }

  .headerLine {
    text-align: left;
    margin-top: -20px;
    margin-bottom: 10px;
    color: $Green-500;

    @media (max-width: 404px) {
      color: transparent;
    }
  }
}

.innerContainers {
  padding: 10px;
  width: 50%;
  display: flex;

  @media (max-width: $phone-width) {
    display: block;
    width: initial;
  }
}

/********Application Page**********/
.cardContentsContainer {
  padding: 0 20px;
  margin-bottom: 20px;
  @media (max-width: $phone-width) {
    width: 100%;
    padding: 0 20px;
  }
}

.capitalPricingBlockText {
  font-weight: bold;
  font-size: 22px;
  margin: 20px 10px;
}

.capitalPricingBlock {
  background-color: $Gray-100;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  padding: 20px;
  margin-top: 70px;
  display: flex;
  flex-direction: row;

  @media (max-width: $tablet-width) {
    display: block;
    text-align: center;
  }
}

.capitalPricingBlockInner {
  flex: 2;
  text-align: center;

  @media (max-width: $tablet-width) {
    flex: 1;
    margin-bottom: 20px;
  }
}

.sliderContainer {
  width: 100%;
  margin-bottom: 80px;

  h3 {
    color: $Green-500;
    font-weight: bold;
  }

  @media (max-width: $phone-width) {
    padding-bottom: 30px;
    margin: 0;
    padding-top: 20px;
  }
}

.consentContainer {
  display: flex;
  flex-direction: row;
  padding-left: 40px;

  @media (max-width: $screen-xxs-max) {
    padding-left: 0px;
  }

  @media (max-width: $screen-md-min) {
    margin: 0 15px;
  }

  .consentTextContainer {
    padding-top: 3px;
    padding-left: 20px;
    width: 90%;
    text-align: left;
  }
}

.capitalQualifiedExtraDescription {
  font-size: 15px;
  line-height: 24px;
  padding: 20px 25px 0 25px;
}

.capitalQualifiedBlock {
  padding-top: 20px;
  text-align: center;
  color: $Brand-Black;

  .capitalQualifiedTextWrap {
    padding: 10px 0;

    .capitalAmount {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      color: $Green-500;

      @media (max-width: $screen-xs-max) {
        font-size: 35px;
        padding-top: 10px;
      }
    }

    .capitalQualifiedBlockTitle {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.15px;
      line-height: 24px;
      padding-bottom: 10px;
    }

    .capitalQualifiedBlockDescription {
      font-size: 15px;
      line-height: 24px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .capitalQualifiedButtonWrap {
    padding: 10px 0;

    .capitalMoreCopy {
      padding-top: 10px;
    }
  }

  img {
    height: 245px;
  }
}

.footer {
  margin: 0 auto;
  width: 60%;
  text-align: center;

  p {
    font-weight: bold;
    color: #8ace40;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
  }

  &.providerLogoContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
  }

  img {
    height: 200px;

    @media (max-width: $phone-width) {
      max-height: 120px;
      max-width: 260px;
    }
  }

  @media (max-width: 1120px) {
    width: 100%;
  }

  @media (max-width: $screen-xxs-max) {
    width: 60%;
    margin: 0;
  }

  @media (max-width: 375px) {
    width: 80%;
  }
}

.almostThere {
  /* section title: */
  font-weight: 500;
  font-size: 13px;
  color: $Gray-700;
}

.capitalPrelimFormAction {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 3px;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
  }
}

.titleCardChildColumn {
  display: flex;
  flex-direction: column;
  padding: 0 40px;

  @media (max-width: $phone-width) {
    padding: 0 25px;
  }
}

.titleCardChildRow {
  display: flex;
  flex-direction: row;
  padding: 10px 50px;
}

.flexEndRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  float: right;
}

.readMoreLink {
  background-color: transparent;
  padding-left: 0;
  float: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: $Green-500;
  border: none !important;
  text-decoration: none !important;
}

/***********Accept Offer page**************/

.filterCapitalContainer {
  background-color: getThemeValue('card-background-color');
}

.viewContract {
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $screen-xs-max) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.spaceBetweenRow {
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  justify-content: space-between;
}

.textAlignLeft {
  text-align: left;
}

.boldText {
  font-weight: bold;
  text-align: left;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.smallPadding {
  padding-left: 10px;
}

.boldLetterSpacing {
  font-weight: bold;
  letter-spacing: 1px;
}

.textDecorationNone {
  text-decoration: none;
}

.displayInlineblock {
}

/******** ApprovedPage ********/

.approvedPageButtonContainer {
  display: flex;
  justify-content: flex-end;

  .button {
    height: 48px;
    min-width: 165px;
    background-color: $Green-500;
    border-radius: 3px;
    color: $white;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin: 0 10px;
    padding-top: 12px;
  }

  @media (max-width: $phone-width) {
    justify-content: center;
    .button {
      width: 100%;
    }
  }
}

.tableRow {
  text-align: right;
}

.tableRowPadded {
  text-align: right;
  padding-right: 20px;
}

.tableRowHeader {
  font-weight: bold;
  font-size: smaller;
  color: $Gray-700;
}

.table {
  padding: 5px;
}

.capitalFindOutMore {
  margin: 6px;
  background: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
}

.capitalDeclinePopupTitleBox {
  text-align: center;
  padding: 5%;
}

.capitalDeclinePopupQuestionBox {
  text-align: center;
  padding: 0;
  overflow: auto;
}

.capitalDeclinePopupRadioButtons {
  font-size: 18px;
  padding: 1rem 0% 2rem 10rem;
  @media (max-width: $menu-toggle-width) {
    font-size: 15px;
    padding: 1rem 0% 4rem 5rem;
  }
  @media (max-width: $screen-xxs-max) {
    padding: 1rem 0% 4rem 2rem;
  }
}

.capitalDeclinePopupRadioButtonsWrapper {
  line-height: 40px;
  font-size: 15px;
}

.capitalDeclinePopupButtons {
  float: right;
  padding: 16px;

  @media (max-width: $screen-xxs-max) {
    float: inherit;
  }
}

.capitalDeclinePopupLabelStyle {
  float: right;
  padding: 1%;
}

.deductionRowItem {
  @media (max-width: $screen-xs-max) {
    padding: 0 0 0 5px !important;
  }
}
