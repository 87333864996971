@import "../../assets/styles/variables.scss";

.imageInputUploader{
  max-width: 150px;
  cursor: pointer;
}

.logo {
  height: 150px;
  width: 150px;
  background-color: #00a2e0;
  border: solid 1px #000000;
  border-radius: 3px;
}

.loaderStyle {
  width: 68px;
}

.uploadButton {
  margin: 5px 20px;
  height: 70px;
  width: 70px;
  cursor: pointer;
  border-radius: 2px;
}

.headerFont {
  font-size: inherit;
  font-weight: bold;
}

.receiptUpload {
  max-width: 300px;

  i {
    font-size: 26px;
    line-height: 48px;
  }

  span {
    display: block;
    font-weight: 500;
    color: $font-dark;
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: initial;
  }

  .receiptLogoUploadBlock {
    display: flex;

    .receiptLogo {
      font-size: getThemeValue('button-font-size');
      font-weight: getThemeValue('button-font-weight');
      border-radius: getThemeValue('button-border-radius');
      background: getThemeValue('button-secondary-background-color');
      border: getThemeValue('button-secondary-border');
      color: getThemeValue('button-secondary-font-color');
      height: 40px;
      padding: 0 16px;
      min-width: 100px;
      line-height: 40px;

      &:hover {
        opacity: 0.8;
        color: getThemeValue('button-secondary-font-color');
      }

      text-align: center;
      margin: 0 0 12px;
      cursor: pointer;
      position: relative;
      display: inline-block;

      input {
        max-width: 100px;
        width: 94px;
        margin-left: -24px;
        border: none;
        cursor: pointer;
        padding: 8px;

        :active :focus {
          border: none;
        }
      }

      .loaderStyle {
        display: none;
      }
    }
  }
}

.frameView {
  border: 1px black solid;
}

.characters {
  font-size: 12px;
  color: $font-light-gray;
  margin: -6px 0 6px;
  text-align: right;
}

.iframeContainer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  top: 60px;

  iframe {
    height: 100%;
    width: 100%;
    font-family: getThemeValue('font');
    border: none;
  }
}
