@import "../../../../assets/styles/variables.scss";

.dropDownButton {
  height: 36px;
  padding: 0 $Padding-Tighter;
  margin: 0;
  min-width: auto;
  & > [class^="yocodsicon-"] {
    margin: 0;
  }
}