.link {
    text-shadow: none;
    font-weight: bold;
    line-height: 24px;

    a {
        text-decoration: none;
    }

    i {
        vertical-align: middle;
    }
}