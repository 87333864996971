@import "../../assets/styles/variables.scss";

.copyableLink {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.copyableLinkInputContainer {
  border: getThemeValue('form-field-border');
  border-radius: getThemeValue('form-field-border-radius');
  position: relative;
  background: white;
  color: getThemeValue('font-color');
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  input {
    border: none;
    flex-grow: 1;
    cursor: pointer;

    &:active {
      border: none;
    }
  }
}

.copyIcon {
  height: 24px;
  line-height: 24px;
  margin: 0px 12px;
}

.copyableLinkInput {
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}

.copyableLinkMessage {
  font-size: 13px;
  font-weight: 500;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.urlText {
  color: $Gray-700;
}
