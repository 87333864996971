@import "./variables.scss";

:global {
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: $Blue-500;
  }

  a:focus {
    outline: none !important;
    text-decoration: none;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $Brand-Black;
    line-height: 1.5;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  * html .clearfix             { zoom: 1; } /* IE6 */
  *:first-child+html .clearfix { zoom: 1; } /* IE7 */
}
