@import "../../assets/styles/variables.scss";

.cardHolder {
  padding: 25px;
  background-color: getThemeValue('card-background-color');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('container-border-radius');
  box-shadow: getThemeValue('card-box-shadow');
  overflow: hidden;
  min-width: 270px;
  position: relative;
  max-width: 355px;
}

.infoButton{
  display: inline-block;
  padding-top: 38px;
}

.newCardHolder {
  max-width: none;

  @media (max-width: 785px) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-right: 0;
  }
} 

.newCardContainer {
  @media (max-width: 785px) {
    display: flex;
    flex-direction: column;
    margin-right: 0;

    div {
      margin-right: 0;
    }

    div:first-child {
      margin-left: 0;
    }
    div:last-child {
      margin-left: 0;
      margin-right: 0;
    }

  }
}

.cardContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: $Padding-Default;

  @media (max-width: 785px) {
    grid-template-columns: 1fr;
  }
}

.cardHolderImage {
  width: 100%;
}

.infoLink{
  float: right;
  margin-top: 10px;
}

.scrollContainer{
  overflow: auto;
  overflow-y: hidden;
}

.button{
  position: absolute;
  bottom: 20px
}
