@import '../../../../assets/styles/variables.scss';

.container {
  margin: -20px;

  .criteriaContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-around;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }

  .footer {
    padding: 16px 0;
    box-sizing: border-box;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .footerText {
      color: $Brand-Black;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      margin: 10px 0;
    }
  }

  @media (max-width: $phone-width) {
    padding: 0 10px;
    margin: -10px;
  }
}
