@import "../../assets/styles/variables.scss";

.form {
  .passwordContainer {
    display: none;
  }

  &.noWrap {
    .field {
      float: none;
      width: 100%;
    }
  }

  .checkboxLabelWrapper {
    display: flex;
    align-items: center;

    .checkbox {
      display: inline-block;
    }
  }

  .radioButtonField {
    button {
      border-radius: 100px;
    }
  }
}

.expandableCheckbox {
  padding: 8px;
  border: 1px solid $Gray-200;
  border-radius: getThemeValue('container-border-radius');

  .expandableCheckboxRow {
    display: flex;
    align-items: center;

  }

  .expandableCheckboxDescription {
    flex-grow: 1;
  }

  i {
    height: 24px;
    margin-right: 12px;
  }
}

.iconArrow{
  transition: 200ms;
  display: inline-block;
  -webkit-transform: translate(0, 5px);
  color:$yoco-blue;

  &.opened  {
    -webkit-transform: translate(0, -4px) rotate(180deg);

  }
}

.checkBoxDescriptionWrapper span{
  padding:10px;
}

.checkBoxDescription button{
  float:right;
  margin-top:-4px;
}

.checkBoxDescriptionText {
  padding: 12px 0px;
  margin-left: 36px;
  font-size: 13px;
  font-weight: 500;
}

.form {
  .threeFieldsWrapper {
    .field {
      float: left;
      margin-right: 12px;
    }
  }
}

.formBlock {
  margin-bottom: 24px;

  .formBlockHeading {
    display: flex;

    .formBlockHeadingTitles {
      flex-grow: 1;
    }
  }
}

.formWrapper {
  padding-bottom: 12px;

  .formLoader {
    background: transparentize(black, 0.5);
    position: fixed;
    height: initial;
    width: initial;
    border-radius: 0px;

    :global {
      .spinner-container {
        min-height: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -29px;
        margin-top: -29px;
      }
    }
  }
}

.heading {
  color: $yoco-blue;
  font-weight: 500;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  line-height: 48px
}

.infoText {
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 0 16px;
  padding: 0;
  font-family: getThemeValue('font');
}

.helpText {
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  padding: 10px 0 0;
  font-weight: light;

  &.helpBelowInput {
    position: relative;
    float: bottom;
  }
}

.fieldLabel {
  font-size: getThemeValue('form-label-font-size');
  text-transform: getThemeValue('form-label-text-transform');
  font-weight: getThemeValue('form-label-font-weight');
  color: getThemeValue('font-color');
  line-height: getThemeValue('form-label-line-height');
  margin: 0;
}

.flagshipFieldLabel {
  @extend .fieldLabel;
  font-size: 12px;
  font-family: getThemeValue('font');
  line-height: unset;
  padding-bottom: 7px;
}

.field {
  float: left;
  width: 50%;
  text-align: left;
  padding-bottom: 12px;
  position: relative;

  .description {
    color: #737373;
    font-family: getThemeValue('font');
    font-size: 12px;
    text-align: left;
    margin: 6px 0 0 0;
    line-height: 12px;
  }

  &.active {
    .fieldLabel {
      color: getThemeValue('form-label-active-color');
    }
  }

  &.isHalfWidth {
    width: 50%;

    .inputWrapper {
      padding-right: 0;

      @media(max-width: $menu-toggle-width) {
        padding-right: 0 !important;
      }
    }
  }

  &.extraLong {
    .inputWrapper {
      padding-right: 0;

      input {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .errorIcon {
    position: absolute;
    top: 31px;
    z-index: 1;
    right: 8px;
    color: $Red-500;

    &.errorBelowInput{
      top: -30px;
    }
  }

  span.required {
    float: right;
    margin-right: 20px;
    color: $font-light-gray;
    font-family: getThemeValue('font');
    font-size: 10px;
  }

  .inputWrapper {
    color: black;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px;
    line-height: 20px;
    padding-right: 20px;
    display: block;

    span.subTitle {
      color: #888E93;
      font-size: 13px;
      margin: 4px 0;
      display: flex;
    }

    @media(max-width: $menu-toggle-width) {
      padding-right: 0 !important;
    }

    @media(max-width: $tablet-width) {
      padding-right: 0;
    }
  }

  .currencyInputWrapper {
    color: black;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 36px;
    padding-right: 20px;

    .readOnlyField {
      padding-right: 16px;
    }

    input {
      height: 36px;
      padding-right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    :global {
      .input-group-addon {
        background: transparent;
        border: getThemeValue('form-field-border');
        color: getThemeValue('form-field-addon-color');
        padding-top: 8px;
        bottom: 6px;
        border-radius: getThemeValue('form-field-border-radius');
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &.left {
          border-right: none;
        }
        &.right {
          border-left: none;
        }
      }
    }

    @media(max-width: $menu-toggle-width) {
      padding-right: 0 !important;
    }

    @media(max-width: $tablet-width) {
      padding-right: 0px;
    }
  }

  .selectButtonField {
    border: getThemeValue('form-field-border');
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .buttonField {
      height: 38px;
      padding: 0 8px;
      text-align: left;
      flex: 1;

      span {
        &.placeholder {
          color: getThemeValue('form-field-placeholder-color');
        }
      }

      .clearButton {
        color: $Red-500;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
      }

      span.buttonIcon {
        float: right;
        padding-top: 1px;

        i {
          margin-right: 0;
          height: 24px;
        }
      }
    }
  }

  &.isDisabled {
    .fieldLabel {
      color: getThemeValue('form-label-disabled-color');
    }

    input {
      border: getThemeValue('form-field-disabled-border');
      background: getThemeValue('form-field-disabled-background');
    }
  }

  &.fieldError {
    .helpText {
      position: absolute;
      right: 0;
      color: $Red-500;
      text-align: right;
      padding-top: 2px;
      font-size: 11px;
      font-weight: 500;
      font-family: getThemeValue('font');
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
  }

  &.isFullWidth {
    float: none;
    width: auto;
    margin: 0 auto;

    .inputWrapper {
      padding-right: 0;
    }

    .currencyInputWrapper {
      padding-right: 0;
    }

    .required {
      margin-right: 0;
    }

    &.fieldError {
      .helpText {
        margin-right: 0;
      }
    }
  }
}

.input {
  flex: 1;
  position: relative;
}

.selectField {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  box-shadow: none;
  padding: 8px 26px 6px 8px;
  font-size: getThemeValue('form-field-font-size');

  @media(max-width: $menu-toggle-width) {
    // Mobile Safari will zoom your page if your input font size is not at least 16px
    font-size: 16px;
  }

  font-family: getThemeValue('font');
  border: getThemeValue('form-field-border');
  color: getThemeValue('font-color');
  border-radius: getThemeValue('form-field-border-radius');
  text-overflow: ellipsis;
  white-space: nowrap;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;

  &:active, &:focus {
    border: getThemeValue('form-field-active-border');
  }
}

input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  outline: none;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 8px 26px 6px 8px;
  font-size: getThemeValue('form-field-font-size');

  @media(max-width: $menu-toggle-width) {
    // Mobile Safari will zoom your page if your input font size is not at least 16px
    font-size: 16px;
  }

  font-family: getThemeValue('font');
  border: getThemeValue('form-field-border');
  color: getThemeValue('font-color');
  border-radius: getThemeValue('form-field-border-radius');
  text-overflow: ellipsis;
  white-space: nowrap;

  &:active, &:focus {
    border: getThemeValue('form-field-active-border');
  }
}

.characters {
  font-size: 11px;
  font-weight: 500;
  color: $Gray-700;
  text-align: right;
}

// ERRORS
.formHeaderError {
  background-color: $light-red;
  padding: 10px;
  border-radius: 4px;
  padding: 16px 12px;
  border: 1px solid $dark-red;
  color: $dark-red;
  font-size: 14px;
  margin: 20px 0 10px;
  font-weight: light;
  margin-bottom: 20px;
}

@media(max-width: $tablet-width) {
  .formHeaderError {
    margin: 0 20px 20px;
  }

  .form {
    &.noWrap {
      .field {
        min-height: initial;
      }
    }

    .field {
      width: 100%;
      min-height: initial;
      padding-bottom: 16px;
      text-align: left;

      .fieldLabel {
        padding-bottom: 6px;
        padding-right: 10px;
      }

      .inputWrapper {
        padding-right: 10px;
        @media (max-width: $tablet-width) {
          padding-right: 0;
        }
      }

      &.fieldError {
        .helpText {
          margin-right: 0;
        }
      }

      .errorIcon {
        top: 36px;
      }
    }
  }
}

.line {
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e5e5;
}

.readOnlyField {
  width: 100%;
  min-height: 37px;
  padding: 8px 26px 6px 8px;
  background: white;
  white-space: nowrap;
  color: getThemeValue('font-color');
  font-size: getThemeValue('form-field-font-size');
  font-family: getThemeValue('font');
  border: getThemeValue('form-field-border');
  border-radius: getThemeValue('form-field-border-radius');
  overflow-x: auto;
}

.readOnlyDisabled {
  border: getThemeValue('form-field-disabled-border');
  background: getThemeValue('form-field-disabled-background');
}

.checkboxLabel {
  margin-left: 8px;
  display: inline-block;
  line-height: 26px;
}

.tooltip {
  display: block;
  position: absolute;
  top: -2px;
  right: 26px;

  @media(max-width: $tablet-width) {
    right: 14px;
  }

  .tooltipContent {
    text-transform: none ;
    font-weight: lighter;
    font-size: 14px;
    color: #888E93;

    img {
      width: 90px;
      height: auto;
      display: block;
      position: relative;
      margin: 0 auto;
      left: 0;
      top: 10px;
      padding-bottom: 6px;
    }
    p {
      display: block;
      position: relative;
    }
  }

  .tooltipQuestionMark {
    width: 20px;
    height: 20px;
    background: $yoco-blue;
    color: white;
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);

    &:before {
      font-family: getThemeValue('font');
      content: "?";
      width: 20px;
      text-align: center;
      display: block;
      line-height: 22px;
    }
  }

  .tooltipContainer {
    span {
      font-size: 12px;
      color: #888e93;
      font-weight: light;
      padding-right: 5px;
    }

    img {
      margin-top: -3px;
    }
  }
}
