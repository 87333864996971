@import "../../assets/styles/variables.scss";

.errorTitle{
  border: 1px solid #FE6162;
  padding: 6px;
  margin-bottom: 5px;
  cursor: pointer;

}

.contentInner {
  padding: 5px 5px 5px 5px;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 5px;
}


.errorSingleRow{
  border: 1px solid #FE6162;
  padding: 5px;
  margin-bottom: 5px;

}

.expandIconLayout{
  float: right;
  margin-top: 3px;
  margin-right: 3px;
  color: #FE6162;

}

.expanderText{
  float: right;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 3px;
}