@import '../../../assets/styles/variables.scss';

.baseChip {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
}

.chipContentWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.labelText {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.labelText::first-letter {
  text-transform: capitalize;
}

.notifyIndicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  outline-width: 2px;
  outline-style: solid;
}
