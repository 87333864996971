@import "../../assets/styles/variables.scss";

.tagsWrapper {
  color: black;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 42px;
  padding-right: 0 !important;
  display: flex;
  border: getThemeValue('form-field-border');
  border-radius: getThemeValue('form-field-border-radius');

  @media(max-width: $menu-toggle-width) {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
}

.iconWrapper {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  outline: none;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 8px 12px 8px 10px;
  font-size: 14px;
  font-family: getThemeValue('font');
  border: 1px solid #e5e5e5;
  color: $black-text;
  border-radius: 0;
  background: white;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 40px;
  padding-right: 20px;
  display: flex;

  @media(max-width: $menu-toggle-width) {
    padding-right: 0 !important;
  }

  input {
    border: none;
  }

  i {
    float: right;
    color: $font-medium;
    font-size: 16px;
    margin-top: 3px;
  }
}

.row {
  flex-direction: row;
  position: relative;

  .tagContainer {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;

    .tag {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 8px 2px 0px;
      padding: 6px 6px;
      border-radius: 4px;
      display: flex;
      cursor: pointer;

      .tagText {
        color: #FFF;
        font-size: 13px;
        font-weight: 500;
        padding-right: 3px;
      }

      i {
        font-size: 8px;
        line-height: 16px;
      }
    }
  }
}