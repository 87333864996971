@import "../../assets/styles/variables.scss";

.deleteIconType {
  color: $red !important;
}

.formCard {
  min-height: 700px;
  text-align: center;
}

.description {
  text-align: center;
  .divider {
    border-top-width: 2px;
    border-color: $yoco-blue;
    max-width: 10%;
    margin: 10px 45%;
  }
}

.liveChatButton {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  width: 95%;
  height: 40px;
  top: 30px;
  left: 25px;
  right: 25px;
  padding: 10px;
  border: 2px solid $yoco-blue;
  color: $yoco-blue;
  background: white;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

.arrowButtonLeft {
  position: absolute;
  bottom: auto;
  padding: .4em;
  z-index: 2;
  border: 0 solid #3498db;
  background-color: transparent;
  top: 50%;
  left: 15px;
}

.arrowLeft {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px
}

.arrowLeft::after,
.arrowLeft::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px
}

.arrowLeft::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px
}

.arrowLeft::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor
}

.arrowButtonRight {
  position: absolute;
  bottom: auto;
  padding: .4em;
  z-index: 2;
  border: 0 solid #3498db;
  background-color: transparent;
  top: 50%;
  right: 15px;
}

.arrowRight {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px
}

.arrowRight::after,
.arrowRight::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px
}

.arrowRight::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 7px
}

.arrowRight::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor
}

.carouselImage {
  max-width: 50%;
  margin-bottom: 30px;
}

.formError {
  text-align: right;
  font-size: 11px;
  color: $red;
  height: 15px;
  right: 0;
  margin-top: 12px;
  margin-bottom: -17px;
  @media (max-width: $phone-width) {
    text-align: center;
    margin-bottom: 0;
  }
}

.formSuccess {
  text-align: right;
  font-size: 11px;
  color: $green;
  height: 15px;
  right: 0;
  margin-top: 12px;
  margin-bottom: -17px;
  @media (max-width: $phone-width) {
    text-align: center;
    margin-bottom: 0;
  }
}