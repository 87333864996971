@import "src/assets/styles/variables.scss";

.logo {
  width: 100px;
  height: 100px;
}

.uploadImageContent{
  margin-left: 30px;
}

.tileColumn {
  width: 100px;
}

.logoHolder{
  display: flex;
  flex-direction: row;
}

.priceColumn {
  text-align: right;
  padding-right: 24px !important;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-right: 6px;
  align-items: center;
  @media(max-width: $screen-xxs-max) {
    flex-direction: column;
  }
}

.inputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media(max-width: $screen-xxs-max) {
    flex-direction: column;
  }

  input {
    cursor: pointer;
    border: 0;
    &:active {
      border: 0;
    }
  }
}

.input {
  display: flex;
  flex-direction: column;

}

.container {
  border: 1px solid #cfd8dc;
  position: relative;
  background: white;
  flex: 0.6;
  margin-right: 10px;
  padding: 8px;
  cursor: pointer;
}

.pageBG {
  background: transparent!important;
}
.icon {
  position: absolute;
  right: 0px;
  padding: 0px 12px;
  pointer-events: none;
  line-height: 24px;
  cursor: pointer;
}

.copy{
  float: right;
  width:20px;
  height: 20px;
  cursor: pointer;
}
.copy:hover {
  cursor: pointer;
}

.testCard {
  padding: 0px 40px 0px 0px;
  @media (max-width: $menu-toggle-width) {
    margin-bottom: 20px;
  }
}

.keyHolder {
  display: flex;
  flex-direction: row;
  @media (max-width: $menu-toggle-width) {
    flex-direction: column;
  }
}
.editMyLink{
  margin-left: 8px;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #949ba5;
  border: none;
  padding: 1px 0 0 0;
}

.testCardContent {
  display: flex;
  flex-direction: column;

}

@media (max-width: $menu-toggle-width) {
}

.supportLinks {
  margin-top: auto;
}

.button {
  @media(max-width: $screen-xxs-max) {
    margin-top: 6px;
  }
}

.copyText {
  position: absolute;
}

.confirmCopyText{
  margin-top: -20px;
  position: absolute;
}

.testCardHolder {
  padding: 20px;
  border: 1px solid #cfd8dc;
  background: $white;
  display: flex;
  flex-direction: row;

  @media (max-width: $menu-toggle-width) {
    flex-direction: column;
  }

}

.message {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #222222;
}