@import '../../../../assets/styles/variables.scss';

.container {
  text-align: center;

  @media (max-width: $phone-width) {
    padding: 20px 20px 0 20px;
  }

  .title {
    font-size: 36px;
    line-height: 45px;

    @media (max-width: $screen-xxs-max) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .description {
    font-size: 15px;
    font-weight: normal;
    line-height: 24px;
    padding-right: 8%;
    padding-left: 8%;
    padding-bottom: 40px;

    @media (max-width: $phone-width) {
      .description {
        font-size: 15px;
        line-height: 24px;
      }
    }

    @media (max-width: $screen-xxs-max) {
      line-height: 24px;
      padding: 0 5%;
    }
  }
}
