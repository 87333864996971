
.customFooterContainer {
  padding-top: 20px;
}

.defaultFooterContainer {
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.flagshipDefaultFooterContainer{
  @extend .defaultFooterContainer;
  padding: 0;

  button {
    width: 100% !important;
    height: 52px;
    font-size: 18px;
  }
}