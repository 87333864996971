@import '../../assets/styles/variables';

.shadow {
  box-shadow: getThemeValue('card-box-shadow');
}

.rounded {
  border-radius: getThemeValue('container-border-radius');
}

.border {
  border: getThemeValue('container-border');
}

.selected {
  border: 2px solid getThemeValue('form-control-active-color');
}

.card {
  background: getThemeValue('card-background-color');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('container-border-radius');
  box-shadow: getThemeValue('card-box-shadow');
}
