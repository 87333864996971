@import "../../assets/styles/variables.scss";

.iconPopup {
  width: 400px;
  max-height: 400px;

  @media (max-width: $menu-toggle-width) {
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .popupEditSection {
    max-height: 320px;
    width: 420px;
    overflow-y: auto;
    margin: 0 -40px 4px -20px;

    @media (max-width: $menu-toggle-width) {
      max-height: 100%;
      width: 100%;
      overflow-y: auto;
      margin: 0;
    }
  }

  .iconItem {
    display: inline-block;
    margin: 3px 3px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0px 0px 2px 0px rgba(148,155,165,0.5);
    position: relative;

    i {
      line-height: 50px;
      color: $Brand-Black;
      font-size: 18px;
    }
  }
}

.inputStyling {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  cursor: pointer;
}

.upload{
  color: $Blue-500;
  text-decoration: underline;
  font-weight: bold;
}

.hrText {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: white;
  }
}

.dragDropHolder{
  position: relative;
  overflow: hidden;
  border: 1px dashed $Gray-500;
  height: 300px;
  width: 100%;
}

.tablePreviewHolder{
  overflow-x: scroll;
  max-height: 400px;
  width: 100%;
}

.csvPreviewHolder{
  position: relative;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  padding-bottom: 20px;
  border-color: $Gray-300;
  background-size: 30%;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: -45px;
}

.fieldError{
  border: 1px solid $Red-500 !important;
  background-color: $Red-700 !important;
  position: relative;
}

.fieldErrorMessage {
  color: $Red-500;
  padding-top: 5px;
}

.loadingText{
  margin-top: 20px;
  color: $Gray-900;
}

.subText {
  margin-top: 10px;
  color: $gray-text;
}

.completeText{
  margin-top: 20px;
  color: $Green-500;
  font-weight: bold;
}


.progressBG{
  background-color: white;
  border-radius: 50%;
  width: 200px;
  overflow: hidden;
  margin: 0 auto;

}

.infiniteProgressBG {
  padding-top: 20px;
  width: 200px;
  overflow: hidden;
  margin: 0 auto;
}

.progressHolder {
  position: relative;
  overflow: hidden;
  border: dashed 2px $Gray-200;
  padding: 20px;
}


.escButton {
  background: transparent;
  border-radius: 32px;
  position: absolute;
  top: -4px;
  padding-top: 4px;
  right: -4px;
  text-align: center;
  z-index: 1000;
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 32px;

  &:hover {
    background: $Gray-100;
    color: black;
  }
}

.errorBlock{
  text-align: left;
}

.errorBlockInner{
  background-color: white;
  border: 1px solid $Gray-300;
  padding: 5px;
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: scroll;
}


.progressCircle{
  background-color: #fff;
  border-radius: 50%;
}

.downloadBtn{
  width: 100%;
  padding: 10px;
  background-color: $Green-500;
  display: block;
  margin: 10px 0;
  color: white;
}
.downloadBtn:hover{
  color: white;
  text-decoration: none;
}

.inputFileUpload {
  opacity: 0;
  max-height: 50px;
  max-width: 50px;
  cursor: pointer;
}

.productsTable {
  border-collapse: collapse;
}

.productsTable td {
  padding: 5px;
  text-align: left;
}

.productsTable td p {
  margin: 5px 0;
  font-size: 12px;
  max-height: 40px;
  text-overflow: ellipsis;
}

.productsTable th tr {
  background: none;
}


.productsTable th {

  padding: 5px;
  min-width: 150px;
  font-size: 11px;
  font-weight: 500;
  color: $Gray-900;
  background: none;
  text-transform: uppercase;
}


.productsTable tr:nth-child(even) td {background: $Gray-100}
.productsTable tr:nth-child(odd) td {background: #FFF}

.productsTable tr:first-child {background: none!important;}
.productsTable tr td:first-child{
  background: none!important;
  padding: 0px;
  text-align: center;
}

.rowRadioButtons {
  margin-top: 2rem;
}

.rowRadioButton {
  margin-bottom: 1rem;
}

.bankDetailsOwnerRows {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.bankDetailsOwnerRow {
  display: flex;
  justify-content: space-between;
  width: 35rem;
}
.bankDetailsOwnerDescription {
  color: $Gray-900;
}
.bankDetailsSubmittedContent {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

:global {
  // Override a style from Onfido
  .ods-button {
    font-size: 16px !important;
  }
}