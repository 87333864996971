@import "../../assets/styles/variables.scss";

.tableRow {
  text-align: left;
  height: 46px;

  &.rowBackground {
  }

  &.clickable {
    cursor: pointer;
  }

  &.selectedRow {
    background-color: getThemeValue('table-selected-row-background');
  }
}

.listBlock {
  position: relative;
  bottom: 0;
  overflow-y: auto;
  flex: 1;
  overflow-x: hidden;
  min-height: 190px;

  @media (max-width: $menu-toggle-width) {
    position: initial;
    bottom: auto;
    overflow-y: auto;
    flex: none;
  }

  .tableContent {
    padding: 38px;
    margin: 0px -38px;
    min-height: 697px;

    @media (max-width: $screen-xs-max) {
      padding: 20px;
      margin: 0px -20px;
      min-height: 0;
    }

    .selectionHeader {
      float: right;
      color: getThemeValue('table-header-font-color');
      font-size: getThemeValue('table-header-font-size');
      text-transform: getThemeValue('table-header-text-transform');
      font-weight: getThemeValue('table-header-font-weight');
      background: none;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $Padding-Tight;
      margin-right: 31px;
    }

    &.reactTableContent {
      padding: 0;
      margin: 0;
      min-height: 0;

      .load-more {
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        padding: 32px 16px;
        font-size: 16px;
        background: white;

        &.empty {
          color: $font-light-gray;
        }

        i {
          margin-right: 16px;
          font-size: 24px;

          &:before {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.loadMore {
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  padding: 32px 16px;
  font-size: 16px;

  &.empty {
    color: $font-light-gray;
    cursor: initial;
  }

  i {
    margin-right: 16px;
    font-size: 24px;

    &:before {
      vertical-align: middle;
    }
  }
}

.searchLoader {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
}

.tableLoader {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

:global {
  .selected-row {
    td {
      background-color: $subtle-blue-background;

      &.spacer {
        background: white;
      }
    }
  }
}

.hideTableResponsive {
  .tableContent {
    @media(max-width: $menu-toggle-width) {
      display: none;
    }
  }
}

.responsiveTable {
  display: none;

  @if isNewTheme() {
    background-color: getThemeValue('card-background-color');
    border: getThemeValue('card-border');
  } @else {
    padding: 8px;
  }

  @media(max-width: $menu-toggle-width) {
    display: block;
  }
}

.sortable {
  cursor: pointer;

  i {
    margin-left: 12px;
    font-size: 10px;

    &.active {
      color: $font-dark;
    }

    &.inactive {
      color: transparentize($font-dark, 0.7);
    }
  }
}

.emptyTable {
  text-align: center;
  max-width: 550px;
  margin: 40px auto;

  .emptyTableTitle {
    font: getThemeValue('subtitle-font');
    font-size: getThemeValue('subtitle-font-size');
    font-weight: getThemeValue('subtitle-font-weight');
    color: getThemeValue('subtitle-font-color');
  }

  img {
    width: 64;
    height: 64px;
    margin: 20px auto;
  }

  .emptyTableContent {
    a {
      text-decoration: none;
    }
  }
}

.table {
  position: relative;
  border-bottom: none;
  width: 100%;
  table-layout: fixed;
  // Fixes a bug whereby the right border of the tbody is not always displayed
  border: 1px solid transparent;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: getThemeValue('font');
  font-size: 14px;
  font-weight: normal;
  color: $font-table-dark;
  bottom: 0;
  height: 100%;

  tbody {
    border: getThemeValue('table-body-border');
    background-color: white;
  }

  tr {
    text-align: left;
  }

  &.tableClickable {
    tr {
      cursor: pointer;
    }
  }

  :global {
    .strikethrough {
      text-decoration: line-through;
    }

    tr {
      height: 46px;

      td {
        padding: 6px 24px;
        position: relative;
        border-top: none;
        border-bottom: getThemeValue('table-row-border-bottom');
       // line-height: 33px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media(max-width: $tablet-width) {
          padding-left: 8px;
        }
      }

      td.amount {
        width: 200px;
        text-align: right;
      }

      td.quantity {
        width: 100px;
        text-align: right;
        font-weight: 500;
      }

      th.quantity {
        width: 100px;
        padding-right: 24px;
        text-align: right;
        line-height: 56px;
      }

      td.image {
        width: 60px;
      }

      &.underlined-row {
        border-bottom: 1px solid #E4E4E4;
      }

      &.row-alternate {
      }

      &.group-header-row {
        height: 33px;

        td {
          line-height: 24px;
          padding-bottom: 0;
          vertical-align: middle;
        }

        &.hoverable {
          cursor: pointer;
        }
      }

      &.child-row {
        height: 30px;

        &.underlined-row {
          td {
            height: 36px;
          }
        }

        &.hoverable {
          cursor: pointer;
        }

        td {
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      &.invisible-header {
        height: 0px;
        cursor: initial;

        th {
          border-bottom: 0px;
        }
      }

      &.visible-header {
        height: 40px;
        cursor: initial;

        th {
          padding: 0;
          padding-left: 24px;
          border: none;
          color: getThemeValue('table-header-font-color');
          font-size: getThemeValue('table-header-font-size');
          text-transform: getThemeValue('table-header-text-transform');
          font-weight: getThemeValue('table-header-font-weight');
          font-family: getThemeValue('font');
          background: none;
          background-color: transparent;
          text-overflow: ellipsis;
          overflow: hidden;

          @media(max-width: $tablet-width) {
            padding-left: 8px;
          }
        }
      }

      &.spacer-header {
        height: 16px;
      }

      &.subtle-background {
        td {
          background-color: #F1F6F6;

          &.spacer {
            background: white;
          }
        }
      }

      &.selected {
        td {
          background-color: $subtle-blue-background;

          &.spacer {
            background: white;
          }
        }
      }
    }

    @media (max-width: $screen-xl-min) {
      .hide-on-xl {
        width: 0 !important;
        overflow: hidden !important;
        font-size: 0px !important;
        padding: 0pc !important;
        margin: 0px !important;
      }
    }

    @media (max-width: $screen-lg-min) {
      .hide-on-lg {
        width: 0 !important;
        overflow: hidden !important;
        font-size: 0px !important;
        padding: 0pc !important;
        margin: 0px !important;
      }
    }

    @media (max-width: $tablet-width) {
      .hide-on-md {
        width: 0 !important;
        overflow: hidden !important;
        font-size: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
      }
    }

    @media (max-width: $phone-width) {
      .hide-on-phone {
        width: 0 !important;
        overflow: hidden !important;
        font-size: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
      }
    }

    @media (max-width: $screen-sm-min) {
      .hide-on-mobile{
        width: 0 !important;
        overflow: hidden !important;
        font-size: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
      }

      @media (max-width: $screen-xxs-max) {
        .hide-on-xs-mobile{
          width: 0 !important;
          overflow: hidden !important;
          font-size: 0px !important;
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }
  }
}
