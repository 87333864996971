@import "../../assets/styles/variables.scss";

.circle {
  margin: 8px auto;
  width: 50px;
  height: 50px;
  z-index: 100;

  -webkit-animation: circle infinite .75s linear;
     -moz-animation: circle infinite .75s linear;
       -o-animation: circle infinite .75s linear;
          animation: circle infinite .75s linear;

  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 100%;

  &.blue {
    border-color: getThemeValue('spinner-color');
    border-top-color: transparent;
  }

  &.white {
    border-color: #FFF;
    border-top-color: transparent;
  }
}

@-webkit-keyframes circle
{
    0%
    {
        -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
             -o-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-moz-keyframes circle
{
    0%
    {
        -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
             -o-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-o-keyframes circle
{
    0%
    {
        -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
             -o-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes circle
{
    0%
    {
        -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
             -o-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
