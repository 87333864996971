@import "../../assets/styles/variables.scss";

.formCard {
  width: 100%;
  padding: $Padding-Default;
  box-shadow: getThemeValue('card-box-shadow');
  border-radius: getThemeValue('container-border-radius');
  background: getThemeValue('card-background-color');
  display: inline-block;
  margin-bottom: $Padding-Default;

  @media(max-width: $phone-width) {
    margin: 6px auto 14px;
  }

  .formCardHeader {
    margin-bottom: 8px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
  }

  .headerText {
    margin-bottom: 20px;
    color: $Gray-700;
  }
}

.titleCard {
  flex: 1;
  box-shadow: getThemeValue('card-box-shadow');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('container-border-radius');
  background: getThemeValue('card-background-color');

  .banner {
    background-color: $Gray-700;

    .title {
      color: $white;
      font-size: 24px;
      font-family: $Inter;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      padding: 20px 0;
      margin: 0px;

      @media (max-width: $screen-xxs-max) {
        padding: 20px 20px;
      }
    }
  }

  .content {
    padding: 20px;
    font-size: 15px;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      padding: 15px 0;
    }
  }

  @media (max-width: $screen-md-min) {
    margin: 0 10px;
  }
}

.noPadding {
  padding: 0;
}

.noMargin {
  margin: 0;
}

.contentCard {
  height: 100%;
  box-shadow: getThemeValue('card-box-shadow');
}

.default {
  padding: $Padding-Loose;
  background: getThemeValue('card-background-color');
  border-radius: getThemeValue('container-border-radius');
}

.warning {
  padding: $Padding-Default;
  background: getThemeValue('card-warning-color');
  border-radius: getThemeValue('container-warning-border-radius');
}
