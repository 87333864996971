@import "../../../../assets/styles/variables.scss";

.sliderContainer, .sliderContainerAbove {
  width: 100%;

  h3 {
    color: $Green-500;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  .leftText, .rightText, .text {
    display: inline;
    font-weight: bold;
    color: grey;
  }

  .leftText, .rightText {
    margin-top: 12px;
  }

  .leftText {
    float: left;
  }

  .rightText {
    float: right;
  }

  .textContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
}

.sliderContainer {
  margin-bottom: 80px;
}

.sliderContainerAbove {
  margin-bottom: 0px;

  h3 {
    @media (max-width: $screen-xxs-max) {
      font-size: 20px;
      margin: 0px 10px;
    }
  }

  .text {
    @media (max-width: $screen-xxs-max) {
      font-size: 14px;
    }
  }
}
