@import "../../../../assets/styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  padding-left: 40px;

  @media (max-width: $screen-xxs-max) {
    padding-left: 0px;
  }

  @media (max-width: $screen-md-min) {
    margin: 0 15px;
  }

  .text {
    padding-top: 3px;
    padding-left: 20px;
    width: 90%;
    text-align: left;
  }
}
