@import "../../../../assets/styles/variables.scss";

.lulalendReAdvanceSliderContainer {
    width: 100%;
    margin-bottom: 80px;
    background-color: #F6F6F6;
    padding: 20px 32px 4px 32px;
    border-radius: 16px;
    
    .reAdvanceDetailsContainer {
      margin-top: 34px;
      font-family: $SharpGrotesk;

    .reAdvanceDetailRow, .reAdvanceDetailRowWithBottomRule {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      color: #222222;
      font-weight: 500;

      @media (max-width: $screen-xxs-max) {
        flex-direction: column;
        align-items: center;
      }
  
      .reAdvanceDetailLabelContainer {
        text-align: left;

        @media (max-width: $screen-xxs-max) {
          margin-bottom: 10px;

          .reAdvanceDetailLabel, .reAdvanceDetailLabelExplanation {
            text-align: center;
          }
        }
  
        .reAdvanceDetailLabel {
          font-size: 19px;
        }
      
        .reAdvanceDetailLabelExplanation {
          font-weight: 400;
          font-size: 13px;
          color: #4E4E4E;
          letter-spacing: 0.32px;
        }
      }
  
      .reAdvanceDetailValue, .reAdvanceDetailSmallValue, .reAdvanceDetailEmphasizedValue {
        font-size: 24px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
  
      .reAdvanceDetailSmallValue {
        font-size: 16px;
      }
  
      .reAdvanceDetailEmphasizedValue {
        color: $Green-500;
      }
    }

    .reAdvanceDetailRowWithBottomRule {
      border-bottom: 1px solid #E9E9E9;
    }
  }
}
  