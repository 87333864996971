@import "../../../../../assets/styles/variables.scss";

.cardContainer {
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
}
.cardDirection {
    display: flex;
    flex-direction: row;
}

.todoLabel {
    border-radius: 4px;
    font-size: 11px;
    background-color: 'green';
    color: rgb(46, 40, 40);
    padding: 1px 20px;
    line-height: 22px;
}

.iconBlock {
    width: 96px;
    height: 96px;
    background: $Gray-100;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 16px;
}

.headingContainer {
    padding-top: 12px;
}

.heading {
 font-weight: 500;
 margin-bottom: 8px;
}

.todoTag {
    width: 88px;
    height: 21px;
    border-radius: 4px;
    background-color: #CCEEF9;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #0087B3
}
.btnContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}
.btn {
    width: 175px
}
.link {
    text-decoration: none;
}
