@import "../../assets/styles/variables.scss";
@import '../../assets/styles/fonts/sharpGrotesk/sharpGrotesk.module.scss';

.backgroundGradients {
    height: 375px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
}

.bg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: linear-gradient(90deg, #BCE5FA 0%, #F8E1DA 100%);
}

.mainCircle {
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background: radial-gradient(50% 37.47% at 50% 50%, #CFEFFA 0%, rgba(207, 239, 250, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.contentColumn {
    width: 480px;
    margin: auto;
    position: relative;
    
    h1 {
        font-family: 'SharpGrotesk' !important;
        font-weight: 500 !important; 
    }

    .subtitle {
        font-family: 'SharpGrotesk' !important;
        font-weight: light !important;
        font-size: 13px;
        color: #767676;
    }
}

.infoLabel {
    font-family: 'SharpGrotesk' !important;
    font-weight: light !important;
    font-size: 15px;
    margin-bottom: 4px;
}

.sausageButton {
    height: 60px;
    width: 100%;
    border-radius: 1000px;
    border: none;
    background-color: getThemeValue('button-secondary-background-color');
    color: white;
    font-family: 'SharpGrotesk' !important;
    font-weight: 500 !important; 


    transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1);


}

.sausageButton:hover {
    border: 2px solid #111;
    box-shadow: 0px 0px 26px 8px #BEEAFA;
    font-size: 16px;
}