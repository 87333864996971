@font-face {
  font-family: "register-collection-icons";
  src:url("./register-collection-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:global {
  [class^="iconcollection-"]:before,
  [class*=" iconcollection-"]:before {
      font-family: "register-collection-icons" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  .iconcollection-scissor:before {
      content: "\61";
  }
  .iconcollection-power:before {
      content: "\62";
  }
  .iconcollection-ink:before {
      content: "\63";
  }
  .iconcollection-cutter:before {
      content: "\64";
  }
  .iconcollection-binocular:before {
      content: "\65";
  }
  .iconcollection-lock:before {
      content: "\66";
  }
  .iconcollection-pen-1:before {
      content: "\67";
  }
  .iconcollection-pen-2:before {
      content: "\68";
  }
  .iconcollection-pencil:before {
      content: "\69";
  }
  .iconcollection-skull-1:before {
      content: "\6a";
  }
  .iconcollection-tag:before {
      content: "\6b";
  }
  .iconcollection-bookmark:before {
      content: "\6c";
  }
  .iconcollection-palette:before {
      content: "\6d";
  }
  .iconcollection-brush-2:before {
      content: "\6e";
  }
  .iconcollection-stationery:before {
      content: "\6f";
  }
  .iconcollection-ruler:before {
      content: "\70";
  }
  .iconcollection-present:before {
      content: "\71";
  }
  .iconcollection-ribbon:before {
      content: "\72";
  }
  .iconcollection-heart:before {
      content: "\73";
  }
  .iconcollection-trophy:before {
      content: "\74";
  }
  .iconcollection-camera-symbol:before {
      content: "\75";
  }
  .iconcollection-profile-nurse-1:before {
      content: "\77";
  }
  .iconcollection-profile-graduate:before {
      content: "\78";
  }
  .iconcollection-profile-chef:before {
      content: "\79";
  }
  .iconcollection-profile-athlete:before {
      content: "\7a";
  }
  .iconcollection-profile-nurse-2:before {
      content: "\41";
  }
  .iconcollection-profile-doctor:before {
      content: "\42";
  }
  .iconcollection-new:before {
      content: "\43";
  }
  .iconcollection-wallet:before {
      content: "\44";
  }
  .iconcollection-camera-pro:before {
      content: "\45";
  }
  .iconcollection-camera-compact:before {
      content: "\46";
  }
  .iconcollection-camera-top:before {
      content: "\47";
  }
  .iconcollection-camera-quick:before {
      content: "\48";
  }
  .iconcollection-polaroid:before {
      content: "\49";
  }
  .iconcollection-video-camera:before {
      content: "\4a";
  }
  .iconcollection-headphone:before {
      content: "\4b";
  }
  .iconcollection-gramophone:before {
      content: "\76";
  }
  .iconcollection-gramophone-record:before {
      content: "\4c";
  }
  .iconcollection-tape:before {
      content: "\4d";
  }
  .iconcollection-alarm-clock:before {
      content: "\4e";
  }
  .iconcollection-watch:before {
      content: "\4f";
  }
  .iconcollection-clock:before {
      content: "\50";
  }
  .iconcollection-calendar:before {
      content: "\51";
  }
  .iconcollection-shop:before {
      content: "\52";
  }
  .iconcollection-treasure-map:before {
      content: "\53";
  }
  .iconcollection-castle:before {
      content: "\54";
  }
  .iconcollection-mouse:before {
      content: "\55";
  }
  .iconcollection-memory-card:before {
      content: "\56";
  }
  .iconcollection-joystick:before {
      content: "\57";
  }
  .iconcollection-gameboy:before {
      content: "\58";
  }
  .iconcollection-smartphone:before {
      content: "\59";
  }
  .iconcollection-chef-hat:before {
      content: "\5a";
  }
  .iconcollection-academic-cap:before {
      content: "\30";
  }
  .iconcollection-shirt-1:before {
      content: "\31";
  }
  .iconcollection-shirt-2:before {
      content: "\32";
  }
  .iconcollection-shirt-3:before {
      content: "\33";
  }
  .iconcollection-sneakers:before {
      content: "\34";
  }
  .iconcollection-snorkel:before {
      content: "\35";
  }
  .iconcollection-monocle-1:before {
      content: "\36";
  }
  .iconcollection-monocle-2:before {
      content: "\37";
  }
  .iconcollection-glasses-1:before {
      content: "\38";
  }
  .iconcollection-fins:before {
      content: "\39";
  }
  .iconcollection-glasses-2:before {
      content: "\21";
  }
  .iconcollection-beanie:before {
      content: "\22";
  }
  .iconcollection-bike-helmet:before {
      content: "\23";
  }
  .iconcollection-bow:before {
      content: "\24";
  }
  .iconcollection-cap:before {
      content: "\25";
  }
  .iconcollection-necktie:before {
      content: "\26";
  }
  .iconcollection-safety-helmet:before {
      content: "\27";
  }
  .iconcollection-tall-hat:before {
      content: "\28";
  }
  .iconcollection-trousers:before {
      content: "\29";
  }
  .iconcollection-walking-stick:before {
      content: "\2a";
  }
  .iconcollection-glasses-3:before {
      content: "\2b";
  }
  .iconcollection-baseball-helmet:before {
      content: "\2c";
  }
  .iconcollection-timer:before {
      content: "\2d";
  }
  .iconcollection-apple:before {
      content: "\2e";
  }
  .iconcollection-beer:before {
      content: "\2f";
  }
  .iconcollection-boil:before {
      content: "\3a";
  }
  .iconcollection-bottle:before {
      content: "\3b";
  }
  .iconcollection-bread:before {
      content: "\3c";
  }
  .iconcollection-burger-1:before {
      content: "\3d";
  }
  .iconcollection-burger-2:before {
      content: "\3e";
  }
  .iconcollection-cake-1:before {
      content: "\3f";
  }
  .iconcollection-cake-2:before {
      content: "\40";
  }
  .iconcollection-champagne:before {
      content: "\5b";
  }
  .iconcollection-cheese:before {
      content: "\5d";
  }
  .iconcollection-cocktail:before {
      content: "\5e";
  }
  .iconcollection-cocktail-1:before {
      content: "\5f";
  }
  .iconcollection-coffee-pot:before {
      content: "\60";
  }
  .iconcollection-cup-1:before {
      content: "\7b";
  }
  .iconcollection-cup-2:before {
      content: "\7c";
  }
  .iconcollection-energy-drink:before {
      content: "\7d";
  }
  .iconcollection-espresso-machine:before {
      content: "\7e";
  }
  .iconcollection-food-dome:before {
      content: "\5c";
  }
  .iconcollection-fork-and-knife:before {
      content: "\e000";
  }
  .iconcollection-grape:before {
      content: "\e001";
  }
  .iconcollection-grater:before {
      content: "\e002";
  }
  .iconcollection-grill:before {
      content: "\e003";
  }
  .iconcollection-hot-drinks-glass:before {
      content: "\e004";
  }
  .iconcollection-hotdog:before {
      content: "\e005";
  }
  .iconcollection-ice-cream-1:before {
      content: "\e006";
  }
  .iconcollection-ice-cream-2:before {
      content: "\e007";
  }
  .iconcollection-ice-cream-3:before {
      content: "\e008";
  }
  .iconcollection-ice-drinks-galss:before {
      content: "\e009";
  }
  .iconcollection-juicer:before {
      content: "\e00a";
  }
  .iconcollection-kitchen-timer:before {
      content: "\e00b";
  }
  .iconcollection-milk:before {
      content: "\e00c";
  }
  .iconcollection-orange:before {
      content: "\e00d";
  }
  .iconcollection-oven:before {
      content: "\e00e";
  }
  .iconcollection-pan-fry:before {
      content: "\e00f";
  }
  .iconcollection-pizza:before {
      content: "\e010";
  }
  .iconcollection-popcorn:before {
      content: "\e011";
  }
  .iconcollection-serving:before {
      content: "\e012";
  }
  .iconcollection-soda:before {
      content: "\e013";
  }
  .iconcollection-soda-can-1:before {
      content: "\e014";
  }
  .iconcollection-soda-can-2:before {
      content: "\e015";
  }
  .iconcollection-steam:before {
      content: "\e016";
  }
  .iconcollection-tea-pot:before {
      content: "\e017";
  }
  .iconcollection-thermometer-high:before {
      content: "\e018";
  }
  .iconcollection-thermometer-low:before {
      content: "\e019";
  }
  .iconcollection-thermometer-medium:before {
      content: "\e01a";
  }
  .iconcollection-water:before {
      content: "\e01b";
  }
  .iconcollection-wine:before {
      content: "\e01c";
  }
  .iconcollection-plaster:before {
      content: "\e01d";
  }
  .iconcollection-ticket-1:before {
      content: "\e01e";
  }
  .iconcollection-ticket-2:before {
      content: "\e01f";
  }
  .iconcollection-suitcase:before {
      content: "\e020";
  }
  .iconcollection-cards:before {
      content: "\e021";
  }
  .iconcollection-balloon:before {
      content: "\e022";
  }
  .iconcollection-smileys:before {
      content: "\e023";
  }
  .iconcollection-rain-lightning:before {
      content: "\e024";
  }
  .iconcollection-snow:before {
      content: "\e025";
  }
  .iconcollection-heavy-rain:before {
      content: "\e026";
  }
  .iconcollection-moon-cloud:before {
      content: "\e027";
  }
  .iconcollection-sun-cloud:before {
      content: "\e028";
  }
  .iconcollection-sun:before {
      content: "\e029";
  }
  .iconcollection-waning-crescent-moon:before {
      content: "\e02a";
  }
  .iconcollection-road-bike:before {
      content: "\e02b";
  }
  .iconcollection-bicycle:before {
      content: "\e02c";
  }
  .iconcollection-scooter:before {
      content: "\e02d";
  }
  .iconcollection-cactus:before {
      content: "\e02e";
  }
  .iconcollection-clover:before {
      content: "\e02f";
  }
  .iconcollection-flower:before {
      content: "\e030";
  }
  .iconcollection-potted-plant:before {
      content: "\e031";
  }
  .iconcollection-bag-shopping-2:before {
      content: "\e032";
  }
  .iconcollection-bag-shopping-1:before {
      content: "\e033";
  }
  .iconcollection-bag-shopping-3:before {
      content: "\e034";
  }
  .iconcollection-briefcase:before {
      content: "\e035";
  }
  .iconcollection-handbag-1:before {
      content: "\e036";
  }
  .iconcollection-handbag-2:before {
      content: "\e037";
  }
  .iconcollection-purse-2:before {
      content: "\e038";
  }
  .iconcollection-wallet-2:before {
      content: "\e039";
  }
  .iconcollection-wallet-3:before {
      content: "\e03a";
  }
}
