@import "../../assets/styles/variables.scss";

.settlementWrapper {
  flex: 1;
  @if isNewTheme() {
    margin: 0;
  } @else {
    margin: 0 18px 0 -28px;
  }
  background: getThemeValue('card-background-color');
  border: getThemeValue('card-border');
  border-radius: getThemeValue('container-border-radius');
  overflow: hidden;

  .settlementHeader{
    text-align: left;
    padding: getThemeValue('card-header-padding');
    font-size: getThemeValue('card-header-font-size');
    font-weight: getThemeValue('card-header-font-weight');
    text-transform: getThemeValue('card-header-text-transform');
    background-color: getThemeValue('card-header-background-color');
    color: getThemeValue('card-header-font-color');
    border-bottom: getThemeValue('card-header-border-bottom');

    .settlementDate {
      font-family: getThemeValue('font');

      @media(max-width: 460px) {
        display: none;
      }
    }

    a {
      color: getThemeValue('card-header-font-color');
      text-transform: uppercase;
    }

    a:hover {
      text-decoration: none;
    }

    i {
      float: right;
      text-shadow: none;
      line-height: 24px;

      @media (max-width: 320px) {
        display: none;
      }
    }

    .headerLink {
      float: right;
      padding-right: 6px;
      text-shadow: none;
      font-size: 11px;
      font-family: getThemeValue('font');
      font-weight: bold;

      span.headerText {

        @media (min-width: $screen-sm-min) and (max-width: 1150px) {
          display: none;
        }

        @media (max-width: $screen-xxs-max) {
          display: none;
        }
      }
    }

    span {
      line-height: 24px;
    }
  }

  .settlementBlockAmount{
    text-align: left;
    font-family: getThemeValue('insight-value-font');
    font-size: getThemeValue('insight-value-font-size');
    font-weight: getThemeValue('insight-value-font-weight');
    line-height: 60px;
    background-color: getThemeValue('card-background-color');
    color: getThemeValue('insight-fact-color');
    display: flex;
    padding: 20px;
  }

  .settlementBlockLink {
    text-align: right;
    padding: 5px;
    text-transform: uppercase;
    background-color: getThemeValue('card-background-color');
    color: getThemeValue('card-font-color');

    a {
      color: getThemeValue('card-font-color');
      padding-right: 8px;
    }
  }

  @media(max-width: $menu-toggle-width) {
    @if isNewTheme() {
      margin: 0px;
    } @else {
      margin: 0 -14px;
    }
  }
}

.settlementCardWrapper {
  @extend .settlementWrapper;
  margin: 0;
  max-width: 600px;

  box-shadow: getThemeValue('card-box-shadow');
  border-radius: getThemeValue('container-border-radius');
  background: getThemeValue('card-background-color');

}

.settlementOnboardingWarningWrapper {
  padding: 20px 0 0 0;

  .settlementOnboardingWarning {
    padding: 8px;
    margin: 0;
    border: 1.5px solid #F7AE41;
    border-radius: 3px;
    background-color: rgba(247,174,65,0.3);
  }

  a {
    color:#000;
    text-decoration: underline;
  }
}
