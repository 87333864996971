@import "../../assets/styles/variables.scss";

.root {
  position: relative;

  :global {
    .react-select__control {
      border: getThemeValue('form-field-border');
      color: getThemeValue('font-color');
      box-shadow: none;
      &--is-focused, &:hover {
        border: getThemeValue('form-field-active-border');
      }
    }

    .react-select__option {
      &--is-focused {
        background: $Green-50;
      }
      &--is-selected, &:active {
        color: white;
        background: $Green-500;
      }
    }
  }
}

.clearButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); 
  right: 42px;
  color: red;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 10px;
}