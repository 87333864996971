@import "../../../../assets/styles/variables.scss";

.qrImage {
  border : 7px solid $Brand-Blue;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 200px;
}

.popupTitle {
  font-family: $SharpGrotesk;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.downloadAsPDFButton {
  background: white;
  border: none;
  color: $Brand-Black;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.linkContainer {
  display: flex;
  justify-content: center;

  .url {
    font-size: 11px;
    line-height: 18px;
    margin: 0;
  }

  .linkIcon{
    margin-right: 10px;
  }
}

.buttonContainer {
  display: block;
  margin-top: 20px;

  @media (max-width: $phone-width) {
    display: none;
  }
}

