@import "../../assets/styles/variables.scss";

:global {
  .alert {
    padding: getThemeValue('alert-padding');
    margin-bottom: getThemeValue('alert-margin-bottom');
    border: getThemeValue('alert-border') !important;
    border-radius: 4px;
    font-family: getThemeValue('font');
    font-size: getThemeValue('alert-font-size');

    &.alert-danger {
      background-color: getThemeValue('alert-danger-background-color');
      border-color: getThemeValue('alert-danger-border-color');
      color: getThemeValue('alert-danger-font-color');
    }

    &.alert-success {
      background-color: getThemeValue('alert-success-background-color');
      border-color: getThemeValue('alert-success-border-color');
      color: getThemeValue('alert-success-font-color');
    }
  }
}

.message {
  position: relative;
  padding-right: 32px !important;
}

.notificationCloseButton {
  margin-top: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.userAlertContainer {
  position: fixed;
  right: 24px;
  max-width: 270px;
  padding: 16px;

  border: getThemeValue('card-border');
  box-shadow: getThemeValue('card-box-shadow');
  border-radius: getThemeValue('container-border-radius');
  background: getThemeValue('card-background-color');
  padding: $Padding-Default;

  .userAlertHeader {
    margin-bottom: $Padding-Tight;
    line-height: 20px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;

    .alertDismissButton {
      border-radius: 32px;
      padding: 4px;

      i {
        height: 24px;
        width: 24px;
      }

      &:hover {
        background: $Gray-100;
      }
    }

    :global {
      .type-warning {
        border-color: $Red-500;

        i {
          color: $Red-500;
        }
      }
    }
  }
}