.errorMessageContainer {
  text-align: center;
  background-color: #f2dede;
  padding: 10px;
}

.errorMessage {
  border-color: #ebccd1;
  color: #a94442;
  font-size: medium;
  margin: 0px;
}
