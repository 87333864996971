@import '../../../assets/styles/variables.scss';

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: getThemeValue('content-overflow-background-color');
  overflow-y: auto;

  .maxWidthBlock {
    padding: 0 getThemeValue('content-padding-size') getThemeValue('content-padding-size');
    max-width: 1440px;
    background: getThemeValue('content-background-color');
    min-height: 100%;

    @media (max-width: $menu-toggle-width) {
      padding: 0px getThemeValue('content-md-padding-size') getThemeValue('content-md-padding-size');
    }

    @media (max-width: $phone-width) {
      padding: 0px getThemeValue('content-sm-padding-size') getThemeValue('content-sm-padding-size');
    }
  }
}
