.placesResultsContainer {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 0px 4px;
  position: absolute;
  z-index: 1;
}

.placesResults {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.mainText{
}

.secondaryText {
  color: #626262;
}
