@import "../../../../assets//styles/variables.scss";

.progressBar {
    display: flex;
    flex-direction: row;
    padding: 0 0 20px 0;

    @media (max-width: $screen-xxs-max) {
      padding: 0 0 10px 0;
    }

    .stepContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;

      .step {
        text-align: center;
        font-size: 18px;
        margin-bottom: 10px;
        position: relative;

        .circleContainer {
          z-index: 2;
          position: relative;
          margin: 0 auto;
          display: inline-block;
        }

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid $Gray-500;
          background: $Gray-300;
          border-radius: 50%;
          width: 25px;
          height: 25px;
        }

        i {
          display: none;
        }
      }
      .active {
        .circle {
          border-color: $Green-500;
          background: $Green-500;
        }
      }

      .completed {
        .circle {
          border-color: $Green-500;
          background: $Green-500;
        }

        i {
          display: block;
          position: absolute;
          top: 5px;
          left: 5px;
          color: white;
          font-size: 14px;
        }
      }

      .text {
        display: flex;
        flex: 1;
        justify-content: center;
        margin-top: 10px;
        color: $Brand-Black;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .dash {
        border-top: 1.5px solid;
        color: $Gray-500;
        position: absolute;
        width: 100%;
        left: -50%;
        margin-top: 12px;
      }

      .active {
        color: $Green-500;
      }
      .completed {
        color: $Gray-700;
      }
    }
}
