@import "../../assets/styles/variables.scss";

.buttonsContainer {
  margin: 16px;

  .rightButtons {
    float: right;
    margin-right: 50px;

    @media(max-width: $menu-toggle-width) {
      margin-right: 0;
    }
  }

  .removeButton {
    float: left;

    @media(max-width: $menu-toggle-width) {
      float: right;
      margin-bottom: 12px;
    }
  }
}

tr {
  th.mobile {
    @media(max-width: $screen-sm-min) {
      display: none;
    }
  }
  td.mobile {
    @media(max-width: $screen-sm-min) {
      display: none;
    }
  }
}

.float {
  right: 40px;
  bottom: 30px;
  position: fixed;
}

.roles {
  display: block;

  .customField {
    padding: 8px;
  }

  .permissionsList {
    .permissionSectionTitle {
      font-weight: bold;
      color: #777;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    section {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .permission {
      flex-direction: row;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      margin-bottom: 20px;
    }
    .permissionCheckbox  {
      flex-basis: 40px;
    }
    .permissionText {
      flex: 1;
      
      span {
        line-height: 22px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .help {
      font-size: 14px;
    }
    .subHelp {
      font-style: italic;
    }
    .checkboxField {
      padding-top: 8px;
    }
  }

  .showHidePermissions {
    padding-bottom: 10px;
    cursor: pointer;
  }
}

.userField {
  width: 100%;
  max-width: none;
}

//Email Notifications Page
.frameView {
  width: 100%;
  height: 100%;
  border: none;
}

.iframeContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 -20px;
  padding: 20px 0;
}

.fieldLabel {
  margin: 0;
  font-size: getThemeValue('form-label-font-size');
  text-transform: getThemeValue('form-label-text-transform');
  font-weight: getThemeValue('form-label-font-weight');
  color: getThemeValue('font-color');

  @media(max-width: $menu-toggle-width) {
    padding-bottom: 16px;
  }
}

.formStyle{
  float: left;
  min-width: 500px;
  margin-bottom: 36px;

  button {
    margin-top: 18px;
  }

  @media(max-width: $menu-toggle-width) {
    min-width: 460px;

    button {
      float: left;
      width: 212px;
      height: 42px;
      margin-left: 8px;
      margin-top: 24px;
    }
  }

  @media(max-width: $phone-width) {
    min-width: 0;
    margin-left: -10px;
  }
}

.actionField {
  display: flex;

  .button {
    margin-top: 23px;
  }
}

.addPanel {
  display: flex;

  @media(max-width: $menu-toggle-width) {
    display: block;
  }

  .info {
    color: white;
  }

  .form {
    float: left;
    margin-right: 24px;
    min-width: 420px;
    margin-bottom: 36px;

    .fieldset {
      .actionField {
        .formField {
          float: left;
          min-width: 280px;
        }

        button {
          float: left;
          margin-top: 32px;
          margin-left: 22px;
        }

        @media(max-width: 560px) {
          .formField {
            float: none;
            min-width: initial;
            max-width: 290px;
          }

          button {
            margin: 0;
          }
        }
      }
    }
  }
}

.checkboxButton {
  float: left;
}

a:hover {
  cursor: pointer;
}

.splitLeft {
  .emailPreferencesContent {
    background: white;
    padding: 0 38px 38px;
  }
}

.preferencesContainer {
  font-size: 14px;
  font-family: getThemeValue('font');

  .description {
    margin: 28px 0 32px;

    @media(max-width: 560px) {
      a {
        display: none;
      }
    }
  }

  .form {
    .fieldset {
      .formField {
        label {
          margin: 0 0 8px;
        }
      }
    }
  }

  .preferencePanel {
    margin-bottom: 32px;

    @media(max-width: $menu-toggle-width) {
      padding-bottom: 24px;
    }

    .empty {
      color: $font-light-gray;
      font-size: 14px;
    }

    .preferenceLine {
      max-width: 850px;
      display: flex;
      display: -ms-flexbox;
      display: -webkit-flex;
      margin-bottom: 8px;
      line-height: 26px;
      font-size: 14px;

      .name {
        flex: 1;
        -ms-flex: 1;
        -webkit-flex: 1;
      }

      .periodContainer {
        width: 100px;
        display: flex;
        align-items: center;

        span {
          margin-left: 8px;
        }
      }
    }
  }

  .tabs {
    margin: 20px;
  }
}

@media(max-width: $menu-toggle-width) {
  .splitLeft {
    padding-right: 0;
  }

  .preferencesContainer {
    .preferencePanel {
      .preferenceLine {
        display: block;
      }
    }
  }
}

@media(max-width: 560px) {
  .emailPreferencesContent {
    padding: 0 12px 12px;
  }
}

.unsubscribeBlock {
  padding: 24px;
  text-align: center;
  min-height: 90px;

  a {
    cursor: pointer;
  }

  h2 {
    text-align: center;
  }
}

.webhookInfo {
  fontFamily: monospace;
  color: darkblue;
}
