@import "assets/styles/variables.scss";

.invoicePreviewIframe{
  overflow-y: hidden;
  transform-origin: 0 0;
  padding-top:24px;
  padding-left:24px;
  padding-right:24px;
  box-sizing: border-box;
  border: 1px solid $Gray-300;
  border-radius:4px;
}
