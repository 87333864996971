@import "../../../../../assets/styles/variables";


.buttonContainer{
  display: flex;
  flex-direction: row;
  width:100%;
  gap:10px;
  justify-content: center;
}

.deleteButton {
  background: getThemeValue('button-destructive-background-color');
  color: getThemeValue('button-destructive-font-color');
  min-width: 50%;
  @media(max-width: $menu-toggle-width) {
    right: 0;
  }
}

.button{
  min-width: 50%;
}