@import "../../assets/styles/variables.scss";


.iconSelectorField{

  div{
    display: inline-block;
    margin: 15px;
    cursor: pointer;
  }

  .selected{
    color:$yoco-blue;
  }

  i:before{
    font-size:40px;
    display:inline-block;
  }

}
