@import "../../assets/styles/variables.scss";

.insightContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  top: 47px;

  .insightHeader {
    height: 50px;
    line-height: 50px;
    cursor: pointer;

    .description {
      padding-left: 32px;

      .light {
        color: transparentize(black, 0.5);
      }
    }

    .InsightsHeaderAmount {
      color: #8c8c8c;
      text-align: right;
      font-size: 18px;
      font-weight: light;
      padding-right: 26px;
    }

    .insightIcons {
      font-size: 14px;

      &.positive {
        color: #42af5f;
      }

      i {
        font-size: 16px;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }

  .slidingContent {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .insightContainerContent {
    background: white;
    height: 0;
    border-bottom: 1px dashed #e4e4e4;
    -webkit-transition: height 200ms;
    position: relative;
    z-index: 1;

    &.expanded {
      height: 90%
    }
  }

  .insightsContainerPageContent {
    background: white;
    height: 100%;
    position: relative;

    &.expanded {
      height: 10%;
    }
  }
}

.insight {
  @if isNewTheme() {
  } @else {
    color: white;
  }
  text-align: center;
  padding: 24px;
  position: relative;

  &.insightPositive {
    background: getThemeValue('insight-positive-background-color');

    .insightValues {
      color: getThemeValue('insight-positive-color');
    }
  }

  &.insightNegative {
    background: getThemeValue('insight-negative-background-color');

    .insightValues {
      color: getThemeValue('insight-negative-color');
    }
  }

  &.insightNeutral {
    background: getThemeValue('insight-neutral-background-color');

    .insightValues {
      color: getThemeValue('insight-neutral-color');
    }
  }


  .insightHeader {
    font-weight: 500;
    font-style: 16px;
    text-transform: capitalize;

    i {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      padding: 14px;
    }
  }

  .insightValues {
    font-weight: 500;
    font-size: 42px;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    width: 100%;

    .spacer {
      flex: 1;
      -ms-flex: 1;
      -webkit-flex: 1;

      @media(max-width: $menu-toggle-width) {
        display: none;
      }
    }

    i {
      font-size: 36px;
      display: inline;
      margin-right: 8px;

      &:before {
        vertical-align: middle;
      }
    }

    .valueText {
      vertical-align: middle;
      white-space: nowrap;

      :global {
        .amount {
          display: inline;
        }
      }
    }

    @media(max-width: $menu-toggle-width) {
      font-size: 32px;

      i {
        font-size: 28px;
      }
    }
  }

  .insightFooter {
    font-size: 16px;
    font-family: getThemeValue('font');
  }
}

.insightDataBlock {
  position: relative;
  white-space: nowrap;
  @media (min-width: $menu-toggle-width) {
    padding: 24px 0px;
  }

  :global {
    .red-text {
      color: black;
    }
  }

  .insightHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .flexBox {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;

    &.leftAlign {
      text-align: left !important;
    }

    .primary {
      font-family: getThemeValue('font');
      font-size: 42px;
      line-height: 50px;
      @if isNewTheme() {
        color: getThemeValue('insight-positive-color');
      } @else {
        color: $yoco-blue;
      }

      @media(max-width: $menu-toggle-width) {
        font-size: 32px;

        .amount {
          text-align: left !important;
        }
      }
    }

    .valueBlock {
      font-family: getThemeValue('font');
      font-size: getThemeValue('font-size');
      line-height: 17px;
      display: flex;
      display: -ms-flexbox;
      display: -webkit-flex;

      .spacer {
        flex: 1;
        -ms-flex: 1;
        -webkit-flex: 1;

        @media(max-width: $menu-toggle-width) {
          display: none;
        }
      }

      .itemLabel {
        color: transparentize(black, 0.3);
      }

      .itemValue {
        margin-left: 4px;
        color: black;

        .amount {
          display: inline;

          .redText {
            color: #000;
          }
        }
      }
    }
  }
}

.insightsBanner {
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;

  margin: 24px 18px;

  @media(max-width: $menu-toggle-width) {
    display: block;
    margin: 0;
    margin-top: 16px;
  }

  .insightWrapper {
    flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    margin: 0 18px;

    @media(max-width: $menu-toggle-width) {
      margin-bottom: 24px;
      display: block;
    }
  }
}

#insight-calculation {
  max-width: initial;
  min-width: 240px;

  .insightCalculation {
    .comparison {
      display: flex;
      display: -ms-flexbox;
      display: -webkit-flex;

      white-space: nowrap;
      font-weight: light;

      .half {
        flex: 1;
        -ms-flex: 1;
        -webkit-flex: 1;

        margin-right: 12px;

        .title {
          text-decoration: underline;
        }

        .value {
          font-family: getThemeValue('font');

          .amount {
            text-align: left;
          }
        }
      }
    }

    .result {
      text-align: center;

      i {
        &:before {
          vertical-align: middle;
        }
      }

      .value {
        font-weight: 500;
        .amount {
          display: inline;
          text-align: left;
        }
      }
    }
  }
}

.section {
  border-bottom: dashed 1px #e4eaed;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .numbersContainer {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    width: 100%;
    padding-bottom: 24px;

    .dates {
      flex: 1;
      -ms-flex: 1;
      -webkit-flex: 1;
    }

    &.padded {
      padding: 24px 0;
      padding-top: 30px;

      .value {
        .amount {
          text-align: center;
          width: 100%;
        }
      }
    }

    &.smallPadding {
      padding: 12px 0;
    }
  }

  &.footer {
    padding-top: 10px;
    color: white;
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .resultBlock {
      .result {
        text-align: right;
        color: black;
        font-size: 18px;
        font-weight: 500;

        .value {
          padding: 0 12px;
        }

        :global {
          .amount {
            float: none;
            display: inline-block;
            margin-left: 6px;
          }
        }

        i {
          position: relative;
          bottom: -3px;
          margin-left: 10px;
        }
      }
    }
  }
}

.noFlex {
  flex: initial;
  -ms-flex: initial;
  -webkit-flex: initial;
}

.insightSplitTitle {
  display: inline-block !important;
  width: 340px;
}

.reportDateDisplay {
  padding: 0 36px;
  line-height: 20px;
  font-size: 16px;
  position: relative;
  top: -16px;
  font-weight: light;
  color: #333;

  .date {
    font-family: getThemeValue('font');
  }
}
